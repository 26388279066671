import http from './http-commons';
import { authHeader } from './authHeader';
import { API_URL } from './config';

class FoodService {
  getFoodType(id) {
    if(id === null || id === undefined || id === "") {
      return http.get(API_URL + `food/type/`, { headers: authHeader() });
    }
    return http.get(API_URL + `food/type/?restaurant_id=${id}`, { headers: authHeader() });
  }
  // getFoodCategory() {
  //   return http.get(API_URL + 'admin/food/category/', { headers: { 'Authorization': 'Token 605a76487d776c8ab2cf53d3ccc485cd61180f02', "Content-type": "application/json", } });
  // }

  getIngredients() {
    return http.get(API_URL + 'ingredient/', { headers: authHeader() });
  }

  setIngredients(value) {
    return http.post(API_URL + 'ingredient/', value, { headers: authHeader()});
  }

  // getAll() {
  //   return http.get(API_URL + 'food/', { headers: authHeader() });
  // }

  getAllForRestaurant(id, status, day=null) {
    const user = localStorage.getItem("user");
    const mainUser = JSON.parse(user);
    let statusFilter = ""
    let publishday = ""
    if(status === true || status === false){
      statusFilter = `&is_active=${status}`
    }
    console.log(`Jour actuel en français : ${day}`);

    if(day){
      publishday = `&published_day=${day}`
    }

    if(id === null || id === undefined || id === "") {
      return http.get(API_URL + `food/`, { headers: authHeader() });
    }
    else if(mainUser && mainUser.role && mainUser.role == "underrestaurant"){
      return http.get(API_URL + `food/?sous_restaurant_id=${mainUser.restaurant_id}${statusFilter}${publishday}`, { headers: authHeader() });
    }
    return http.get(API_URL + `food/?restaurant=${id}${statusFilter}${publishday}`, { headers: authHeader() });
  }

  get = id => {
    return http.get(`food/${id}/`, { headers: authHeader() });
  };

  getFoodByType = (rest, type, name) => {
    return http.get(`food/?type=${type}&restaurant=${rest}`, { headers: authHeader() });
  };

  getFoodByName = (restId, name) => {
    return http.get(`food/?name=${name}&restaurant=${restId}`, { headers: authHeader() });
  };

  getFoodSubType = () =>{
    return http.get(API_URL + "food/subtype",{ headers: authHeader()  });
  }

  create = data => {
    console.log(data);
    return http.post(API_URL + "food/", data, { headers: authHeader("multipart/form-data") });
  };

  sousreso = (id, data) => {
    console.log(data);
    return http.post(API_URL + `food/sousrestaurant/detail/${id}/`, data, { headers: authHeader("multipart/form-data") });
  };

  update = (id, data) => {
    return http.put(API_URL + `food/${id}/`, data, { headers: authHeader("multipart/form-data") });
  };

  remove = id => {
    return http.delete(API_URL + `food/${id}/`, { headers: authHeader() });
  };

  deactivate = id => {
    return http.post(`food/${id}/deactivate/`,{}, { headers: authHeader()  });
  };
  activate = id => {
    return http.post(`food/${id}/activate/`, {}, { headers: authHeader()  });
  };
  //api/restaurant/<restau_id>
}

export default new FoodService();
