import http from './http-commons';
import { authHeader } from './authHeader';
import { API_URL } from './config';

class MenuService {
  // getFoodType() {
  //   return http.get(API_URL + 'admin/food/type/', { headers: { 'Authorization': 'Token 605a76487d776c8ab2cf53d3ccc485cd61180f02', "Content-type": "application/json", }  });
  // }
  //
  // getFoodCategory() {
  //   return http.get(API_URL + 'admin/food/category/', { headers: { 'Authorization': 'Token 605a76487d776c8ab2cf53d3ccc485cd61180f02', "Content-type": "application/json", }  });
  // }

  // getIngredients() {
  //   return http.get(API_URL + 'ingredient/', { headers: authHeader() });
  // }

  getAllForRestaurant(id) {
    if(id === null || id === undefined || id === "") {
      return http.get(API_URL + `menu/`, { headers: authHeader() });
    }
    return http.get(API_URL + `menu/?restaurant=${id}`, { headers: authHeader() });
  }

  getAll() {
    return http.get(API_URL + 'menu/', { headers: authHeader() });
  }

  get = id => {
    return http.get(`menu/${id}/`, { headers: authHeader() });
  };

  create = data => {
    return http.post("menu/", data, { headers: authHeader() });
  };

  createPeriod = data => {
    return http.post("menu/publication/period/", data, { headers: authHeader() });
  };


  updatePeriod = (id, data) => {
    return http.put(`menu/publication/period/${id}/`, data, { headers: authHeader() });
  };

  update = (id, data) => {
    return http.put(`menu/${id}/`, data, { headers: authHeader() });
  };

  remove = id => {
    return http.delete(`menu/${id}/`, { headers: authHeader() });
  };

}

export default new MenuService();
