import { useNavigate } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mainService from "../../services/mainService";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useIntl } from "react-intl";

export default function OrderModal({
  modalState,
  updatedOrderList,
  toggle,
  orderId,
  formIsDirty,
}) {
  const intl = useIntl();

  const navigate = useNavigate();

  const user = localStorage.getItem("user");
  const mainUser = JSON.parse(user);
  const token = mainUser.token;

  const getConfirmTranslation = (state) => {
    switch (state) {
      case "accept":
        return [
          intl.formatMessage({ id: "confirmAcceptAction" }),
          intl.formatMessage({ id: "reallyAcceptOrder" }),
        ];
      case "reject":
        return [
          intl.formatMessage({ id: "confirmRejectAction" }),
          intl.formatMessage({ id: "reallyRejectOrder" }),
        ];
      case "modify":
        return [
          intl.formatMessage({ id: "confirmModifyAction" }),
          intl.formatMessage({ id: "reallyModifyOrder" }),
        ];
      default:
        break;
    }
  };

  const closeBtn = (
    <FontAwesomeIcon
      className="close"
      onClick={toggle}
      style={{ cursor: "pointer", fontSize: "1.5rem" }}
      icon={faTimes}
    />
  );
  return (
    <Modal
      id="modal"
      className="wazi-modal"
      isOpen={!!modalState}
      toggle={toggle}
      size="l"
      backdrop="static"
    >
      <ModalHeader
        style={{ fontSize: "1.5rem" }}
        toggle={toggle}
        close={closeBtn}
      >
        {modalState &&
          getConfirmTranslation(
            formIsDirty && modalState !== "reject" ? "modify" : modalState
          )[0]}
      </ModalHeader>
      <ModalBody>
        <div className="text-center py-5">
          {modalState &&
            getConfirmTranslation(
              formIsDirty && modalState !== "reject" ? "modify" : modalState
            )[1]}
        </div>
        <Row style={{ flexDirection: "row", justifyContent: "flex-end" }}>
          <Button
            className=" btn-icon-only pt-0 wazi-btn-add "
            style={{
              backgroundColor: "#0ab44e",
              border: "1px solid #0ab44e",
              width: "auto",
              whiteSpace: "nowrap",
              fontSize: "20px",
              lineHeight: "45px",
              margin: "0 15px",
            }}
            onClick={() => {
              toggle();
              if (formIsDirty) {
                mainService
                  .acceptRejectOrder(
                    { action: "modify", ...updatedOrderList },
                    token,
                    orderId
                  )
                  .then((response) => {
                    console.log("modify response", response);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                mainService
                  .acceptRejectOrder({ action: modalState }, token, orderId)
                  .then((response) => {
                    console.log("accept response", response);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              navigate(`/orders`);
            }}
          >
            {intl.formatMessage({ id: "yes" })}
          </Button>
          <Button
            className=" btn-icon-only pt-0 wazi-btn-add "
            style={{
              backgroundColor: "red",
              border: "1px solid red",
              width: "auto",
              whiteSpace: "nowrap",
              fontSize: "20px",
              lineHeight: "45px",
              margin: "0 15px",
            }}
            onClick={() => {
              toggle();
            }}
          >
            {intl.formatMessage({ id: "no" })}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}
