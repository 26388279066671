import { useIntl } from "react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "@chakra-ui/react";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

export default function DashboardByDate({ filteredOrders, isLoading }) {
  const intl = useIntl();

  const totalDayFood = filteredOrders.reduce(
    (acc, item) =>
      acc +
      item?.food_items.reduce(
        (sum, item) =>
          sum + item?.food_data.purchase_price * item?.total_quantity,
        0
      ),
    0
  );
  const totalDayDrinks = filteredOrders.reduce(
    (acc, item) =>
      acc +
      item?.drink_items.reduce(
        (sum, item) =>
          sum + item?.drink_data.purchase_price * item?.total_quantity,
        0
      ),
    0
  );
  const totalDayOrder = totalDayFood + totalDayDrinks;

  const getDayDrinksTotals = () => {
    const totals = {};

    filteredOrders.forEach((order) => {
      if (order.drink_items.length) {
        for (const drink of order.drink_items) {
          const { drink_id, drink_name, total_quantity, drink_data } = drink;

          if (totals[drink_id]) {
            totals[drink_id].total_quantity += total_quantity;
            totals[drink_id].totalAmount +=
              total_quantity * drink_data.purchase_price;
          } else {
            totals[drink_id] = {
              drink_id,
              drink_name,
              total_quantity,
              totalAmount: total_quantity * drink_data.purchase_price,
              drink_data_price: drink_data.purchase_price,
              drinkPicture: drink_data.drinkPicture,
            };
          }
        }
      }
    });
    return Object.values(totals);
  };

  const getDayFoodTotals = () => {
    const totals = {};

    filteredOrders.forEach((order) => {
      if (order.food_items.length) {
        for (const food of order.food_items) {
          const { food_id, food_name, total_quantity, food_data } = food;

          if (totals[food_id]) {
            totals[food_id].total_quantity += total_quantity;
            totals[food_id].totalAmount +=
              total_quantity * food_data.purchase_price;
          } else {
            totals[food_id] = {
              food_id,
              food_name,
              total_quantity,
              totalAmount: total_quantity * food_data.purchase_price,
              food_data_price: food_data.purchase_price,
              foodPicture: food_data.foodPicture,
            };
          }
        }
      }
    });
    return Object.values(totals);
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ alignSelf: "center" }}>
            {intl.formatMessage({
              id: "creationOfReportCanTakeTime",
            })}
          </span>
          <Spinner
            speed="0.85s"
            thickness="5px"
            emptyColor="gray.200"
            color="#0ab44e"
            size="xl"
            style={{ alignSelf: "center", marginTop: "15px" }}
          />
        </div>
      ) : (
        <Row>
          <Col lg="6" xl="4">
            <Card className="mb-3">
              <CardHeader className="card-header-tab text-center flex align-center">
                <div className="card-header-title font-size-lg text-center flex align-center text-capitalize fw-normal">
                  {intl.formatMessage({
                    id: "foodSales",
                  })}
                </div>
              </CardHeader>
              <CardBody className="pt-2 pb-0">
                <h6 className="text-muted text-center text-capitalize font-size-md opacity-9 mb-2 fw-normal">
                  {intl.formatMessage({
                    id: "salesList",
                  })}
                </h6>
                <div className="scroll-area-md shadow-overflow">
                  <PerfectScrollbar>
                    <ListGroup
                      className="rm-list-borders rm-list-borders-scroll"
                      flush
                    >
                      {getDayFoodTotals().map((dish, index) => {
                        return (
                          <ListGroupItem key={`food-${index}`}>
                            <div className="widget-content p-0">
                              <div className="widget-content-wrapper">
                                <div className="widget-content-left me-3">
                                  <img
                                    width={38}
                                    className="wazi-img-list"
                                    src={dish.foodPicture}
                                    alt=""
                                  />
                                </div>
                                <div className="widget-content-left">
                                  <div className="widget-heading">
                                    {dish.food_name}
                                  </div>
                                  <div className="widget-subheading mt-1 opacity-10">
                                    <div className="badge rounded-pill bg-dark">
                                      {new Intl.NumberFormat().format(
                                        dish.food_data_price
                                      )}{" "}
                                      Fcfa
                                    </div>
                                    <div className="badge rounded-pill bg-dark">
                                      Qty: {dish.total_quantity}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="widget-content-right">
                                  <div className="fsize-1 text-focus">
                                    {new Intl.NumberFormat().format(
                                      dish.totalAmount
                                    )}{" "}
                                    Fcfa
                                    <small
                                      className={
                                        index % 2 === 0
                                          ? "text-warning ps-2"
                                          : "text-danger ps-2"
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          index % 2 === 0
                                            ? faAngleUp
                                            : faAngleDown
                                        }
                                      />
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        );
                      })}
                    </ListGroup>
                  </PerfectScrollbar>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" xl="4">
            <Card className="mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize text-center fw-normal">
                  {intl.formatMessage({
                    id: "salesDrinks",
                  })}
                </div>
              </CardHeader>
              <CardBody className="pt-2 pb-0">
                <h6 className="text-muted text-center text-capitalize font-size-md opacity-9 mb-2 fw-normal">
                  {intl.formatMessage({
                    id: "salesList",
                  })}
                </h6>
                <div className="scroll-area-md shadow-overflow">
                  <PerfectScrollbar>
                    <ListGroup
                      className="rm-list-borders rm-list-borders-scroll"
                      flush
                    >
                      {getDayDrinksTotals().map((drink, index) => {
                        return (
                          <ListGroupItem key={`drink-${index}`}>
                            <div className="widget-content p-0">
                              <div className="widget-content-wrapper">
                                <div className="widget-content-left me-3">
                                  <img
                                    width={38}
                                    className="wazi-img-list"
                                    src={drink.drinkPicture}
                                    alt=""
                                  />
                                </div>
                                <div className="widget-content-left">
                                  <div className="widget-heading">
                                    {drink.drink_name}
                                  </div>
                                  <div className="widget-subheading mt-1 opacity-10">
                                    <div className="badge rounded-pill bg-dark">
                                      {new Intl.NumberFormat().format(
                                        drink.drink_data_price
                                      )}{" "}
                                      Fcfa
                                    </div>
                                    <div className="badge rounded-pill bg-dark">
                                      Qty: {drink.total_quantity}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="widget-content-right">
                                  <div className="fsize-1 text-focus">
                                    {new Intl.NumberFormat().format(
                                      drink.totalAmount
                                    )}{" "}
                                    Fcfa
                                    <small
                                      className={
                                        index % 2 === 0
                                          ? "text-warning ps-2"
                                          : "text-danger ps-2"
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          index % 2 === 0
                                            ? faAngleUp
                                            : faAngleDown
                                        }
                                      />
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        );
                      })}
                    </ListGroup>
                  </PerfectScrollbar>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="12" xl="4">
            <Card className="mb-3 total_card">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalizeflex fw-normal">
                  {intl.formatMessage({ id: "summary" })}
                </div>
              </CardHeader>
              <Row>
                <Col lg="6" xl="12">
                  <Card className="no-shadow total_card-head rm-border bg-transparent widget-chart text-start">
                    <div className="main_wrapper">
                      <div className="widget-subheading">
                        {intl.formatMessage({
                          id: "salesFigures",
                        })}
                      </div>
                      <div className="widget-numbers1 text-capitalize">
                        {new Intl.NumberFormat().format(totalDayOrder)} Fcfa
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="row">
                <div className="widget-heading text-capitalize">Details</div>
                <Col lg="6" xl="12" className="col">
                  <Card className="no-shadow total_card-head rm-border bg-transparent widget-chart text-start">
                    <div className="total_wrapper">
                      <div className="widget-subheading text-capitalize">
                        {intl.formatMessage({
                          id: "dish",
                        })}
                      </div>
                      <div className="widget-numbers text-capitalize">
                        {new Intl.NumberFormat().format(totalDayFood)} Fcfa
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg="6" xl="12">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-start mt-2">
                    <div className="total_wrapper">
                      <div className="widget-subheading text-capitalize">
                        {intl.formatMessage({
                          id: "drinks",
                        })}
                      </div>
                      <div className="widget-numbers text-capitalize">
                        {new Intl.NumberFormat().format(totalDayDrinks)} Fcfa
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}
