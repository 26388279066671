import { useForm } from "react-hook-form";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useIntl } from "react-intl";
import { useContext, useState, useEffect } from "react";
import mainService from "../../services/mainService";
import LoadingOverlay from "react-loading-overlay-ts";
import { Loader } from "react-loaders";
import { timeOptions } from "../../utils/timeOptions";

export default function ListTransaction({ toggle, open }) {
  const intl = useIntl();
  const [transactionList, setTransactionList] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = localStorage.getItem("user");
  const mainUser = JSON.parse(user);
  const token = mainUser.token;

  useEffect(() => {
    setLoading(true)
    mainService
      .getTransaction(token)
      .then((response) => {
        setLoading(false)
        setTransactionList(response.data.results)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }, []);

  const { register, handleSubmit, watch, setValue } = useForm();

  const amountWatch = watch().amount_to_add;
  const addOptionWatch = watch().addOption;

  const orderCardStyle = {
    display: "grid",
    gridTemplateColumns: "70% 30%",
    width: "100%",
    padding: "15px",
    margin: "5px",
    cursor: "pointer",
  };

  const getTagColor = (status) => {
    switch (status) {
      case 1:
        return "purple";
      case 2:
        return "green";
      case 3:
        return "red";
      default:
        return "grey";
    }
  };

  const getTagText = (status) => {
    switch (status) {
      case 1:
        return intl.formatMessage({ id: "processing" });
      case 3:
        return intl.formatMessage({ id: "inProgress" });
      case 3:
        return intl.formatMessage({ id: "cancelled" });
      default:
        return "";
    }
  };
  
  return (
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="2">
          {intl.formatMessage({
            id: "listTran",
          })}
        </AccordionHeader>
        <AccordionBody accordionId="2">
        <LoadingOverlay
            tag="div"
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "#fff",
                opacity: 0.5,
              }),
            }}
            spinner={<Loader active={loading} type="ball-pulse-rise" />}
          >
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {
                  transactionList.length > 0
                  ? (transactionList.map((trans, key) => {
                    return (
                      <Card
                        key={trans.id}
                        style={orderCardStyle}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            justifySelf: "flex-start",
                          }}
                        >
                          <span style={{ color: "#0ab44e" }}>
                            <b>{`${intl.formatMessage({ id: "transaction" })} #${
                              trans.reference
                            }`}</b>
                          </span>
                          <span style={{ justifySelf: "center" }}>
                            {new Date(trans.created_at).toLocaleDateString(
                              "en-US",
                              timeOptions
                            )}
                          </span>
                          <span
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <b>{`${trans.method}`}</b>{" "}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            justifySelf: "center",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: "white",
                              backgroundColor: getTagColor(trans.status),
                              padding: "5px",
                              borderRadius: "3px",
                            }}
                          >
                            {getTagText(trans.status)}
                          </span>
                          <span>
                            <b>{`${trans.amount} Fcfa`}</b>
                          </span>
                            <span
                              style={{
                                justifySelf: "center",
                                color: "red",
                              }}
                            >{ trans.transaction_type }</span>
                        </div>
                      </Card>
                    )
                  }) ) 
                  : ("")
                }
              </div>
            </div>
          </LoadingOverlay>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
}
