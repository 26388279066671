import React, { Fragment, useContext, useState } from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  NavLink,
} from "reactstrap";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bg4 from "../../../assets/utils/images/dropdown-header/abstract4.jpg";
import { NotificationsContext } from "../../../context/notificationsContext";
import { useIntl } from "react-intl";

export default function HeaderDots() {
  const intl = useIntl();
  const { notificationMessages } = useContext(NotificationsContext);
  console.log("notificationMessages", notificationMessages);
  return (
    <Fragment>
      <div className="header-dots">
        <UncontrolledDropdown>
          <DropdownToggle className="p-0" color="link">
            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
              <div className="icon-wrapper-bg bg-success" />
              <i className="dot-btn-icon lnr-bullhorn icon-gradient bg-premium-dark" />
              {!!(
                notificationMessages &&
                notificationMessages.filter(
                  (notification) => notification.is_active
                ).length
              ) ? (
                <div
                  style={{
                    width: "13px",
                    height: "13px",
                    opacity: "1",
                    bottom: "0",
                    right: "0",
                    margin: "3px",
                    borderRadius: "50%",
                    position: "absolute",
                    background: "red",
                  }}
                ></div>
              ) : (
                <></>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu end className="dropdown-menu-xl rm-pointers">
            <div className="dropdown-menu-header">
              <div className="dropdown-menu-header-inner bg-premium-dark">
                <div
                  className="menu-header-image"
                  style={{
                    backgroundImage: "url(" + bg4 + ")",
                  }}
                />
                <div className="menu-header-content text-white">
                  <h5 className="menu-header-title">Notifications</h5>
                  <h6 className="menu-header-subtitle">
                    {intl.formatMessage(
                      {
                        id: "unreadMessages",
                      },
                      {
                        messagesCount:
                          notificationMessages &&
                          notificationMessages.filter(
                            (notification) => notification.is_active
                          ).length,
                      }
                    )}
                  </h6>
                </div>
              </div>
            </div>
            <Nav vertical>
              <NavItem className="nav-item-btn text-center">
                <Button
                  size="sm"
                  className="btn-shine btn-wide btn-pill wazi-btn"
                >
                  <NavLink href="/notifications">
                    <FontAwesomeIcon
                      className="me-2"
                      icon={faMailBulk}
                      fixedWidth={false}
                    />
                    {intl.formatMessage({ id: "viewMessages" })}
                  </NavLink>
                </Button>
              </NavItem>
            </Nav>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </Fragment>
  );
}
