import React, { Fragment, useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import cx from "classnames";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Activate from "./pages/Login/activate";
import Vendor from "./pages/Vendor";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profil";
import Menu from "./pages/Menu";
import Plates from "./pages/Plates";
import Restaurants from "./pages/restaurants";
import Drinks from "./pages/Drinks";
import Error from "./pages/Error";
import Notifications from "./pages/Notifications";
import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import { extendTheme } from "@chakra-ui/react";

import ResizeDetector from "react-resize-detector";
import PlateContext from "./context/selectPlates";
import ResetPass from "./pages/ForgotPassword/reset";
import { withRouter } from "./components/HOC/withRouter";
import CreatePassword from "./pages/CreatePassword";
import SecurityQuestion from "./pages/SecurityQuestion/SecurityQuestion";
import ReactGA from "react-ga";
import ForgotIdentifier from "./pages/ForgotIdentifier";
import Orders from "./pages/Orders";
import OrderDetail from "./pages/Orders/OrderDetail";
import { IntlProvider } from "react-intl";
import { LanguageContext, messages } from "./context/languageContext";
import Wallet from "./pages/Wallet";
import NotificationsWebSocket from "./services/NotificationsWebSocket";
import NotificationDetail from "./pages/Notifications/NotificationDetail";
import {Button} from "reactstrap";

ReactGA.initialize("G-1WHGTG4TRX");
ReactGA.pageview(window.location.pathname + window.location.search);

function App(props) {
  let {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;

  const theme = extendTheme({
    colors: {
      brand: {
        primary: "#0ab44e",
        secondary: "#f8b545",
      },
      primary: {
        _default: "#0ab44e",
      },
    },
  });

  const [plate, setPlate] = useState([]);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);

  const { selectedLanguage } = useContext(LanguageContext);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Empêcher le mini-infobar d'apparaître
      e.preventDefault();
      // Sauvegarder l'événement pour l'utiliser plus tard
      setDeferredPrompt(e);
    });

    window.addEventListener('appinstalled', () => {
      // Masquer le bouton lorsque l'application est installée
      setIsInstalled(true);
    });
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
        setIsInstalled(true);
      });
    }
  };

  return (
    
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <Fragment>
          <IntlProvider
            messages={messages[selectedLanguage]}
            locale={selectedLanguage}
          >
            <ChakraProvider theme={theme}>
              <PlateContext.Provider value={{ plate, setPlate }}>
                {
                  !isInstalled && deferredPrompt ? (
                    <Button
                      style={{  position: "fixed", bottom: 0, zIndex: 999999 }}
                      id="install-button"
                      type="button"
                      size="md"
                      className="fw-bold btn btn-login mt-2"
                      block
                      onClick={handleInstallClick}
                    >
                      Installer l'application
                    </Button>
                  )
                  : ("")
                }
                <div
                  className={cx(
                    "app-container app-theme-" + colorScheme,
                    { "fixed-header": enableFixedHeader },
                    { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                    { "fixed-footer": enableFixedFooter },
                    { "closed-sidebar": enableClosedSidebar || width < 1250 },
                    {
                      "closed-sidebar-mobile":
                        closedSmallerSidebar || width < 1250,
                    },
                    { "sidebar-mobile-open": enableMobileMenu },
                    { "body-tabs-shadow-btn": enablePageTabsAlt }
                  )}
                >
                  <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route
                      exact
                      path="/restaurants"
                      element={<Restaurants />}
                    />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/activate" element={<Activate />} />
                    <Route exact path="/register" element={<Register />} />
                    <Route exact path="/vendor" element={<Vendor />} />
                    <Route exact path="/orders" element={<Orders />} />
                    <Route exact path="/orders/:id" element={<OrderDetail />} />
                    <Route
                      exact
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      exact
                      path="/create-password"
                      element={<CreatePassword />}
                    />
                    <Route
                      exact
                      path="/security-questions"
                      element={<SecurityQuestion />}
                    />

                    {/* <Route exact path="/forgot-identifier" element={<ForgotIdentifier />} /> */}
                    <Route
                      exact
                      path="/reset-password"
                      element={<ResetPass />}
                    />
                    <Route exact path="/menus" element={<Menu />} />
                    <Route exact path="/plates" element={<Plates />} />
                    <Route exact path="/drinks" element={<Drinks />} />
                    <Route exact path="/wallet" element={<Wallet />} />
                    <Route
                      exact
                      path="/notifications"
                      element={<Notifications />}
                    />
                    <Route
                      exact
                      path="/notifications/:id"
                      element={<NotificationDetail />}
                    />
                    <Route
                      exact
                      path="/forgot-identifier"
                      element={<ForgotIdentifier />}
                    />
                    <Route path="*" element={<Error />} />
                  </Routes>
                </div>
                <NotificationsWebSocket />
                <ToastContainer
                  position="top-right"
                  autoClose={false}
                  newestOnTop={false}
                  rtl={false}
                  theme="light"
                  closeOnClick={true}
                />
              </PlateContext.Provider>
            </ChakraProvider>
          </IntlProvider>
        </Fragment>
      )}
    />
  );
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(App));
//export default App;
