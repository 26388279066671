import React, { Fragment } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { logout } from "../../../features/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-toastify/dist/ReactToastify.css";

import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg";
import avatar1 from "../../../assets/utils/images/avatars/user.webp";
import { injectIntl } from "react-intl";
import { isMobile } from 'react-device-detect';

class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
    this.bImg = {};
  }

  logOut() {
    logout();
    window.location = "/";
  }

  render() {
    const { intl } = this.props;

    const bImg =
      this.state.user?.picture === null ? city3 : this.state.user?.picture;
    return (
      <Fragment>
        <div className="header-btn-lg pe-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown className="d-flex flex-column">
                  <DropdownToggle
                    color="link"
                    className="p-0 d-flex align-items-center"
                  >
                    <img
                      width={42}
                      className="rounded-circle"
                      src={
                        this.state.user?.picture === null
                          ? avatar1
                          : this.state.user?.picture
                      }
                      alt=""
                    />
                    {
                      !isMobile ? (
                        <div className="header_text">
                          {this.state.user?.first_name} {this.state.user?.last_name}
                        </div>
                      )
                      :("")
                    }
                    <FontAwesomeIcon
                      className="ms-2 opacity-8"
                      icon={faAngleDown}
                    />
                  </DropdownToggle>

                  <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-info">
                        <div
                          className="menu-header-image opacity-2"
                          style={{
                            backgroundImage: `url(${bImg})`,
                          }}
                        />

                        <div className="menu-header-content text-start">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left me-3">
                                <img
                                  width={42}
                                  className="rounded-circle"
                                  src={
                                    this.state.user?.picture === null
                                      ? avatar1
                                      : this.state.user?.picture
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  {this.state.user?.first_name}{" "}
                                  {this.state.user?.last_name}
                                </div>
                              </div>
                              <div className="widget-content-right me-2">
                                <Button
                                  onClick={this.logOut}
                                  className="btn-pill btn-shadow btn-shine"
                                  color="focus"
                                >
                                  {intl.formatMessage({ id: "disconnect" })}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="scroll-area-xs"
                      style={{
                        height: "150px",
                      }}
                    >
                      <PerfectScrollbar>
                        <Nav vertical>
                          <NavItem>
                            <NavLink href="/profile">
                              {intl.formatMessage({ id: "myAccount" })}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="/wallet">My wallet</NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#">
                              {intl.formatMessage({ id: "historical" })}
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </PerfectScrollbar>
                    </div>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div className="widget-content-left  ms-3 header-user-info">
                <div className="widget-heading">
                  {this.state.user?.restaurant}
                </div>
                <div className="widget-subheading">{this.state.user?.role}</div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(UserBox);
