import React from 'react';
import { Player, BigPlayButton } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css";
import YouTube from "react-youtube";

export default  props => {
  return (
      <YouTube
          className={'video-react'}
          iframeClassName={""}
          id={"contentframe"}
          style={{
            margin: "auto",
            textAlign: "center"
          }}
          videoId="TSfQ4KkQifc"/>
  );
};
