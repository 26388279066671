import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  Col,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Input,
  Row,
  UncontrolledButtonDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import Base from "../Base";
import Item from "../../components/Item";
import { isLoggedIn, selectMenu } from "../../utils/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchMenu, removeItem } from "../../features/menu";
import swal from "sweetalert";
import drinkService from "../../services/drinkService";
import menuService from "../../services/menuService";
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import { Spinner } from "@chakra-ui/react";
import img from "../../assets/wazi.png";
// import AsyncCreatableSelect from "react-select/async-creatable/dist/react-select.esm";
import {
  faTimes,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import foodService from "../../services/foodService";
import Slider from "react-slick";
import { useIntl } from "react-intl";
import { isMobile } from 'react-device-detect';

function Menu() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { loading, error, data } = useSelector(selectMenu);
  console.log(data);
  const currentUser = JSON.parse(localStorage.getItem("user"));

  console.log(currentUser);

  useEffect(() => {
    dispatch(fetchMenu(currentUser.restaurant));
  }, [dispatch]);

  const [filter, setFilter] = useState("");
  const [modal, setModal] = useState(false);
  const isConnected = useSelector(isLoggedIn);
  const [message, setMessage] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);
  const [foodType, setFoodType] = useState([]);
  const [foodData, setFoodData] = useState([]);
  const [selectedFood, setSelectedFood] = useState([]);
  const [drinkType, setDrinkType] = useState([]);
  const [drinkData, setDrinkData] = useState([]);
  const [selectedDrink, setSelectedDrink] = useState([]);

  const [load, setLoad] = useState(false);
  const [foodPriceTotal, setFoodPriceTotal] = useState(0);
  const [drinkPriceTotal, setDrinkPriceTotal] = useState(0);

  if (!isConnected) {
    return <Navigate replace to="/login" />;
  }

  const toggleConfirmDelete = (d) => {
    swal({
      text: "Confirmer la suppression du menu",
      title: "Suppression",
      icon: "warning",
      buttons: ["Non", "Oui"],
    }).then((value) => {
      if (value) {
        menuService
          .remove(d.id)
          .then(() => {
            dispatch(removeItem(d));
            setMessage("Menu supprimé avec succès.");
            setItemToDelete(d);
            swal("Menu supprimé avec succès", {
              icon: "success",
            });
          })
          .catch((err) => {
            swal("Erreur lors du traitement de la requete", {
              icon: "error",
            });
          });
      }
    });
  };

  const toggle = () => {
    resetForm();
    setModal(!modal);
  };

  const handleFilter = (event) => {
    setFilter(event.target.value);
  };

  const handleDelete = (drink) => {
    toggleConfirmDelete(drink);
  };

  useEffect(() => {
    foodService
      .getFoodType(currentUser.restaurant_id)
      .then((response) => {
        setFoodType(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    foodService
      .getAllForRestaurant(currentUser.restaurant)
      .then((response) => {
        setFoodData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    drinkService
      .getDrinkCategory()
      .then((response) => {
        setDrinkType(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    drinkService
      .getAllForRestaurant(currentUser.restaurant)
      .then((response) => {
        setDrinkData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [id, setId] = useState(0);
  const [pid, setPeriodId] = useState(0);
  const filters = ["Nom", "Prix", "Catégorie"];

  const [loader, setLoader] = useState(false);

  const closeBtn = (
    <FontAwesomeIcon
      className="close"
      onClick={toggle}
      style={{ cursor: "pointer", fontSize: "1.5rem" }}
      icon={faTimes}
    />
  );

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const nextTab = () => {
    setItemPrice(foodPriceTotal + drinkPriceTotal);
    setPrice(foodPriceTotal + drinkPriceTotal);
    setTabIndex(tabIndex <= 3 ? tabIndex + 1 : 3);
  };

  const prevTab = () => {
    setTabIndex(tabIndex >= 0 ? tabIndex - 1 : 0);
  };

  const [fname, setFName] = useState("");
  const [vfName, setVFname] = useState(false);
  const [dname, setDName] = useState("");
  const [vdName, setVDname] = useState(false);
  const [name, setName] = useState("");
  const [vName, setVname] = useState(false);
  const [description, setDescription] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [price, setPrice] = useState("");
  const [percent, setPercent] = useState("");
  const [status, setStatus] = useState("");
  const [vStatus, setVstatus] = useState(false);
  const [vPrice, setVprice] = useState(false);
  const [vDate, setVdate] = useState("");
  const [eDate, setEndDate] = useState("");
  const [day, setDay] = useState("");
  const [vDay, setVday] = useState(false);
  const [repeat, setRepeat] = useState("");
  const [foodImage, setFoodImage] = useState([]);
  console.log(foodImage, "food images here");
  //2023-06-7
  const today = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  };

  const [sDate, setStartDate] = useState(today());

  const resetForm = () => {
    setId(0);
    setPeriodId(0);
    setTabIndex(0);
    setFName("");
    setDName("");
    setName("");
    setPercent("");
    setStatus("");
    setDescription("");
    setStartDate("");
    setEndDate("");
    setDay("");
    setItemPrice("");
    setPrice("");
    setLoad(false);
    setLoader(false);
    setRepeat("");
    setVFname(false);
    setVDname(false);
    setVname(false);
    setVdate(false);
    setVday(false);
    setVprice(false);
    setSelectedDrink([]);
    setSelectedFood([]);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case "fname":
        {
          setFName(value);
          setLoad(true);
          if (value.length === 0) {
            foodService
              .getAllForRestaurant(currentUser.restaurant)
              .then((response) => {
                setLoad(false);

                setFoodData(response.data);
                displayFood(response.data);
                // response.data.forEach((element) => {
                //   const index = selectedFood.findIndex(
                //     (item) => item.id === element.id
                //   );
                //   const elem = document.querySelector(`#sFood${element.id}`);
                //   if (index === -1) {
                //     elem.checked = false;
                //   } else {
                //     elem.checked = true;
                //   }
                // });
              })
              .catch((error) => {
                setLoad(false);
                setFoodData([]);
              });
          } else {
            foodService
              .getFoodByName(currentUser.restaurant, value)
              .then((response) => {
                setLoad(false);
                let res = response.data.filter((food) =>
                  food.name.toLowerCase().includes(value.toLowerCase())
                );
                setFoodData(res);
                displayFood(res);
                // response.data.forEach((element) => {
                //   const index = selectedFood.findIndex(
                //     (item) => item.id === element.id
                //   );
                //   const elem = document.querySelector(`#sFood${element.id}`);
                //   if (index === -1) {
                //     elem.checked = false;
                //   } else {
                //     elem.checked = true;
                //   }
                // });
              })
              .catch((error) => {
                setLoad(false);
                setFoodData([]);
              });
          }
        }
        break;
      case "dname":
        {
          setDName(value);
          setLoad(true);
          if (value.length === 0) {
            drinkService
              .getAllForRestaurant(currentUser.restaurant)
              .then((response) => {
                setLoad(false);
                setDrinkData(response.data);
                displayDrink(response.data);
                // response.data.forEach((element) => {
                //   const index = selectedDrink.findIndex(
                //     (item) => item.id === element.id
                //   );
                //   const elem = document.querySelector(`#sDrink${element.id}`);
                //   if (index === -1) {
                //     elem.checked = false;
                //   } else {
                //     elem.checked = true;
                //   }
                // });
              })
              .catch((error) => {
                setLoad(false);
                setDrinkData([]);
              });
          } else {
            drinkService
              .getDrinkByName(currentUser.restaurant, value)
              .then((response) => {
                setLoad(false);
                let res = response.data.filter((drink) =>
                  drink.name.toLowerCase().includes(value.toLowerCase())
                );
                setDrinkData(res);
                displayDrink(res);
                // response.data
                // res.forEach((element) => {
                //   const index = selectedDrink.findIndex(
                //     (item) => item.id === element.id
                //   );
                //   const elem = document.querySelector(`#sDrink${element.id}`);
                //   if (index === -1) {
                //     elem.checked = false;
                //   } else {
                //     elem.checked = true;
                //   }
                // });
              })
              .catch((error) => {
                setLoad(false);
                setDrinkData([]);
              });
          }
        }
        break;
      case "name":
        setName(value);
        break;
      case "end":
        setEndDate(value);
        break;
      case "start":
        setStartDate(value);
        break;
      case "day":
        setDay(value);
        break;
      case "repeat":
        if (value === "") {
          setRepeat("");
        } else {
          setRepeat(value === "true");
        }
        break;
      case "percent":
        if (value === "") {
          setPercent("");
          setPrice(itemPrice);
        } else {
          setPercent(value);
          setPrice(Math.floor(((100 - value) / 100) * itemPrice));
        }
        break;
      case "status":
        if (value === "") {
          setStatus("");
        } else {
          setStatus(value);
        }
        break;
      case "description":
        setDescription(value);
        break;
      case "price":
        setPrice(value);
        setPercent(Math.floor(100 - (value / itemPrice) * 100));
        break;
      default:
        break;
    }
  };

  let classes = ["btn-login", "btn-login2", "btn-login-alt", "btn-login-alt2"];

  function getRandomItem(arr) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const item = arr[randomIndex];
    return item;
  }

  const fetchFoodType = (ft) => {
    setFType(ft);
    setLoad(true);
    foodService
      .getFoodByType(currentUser.restaurant, ft, fname)
      .then((response) => {
        setLoad(false);
        setFoodData(response.data);
        displayFood(response.data);
      })
      .catch((error) => {
        setLoad(false);
        setFoodData([]);
      });
  };

  const fetchDrinkType = (dId, dname) => {
    setDType(dname);
    setLoad(true);
    drinkService
      .getDrinkByCategory(currentUser.restaurant_id, dId, dname)
      .then((response) => {
        setLoad(false);
        setDrinkData(response.data);
        displayDrink(response.data);
      })
      .catch((error) => {
        setLoad(false);
        setDrinkData([]);
      });
  };

  const clearFoodFilter = () => {
    setFType("");
    setLoad(true);
    foodService
      .getAllForRestaurant(currentUser.restaurant)
      .then((response) => {
        setLoad(false);
        setFoodData(response.data);
        displayFood(foodData);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  function displayFood(response) {
    response.forEach((element) => {
      const index = selectedFood.findIndex((item) => item.id === element.id);
      const elem = document.getElementById(`#sFood${element.id}`);
      if (elem !== null) {
        if (index === -1) {
          elem.checked = false;
        } else {
          elem.checked = true;
        }
      }
    });

    setTimeout(function () {
      selectedFood.forEach((element) => {
        handleSelectedFood(element);
      });
    }, 1000);
  }

  function displayDrink(response) {
    response.forEach((element) => {
      const index = selectedDrink.findIndex((item) => item.id === element.id);
      const elem = document.querySelector(`#sDrink${element.id}`);
      if (elem !== null) {
        if (index === -1) {
          elem.checked = false;
        } else {
          elem.checked = true;
        }
      }
    });
  }

  const clearDrinkFilter = () => {
    setDType("");
    setLoad(true);
    drinkService
      .getAllForRestaurant(currentUser.restaurant)
      .then((response) => {
        setLoad(false);
        setDrinkData(response.data);

        displayDrink(response.data);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSelectedFood = (it) => {
    let arr = selectedFood;
    const index = arr.findIndex((item) => item.id === it.id);
    const elem = document.querySelector(`#sFood${it.id}`);
    if (elem !== undefined && elem !== null) {
      if (index === -1) {
        arr.push(it);
        elem.checked = true;
      } else {
        arr.splice(index, 1);
        elem.checked = false;
      }
    }
    let _foodPriceTotal = 0;
    selectedFood.forEach((item) => {
      _foodPriceTotal = _foodPriceTotal + Math.ceil(item.price);
    });
    setFoodPriceTotal(_foodPriceTotal);
    // dispatch(foodPriceTotal);
    setSelectedFood(selectedFood);
  };

  const handleSelectedDrink = (it) => {
    let arr = selectedDrink;
    const index = arr.findIndex((item) => item.id === it.id);
    const elem = document.querySelector(`#sDrink${it.id}`);
    if (elem !== null) {
      if (index === -1) {
        arr.push(it);
        elem.checked = true;
      } else {
        arr.splice(index, 1);
        elem.checked = false;
      }
    }

    let _drinkPriceTotal = 0;
    selectedDrink.forEach((item) => {
      _drinkPriceTotal = _drinkPriceTotal + Math.ceil(item.price);
      ///TODO: Contemplate on menu
      ///1) you cannot select all drink cause they might have various prices
      ///2) difficult to create a menu which has 2 drinks
    });
    setDrinkPriceTotal(_drinkPriceTotal);
    setSelectedDrink(selectedDrink);
  };

  const edit = (menu) => {
    setTabIndex(0);
    setId(menu.id);
    setName(menu.name);
    setItemPrice(menu.real_price);
    setPrice(menu.real_price);
    setPercent(menu.percent);
    setDescription(menu.description);
    setStatus(menu.status_price);
    setDay(menu.menu_day);
    // setPeriodId(menu.period.id);
    setRepeat(menu.repeat === "" ? "" : menu.repeat);
    setStartDate(menu.publication_start_date);
    setEndDate(menu.publication_end_date);

    setModal(true);

    setTimeout(function () {
      menu.foods.forEach((element) => {
        handleSelectedFood(element);
      });
      menu.drinks.forEach((element) => {
        handleSelectedDrink(element);
      });
    }, 1000);
  };

  function isSelect(id, table) {
    return table.includes(id);
  }

  const toggleConfirm = () => {
    const d = new Date();

    const title =
      id === 0
        ? intl.formatMessage({ id: "confirmAdditionOfMenu" })
        : intl.formatMessage({ id: "confirmMenuUpdate" });
    const buttonText = id === 0 ? "Ajouter" : "Mettre à jour";
    const successText =
      id === 0
        ? intl.formatMessage({ id: "menuAddedSuccessfully" })
        : intl.formatMessage({ id: "menuUpdatedSuccessfully" });

    swal({
      text: title,
      title: "Confirmation",
      icon: "info",
      buttons: ["Annuler", buttonText],
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm) {
        setLoader(true);
        if (id === 0) {
          setLoader(true);
          const formData = new FormData();
          /*formData.append("name", "Menu Period " + day + " " + d.getTime());
          formData.append("description", "Period du jour : " + day);
          formData.append("start_date", sDate);
          formData.append("end_date", eDate);
          formData.append("menu_day", parseInt(day));
          formData.append("repeat", repeat === "1" ? true : false);*/
          // menuService.createPeriod(formData).then((response) => {
          const fData = new FormData();
          fData.append("name", name);
          fData.append("description", description);
          fData.append("status_price", status);
          fData.append("real_price", price);
          fData.append("percent", percent);
          fData.append("menu_day", day === "" ? 8 : parseInt(day));
          fData.append("repeat", repeat);
          let sd = sDate;
          if (sDate == "") {
            sd = today();
          }
          fData.append("publication_start_date", sd);
          fData.append("publication_end_date", eDate);

          // for (let i = 0; i < selectedDrink.length; i++) {
          //   fData.append("drinks[" + i + "]", selectedDrink[i].id);
          // }
          selectedDrink.forEach((element) => {
            fData.append("drinks", element.id);
          });
          selectedFood.forEach((element) => {
            fData.append("foods", element.id);
          });

          // for (let i = 0; i < selectedFood.length; i++) {
          //   fData.append("foods[" + i + "]", selectedFood[i].id);
          // }
          menuService
            .create(fData)
            .then((response) => {
              setLoader(false);
              dispatch(fetchMenu(currentUser.restaurant));
              resetForm();
              toggle();
              swal(successText, {
                icon: "success",
              });
            })
            .catch((error) => {
              setLoader(false);
              if (error.response.data.message.includes("status_price")) {
                swal("Erreur", "Le statut du prix est invalide", "error");
              } else if (error.response.data.message.includes("period")) {
                swal(
                  "Erreur",
                  intl.formatMessage({ id: "thePublicationPeriodIsInvalid" }),
                  "error"
                );
              } else if (error.response.data.message.includes("name")) {
                swal(
                  "Erreur",
                  intl.formatMessage({ id: "theMenuNameIsInvalid" }),
                  "error"
                );
              } else if (error.response.data.message.includes("foods")) {
                swal(
                  "Erreur",
                  intl.formatMessage({ id: "noFoodSelected" }),
                  "error"
                );
              } else if (error.response.data.message.includes("drinks")) {
                swal(
                  "Erreur",
                  intl.formatMessage({ id: "noDrinksSelected" }),
                  "error"
                );
              } else if (error.response.status === 409) {
                swal("Erreur", error.response.data.message, "error");
              } else if (error) {
                swal("Erreur", error.message, "error");
              } else {
                swal.stopLoading();
                swal.close();
              }
            })
            .catch((error) => {
              setLoader(false);
              if (error.response.data.message.includes("menu_day")) {
                swal(
                  "Erreur",
                  intl.formatMessage({ id: "menuDayIsInvalid" }),
                  "error"
                );
              } else if (error.response.status === 409) {
                swal("Erreur", error.response.data.message, "error");
              } else if (error) {
                swal("Erreur", error.message, "error");
              } else {
                swal.stopLoading();
                swal.close();
              }
            });
          // });
        } else {
          setLoader(true);
          const formData = new FormData();
          formData.append("name", "Menu Period " + day + " " + d.getTime());
          formData.append("description", "Period du jour : " + day);
          formData.append("start_date", sDate);
          formData.append("end_date", eDate);
          formData.append("menu_day", parseInt(day));
          formData.append("repeat", repeat);
          menuService.updatePeriod(pid, formData).then((response) => {
            const fData = new FormData();
            fData.append("name", name);
            fData.append("description", description);
            fData.append("status_price", status);
            fData.append("real_price", price);
            fData.append("percent", percent);
            // fData.append("period", response.data.id);
            selectedDrink.forEach((element) => {
              fData.append("drinks", element.id);
            });
            selectedFood.forEach((element) => {
              fData.append("foods", element.id);
            });
            menuService
              .update(id, fData)
              .then((response) => {
                setLoader(false);
                dispatch(fetchMenu(currentUser.restaurant));
                resetForm();
                toggle();
                swal(successText, {
                  icon: "success",
                });
              })
              .catch((error) => {
                setLoader(false);
                if (error.response.data.message.includes("status_price")) {
                  swal("Erreur", "Le statut du prix est invalide", "error");
                } else if (error.response.data.message.includes("period")) {
                  swal(
                    "Erreur",
                    "La periode de publication est invalide",
                    "error"
                  );
                } else if (error.response.data.message.includes("name")) {
                  swal("Erreur", "Le nom du menu est invalide", "error");
                } else if (error.response.data.message.includes("foods")) {
                  swal("Erreur", "Aucun food selectionné", "error");
                } else if (error.response.data.message.includes("drinks")) {
                  swal("Erreur", "Aucune boisson selectionné", "error");
                } else if (error.response.status === 409) {
                  swal("Erreur", error.response.data.message, "error");
                } else if (error) {
                  swal("Erreur", error.message, "error");
                } else {
                  swal.stopLoading();
                  swal.close();
                }
              })
              .catch((error) => {
                setLoader(false);
                if (error.response.data.message.includes("menu_day")) {
                  swal("Erreur", "Le jour du menu est invalide", "error");
                } else if (error.response.status === 409) {
                  swal("Erreur", error.response.data.message, "error");
                } else if (error) {
                  swal("Erreur", error.message, "error");
                } else {
                  swal.stopLoading();
                  swal.close();
                }
              });
          });
        }
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validate();

    if (name === "") {
      setVname(true);
    }
    if (price <= 0 || isNaN(price)) {
      setVprice(true);
    }
    // if (day === "") {
    //   setVday(true);
    // }
    // if (sDate === "") {
    //   setVdate(true);
    // }
    if (status === "") {
      setVstatus(true);
    }
    if (status !== "" && name !== "" && price > 0 && !isNaN(price)) {
      validate();
      toggleConfirm();
    }
  };

  function validate() {
    setVname(false);
    setVprice(false);
    setVday(false);
    setVdate(false);
    setVstatus(false);
  }

  const [dType, setDType] = useState("");
  const [fType, setFType] = useState("");

  return (
    <Base>
      <div className="app-inner-layout">
        <div className="app-inner-layout__header-boxed p-0">
          <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
            <div>
              <Input
                value={filter}
                onChange={handleFilter}
                type="select"
                name="filter"
                id="filter"
              >
                <option value="">Afficher par</option>
                {filters.map((filter, index) => (
                  <option key={`filter-${index}`} value={filter}>
                    {filter}
                  </option>
                ))}
              </Input>
            </div>
            <h1 className="fs-3" style={ isMobile ? {fontSize: "1.2em", marginLeft: "10px"} : {}}>
              {intl.formatMessage({ id: "managementOfMenusAndPromotions" })}
            </h1>
            <div>
              <Button
                className="btn-icon btn-icon-only pt-0 ps-2 wazi-btn-add float-end wazi-btn"
                onClick={toggle}
              >
                <FontAwesomeIcon icon="plus" />
              </Button>
            </div>
          </div>
        </div>

        <Row>
          <Col md="12">
            <Modal
              id="modal"
              className="wazi-modal"
              isOpen={modal}
              toggle={toggle}
              size="xl"
              backdrop="static"
            >
              <ModalHeader
                style={{ fontSize: "1.5rem" }}
                toggle={toggle}
                close={closeBtn}
              >
                <FontAwesomeIcon icon="utensils" />
                <FontAwesomeIcon icon="wine-bottle" />
                &nbsp;&nbsp;
                {id === 0
                  ? intl.formatMessage({ id: "addMenusAndPromotions" })
                  : intl.formatMessage({ id: "updatedMenusAndPromotions" })}
              </ModalHeader>
              <ModalBody>
                {loader ? (
                  <div className="text-center py-5">
                    <div className="text-center py-5">
                      <div className="text-center py-5">
                        <Spinner
                          thickness="4px"
                          emptyColor="gray.200"
                          color="green.500"
                          size="xl"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Form>
                    <Tabs
                      isFitted
                      variant="soft-rounded"
                      colorScheme="green"
                      index={tabIndex}
                      onChange={handleTabsChange}
                    >
                      <TabList className="my-3 px-5">
                        <div className="px-5 d-flex justify-content-center w-100">
                          <Tab>Food</Tab>
                          <Tab>{intl.formatMessage({ id: "drinks" })}</Tab>
                          <Tab>Détails</Tab>
                        </div>
                      </TabList>

                      <TabPanels>
                        <TabPanel className="pt-5 px-5">
                          <FormGroup>
                            <Input value={id} type="hidden" name="id" id="id" />
                          </FormGroup>

                          <FormGroup row>
                            <Label for="name" sm={2}>
                              {intl.formatMessage({ id: "name" })} *
                            </Label>
                            <Col sm={10}>
                              <Input
                                value={fname}
                                invalid={vfName}
                                onChange={handleChange}
                                type="text"
                                name="fname"
                                id="fname"
                                placeholder={intl.formatMessage({
                                  id: "enterName",
                                })}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mt-3 mb-3" row>
                            <Label for="name" sm={2}>
                              Tags
                            </Label>
                            <Col sm={10}>
                              <Button
                                type="button"
                                onClick={() => clearFoodFilter()}
                                size="sm"
                                className={"fw-bold me-1 mt-2 btn btn-login"}
                              >
                                {intl.formatMessage({ id: "allFoods" })}
                              </Button>
                              {foodType.map((ft, i) => {
                                return (
                                  <Button
                                    type="button"
                                    onClick={() => fetchFoodType(ft.name)}
                                    size="sm"
                                    className={
                                      "fw-bold me-1 mt-2 btn " +
                                      getRandomItem(classes) +
                                      ""
                                    }
                                  >
                                    {ft.name}
                                  </Button>
                                );
                              })}
                            </Col>
                          </FormGroup>

                          <FormGroup className="" row>
                            <Label for="name" sm={2}>
                              {intl.formatMessage({ id: "selected" })}
                            </Label>
                            <Col className="mt-4" sm={10}>
                              {load ? (
                                <div className="text-center">
                                  <div className="text-center py-5">
                                    <div className="text-center py-5">
                                      <Spinner
                                        thickness="4px"
                                        emptyColor="gray.200"
                                        color="green.500"
                                        size="xl"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {foodData.length > 0 ? (
                                    <>
                                      <Slider {...settings}>
                                        {foodData.map((item) => (
                                          <div
                                            key={`plate-${item.id}`}
                                            className="px-2"
                                          >
                                            <Card className="wazi-card-item card-shadow-primary card-border text-white mb-3">
                                              <div className="btn-actions-pane-right actions-icon-btn">
                                                <FormGroup check>
                                                  <Label check>
                                                    <Input
                                                      defaultChecked={isSelect(
                                                        item.id,
                                                        selectedFood
                                                      )}
                                                      type="checkbox"
                                                      id={`sFood${item.id}`}
                                                      onChange={(e) =>
                                                        handleSelectedFood(item)
                                                      }
                                                    />
                                                  </Label>
                                                </FormGroup>
                                              </div>
                                              <Item
                                                name={item.name}
                                                price={Math.ceil(item.price)}
                                                image={item.foodPicture}
                                              />
                                            </Card>
                                          </div>
                                        ))}
                                      </Slider>
                                    </>
                                  ) : (
                                    <div className="p-5 text-center">
                                      <FontAwesomeIcon
                                        icon="utensils"
                                        style={{}}
                                      />
                                      <br />
                                      {intl.formatMessage({
                                        id: "noFoodAvailable",
                                      })}
                                    </div>
                                  )}
                                </>
                              )}
                            </Col>
                          </FormGroup>
                        </TabPanel>

                        <TabPanel className="pt-5 px-5">
                          <FormGroup row>
                            <Label for="name" sm={2}>
                              {intl.formatMessage({ id: "name" })} *
                            </Label>
                            <Col sm={10}>
                              <Input
                                value={dname}
                                invalid={vdName}
                                onChange={handleChange}
                                type="text"
                                name="dname"
                                id="dname"
                                placeholder={intl.formatMessage({
                                  id: "enterName",
                                })}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mt-3 mb-3" row>
                            <Label for="name" sm={2}>
                              Tags
                            </Label>
                            <Col sm={10}>
                              <Button
                                type="button"
                                onClick={() => clearDrinkFilter()}
                                size="sm"
                                className={"fw-bold me-1 mt-2 btn btn-login"}
                              >
                                {intl.formatMessage({ id: "allDrinks" })}
                              </Button>
                              {drinkType.map((dt, i) => {
                                return (
                                  <Button
                                    type="button"
                                    onClick={() =>
                                      fetchDrinkType(dt.id, dt.name)
                                    }
                                    size="sm"
                                    className={
                                      "fw-bold me-1 mt-2 btn " +
                                      getRandomItem(classes) +
                                      ""
                                    }
                                  >
                                    {dt.name}
                                  </Button>
                                );
                              })}
                            </Col>
                          </FormGroup>

                          <FormGroup className="" row>
                            <Label for="name" sm={2}>
                              {intl.formatMessage({ id: "selected" })}
                            </Label>
                            <Col className="mt-4" sm={10}>
                              {load ? (
                                <div className="text-center">
                                  <div className="text-center py-5">
                                    <div className="text-center py-5">
                                      <Spinner
                                        thickness="4px"
                                        emptyColor="gray.200"
                                        color="green.500"
                                        size="xl"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {drinkData.length > 0 ? (
                                    <>
                                      <Slider {...settings}>
                                        {drinkData.map((item) => (
                                          <div
                                            key={`plate-${item.id}`}
                                            className="px-2"
                                          >
                                            <Card className="wazi-card-item card-shadow-primary card-border text-white mb-3">
                                              <div className="btn-actions-pane-right actions-icon-btn">
                                                <FormGroup check>
                                                  <Label check>
                                                    <Input
                                                      type="checkbox"
                                                      id={`sDrink${item.id}`}
                                                      onChange={() =>
                                                        handleSelectedDrink(
                                                          item
                                                        )
                                                      }
                                                    />
                                                  </Label>
                                                </FormGroup>
                                              </div>
                                              <Item
                                                name={item.name}
                                                price={Math.ceil(item.price)}
                                                image={item.drinkPicture}
                                              />
                                            </Card>
                                          </div>
                                        ))}
                                      </Slider>
                                    </>
                                  ) : (
                                    <div className="p-5 text-center">
                                      <FontAwesomeIcon
                                        icon="wine-bottle"
                                        style={{}}
                                      />
                                      <br />
                                      {intl.formatMessage({
                                        id: "noDrinksAvailable",
                                      })}
                                    </div>
                                  )}
                                </>
                              )}
                            </Col>
                          </FormGroup>
                        </TabPanel>

                        <TabPanel className="pt-5 px-5">
                          <FormGroup row>
                            <Label sm={2}>Composition</Label>
                            <Col sm={10}>
                              {selectedFood.length === 0 &&
                              selectedDrink.length === 0 ? (
                                <div className={"pt-2"}>
                                  <b className="text-danger">
                                    {intl.formatMessage({
                                      id: "noFoodOrDrinkSelected",
                                    })}
                                  </b>
                                </div>
                              ) : (
                                <Slider {...settings}>
                                  {selectedFood.map((item) => (
                                    <div
                                      key={`food-${item.id}`}
                                      className="px-2 mb-4"
                                    >
                                      <div className="wazi-card-item card-shadow-primary card-border text-center px-3 py-2">
                                        <FontAwesomeIcon icon="utensils" />{" "}
                                        &nbsp; <b>{item.name}</b> (
                                        {Math.ceil(item.price)} FCFA)
                                      </div>
                                    </div>
                                  ))}
                                  {selectedDrink.map((item) => (
                                    <div
                                      key={`drink-${item.id}`}
                                      className="px-2 mb-4"
                                    >
                                      <div className="wazi-card-item card-shadow-primary card-border text-center px-3 py-2">
                                        <FontAwesomeIcon icon="wine-bottle" />{" "}
                                        &nbsp; <b>{item.name}</b> (
                                        {Math.ceil(item.price)} FCFA)
                                      </div>
                                    </div>
                                  ))}
                                </Slider>
                              )}
                            </Col>
                          </FormGroup>

                          <FormGroup className="mt-3 mb-3" row>
                            <Label for="name" sm={2}>
                              {intl.formatMessage({ id: "name" })} *
                            </Label>
                            <Col sm={10}>
                              <Input
                                value={name}
                                invalid={vName}
                                onChange={handleChange}
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Entrer le nom"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label for="description" sm={2}>
                              Description *
                            </Label>
                            <Col sm={10}>
                              <Input
                                value={description}
                                onChange={handleChange}
                                type="textarea"
                                maxLength={700}
                                name="description"
                                id="description"
                                placeholder="Entrez la description"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label sm={2}>
                              {intl.formatMessage({
                                id: "calculatedPriceFCFA",
                              })}
                            </Label>
                            <Col sm={10}>
                              <Input
                                value={itemPrice}
                                invalid={vPrice}
                                onChange={handleChange}
                                disabled={true}
                                type="number"
                                name="itemPrice"
                                id="itemPrice"
                                placeholder="000"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label sm={2}>
                              {intl.formatMessage({ id: "percentage" })}
                              <br />
                              <span
                                className={"text-muted"}
                                style={{ fontSize: "12px" }}
                              >
                                (Hausse ou rabais en %)
                              </span>
                            </Label>
                            <Col sm={4}>
                              <Input
                                value={status}
                                onChange={handleChange}
                                type="select"
                                invalid={vStatus}
                                name="status"
                                // style={{ backgroundColor: '#0ab44e', color: '#fff' }}
                                id="status"
                              >
                                <option value="">
                                  {intl.formatMessage({
                                    id: "chooseFinalPrice",
                                  })}
                                </option>
                                <option value="1">
                                  {intl.formatMessage({
                                    id: "chooseFinalPrice",
                                  })}
                                </option>
                                <option value="2">
                                  {intl.formatMessage({ id: "initialPrice" })}
                                </option>
                              </Input>
                            </Col>
                            <Col sm={6}>
                              <Input
                                value={percent}
                                onChange={handleChange}
                                type="number"
                                name="percent"
                                disabled={
                                  status === "" || status === "2" ? true : false
                                }
                                id="percent"
                                placeholder="0.0 %"
                              />
                            </Col>
                          </FormGroup>

                          {status !== "" ? (
                            <FormGroup row>
                              <Label sm={2}>
                                {intl.formatMessage({
                                  id: "newCalculatedPriceFCFA",
                                })}
                              </Label>
                              <Col sm={10}>
                                <Input
                                  value={price}
                                  invalid={vPrice}
                                  onChange={handleChange}
                                  disabled={
                                    status === "" || status === "2"
                                      ? true
                                      : false
                                  }
                                  type="number"
                                  name="price"
                                  id="price"
                                  placeholder="000"
                                />
                              </Col>
                            </FormGroup>
                          ) : (
                            <div></div>
                          )}

                          <FormGroup row>
                            <Label sm={2}>
                              {intl.formatMessage({ id: "publicationDay" })}
                            </Label>
                            <Col sm={2}>
                              <Input
                                value={day}
                                invalid={vDay}
                                onChange={handleChange}
                                type="select"
                                name="day"
                                style={{
                                  backgroundColor: "#0ab44e",
                                  color: "#fff",
                                }}
                                id="day"
                              >
                                <option value="">Jour</option>
                                <option value="1">
                                  {intl.formatMessage({ id: "monday" })}
                                </option>
                                <option value="2">
                                  {intl.formatMessage({ id: "tuesday" })}
                                </option>
                                <option value="3">
                                  {intl.formatMessage({ id: "wednesday" })}
                                </option>
                                <option value="4">
                                  {intl.formatMessage({ id: "thursday" })}
                                </option>
                                <option value="5">
                                  {intl.formatMessage({ id: "friday" })}
                                </option>
                                <option value="6">
                                  {intl.formatMessage({ id: "saturday" })}
                                </option>
                                <option value="7">
                                  {intl.formatMessage({ id: "sunday" })}
                                </option>
                              </Input>
                            </Col>
                            <Col sm={3}>
                              <Input
                                value={sDate}
                                invalid={vDate}
                                onChange={handleChange}
                                type="date"
                                name="start"
                                id="start"
                                placeholder="Début"
                              />
                              <span>
                                {intl.formatMessage({ id: "startDate" })}
                              </span>
                            </Col>
                            <Col sm={3}>
                              <Input
                                value={eDate}
                                onChange={handleChange}
                                type="date"
                                name="end"
                                id="end"
                                placeholder="Fin"
                              />
                              <span>Date de fin</span>
                            </Col>
                            <Col sm={2}>
                              <Input
                                value={repeat}
                                onChange={handleChange}
                                type="select"
                                name="repeat"
                                style={{
                                  backgroundColor: "#0ab44e",
                                  color: "#fff",
                                }}
                                id="repeat"
                              >
                                <option value="">
                                  {intl.formatMessage({ id: "repeat" })}
                                </option>
                                <option value="true">
                                  {intl.formatMessage({ id: "yes" })}
                                </option>
                                <option value="false">
                                  {intl.formatMessage({ id: "no" })}
                                </option>
                              </Input>
                            </Col>
                          </FormGroup>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>

                    <Row className="mt-5">
                      <Col sm={{ size: 3, offset: 6 }}>
                        {tabIndex > 0 ? (
                          <Button
                            onClick={() => prevTab()}
                            type="button"
                            size="sm"
                            className="fw-bold btn btn-login mt-2"
                            block
                          >
                            <FontAwesomeIcon icon={faArrowLeft} /> &nbsp;
                            Précédent
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col sm={{ size: 3 }}>
                        {tabIndex === 2 ? (
                          <Button
                            onClick={(e) => handleSubmit(e)}
                            type="button"
                            size="sm"
                            className="fw-bold btn btn-login mt-2"
                            block
                          >
                            Terminer
                          </Button>
                        ) : (
                          <Button
                            onClick={() => nextTab()}
                            type="button"
                            size="sm"
                            className="fw-bold btn btn-login mt-2"
                            block
                          >
                            Suivant &nbsp;{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </ModalBody>
            </Modal>
          </Col>
        </Row>

        <LoadingOverlay
          tag="div"
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "#fff",
              opacity: 0.5,
            }),
          }}
          spinner={<Loader active={loading} type="ball-pulse-rise" />}
        >
          <Row>
            {data.map((item, index) => (
              <Col md="12" lg="6" xl="3">
                <Card className="wazi-card-item card-shadow-primary card-border text-white mb-3">
                  <CardHeader>
                    <div className="btn-actions-pane-right actions-icon-btn">
                      <UncontrolledButtonDropdown>
                        <DropdownToggle
                          className="btn-icon btn-icon-only"
                          color="link"
                        >
                          <FontAwesomeIcon
                            icon="ellipsis-v"
                            className="btn-icon-wrapper"
                          />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link">
                          <DropdownItem
                            onClick={function (e) {
                              edit(item);
                            }}
                          >
                            <i className="dropdown-icon lnr-pencil"> </i>
                            <span>{intl.formatMessage({ id: "modify" })}</span>
                          </DropdownItem>
                          <DropdownItem onClick={(e) => handleDelete(item)}>
                            <i className="dropdown-icon lnr-trash"> </i>
                            <span>{intl.formatMessage({ id: "delete" })}</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </div>
                  </CardHeader>
                  <Item
                    name={item.name}
                    price={item.real_price}
                    image={item.foods[0]?.foodPicture ?? img}
                    image1={item.drinks[0]?.drinkPicture ?? img}
                  />
                  <div className="item-detail">
                    <h5
                      style={{
                        position: "absolute",
                        bottom: "30px",
                        left: "35%",
                      }}
                    >
                      {intl.formatMessage({ id: "menuDetails" })}
                    </h5>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </LoadingOverlay>
      </div>
    </Base>
  );
}

export default Menu;
