import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Button, Form, FormGroup, Label } from "reactstrap";
import logo from "../../assets/utils/images/logo.png";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../../utils/selectors";
import { Navigate } from "react-router-dom";
import { InputGroup, InputGroupText, Input, FormFeedback } from "reactstrap";
import swal from "sweetalert";
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faKey,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { reset } from "../../features/auth";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isValidPhoneNumber } from "react-phone-number-input";

import { event } from "jquery";
import drinkService from "../../services/drinkService";

export default function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const isConnected = useSelector(isLoggedIn);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("Numéro téléphone invalide");

  const [uname, setUname] = useState(false);

  const questions = [
    "Le nom de votre quartier de résidence",
    "Votre couleur préférée",
    "Nom de l'école primaire que vous avez fréquenté",
  ];



  useEffect(() => {
    const pos = Math.floor(Math.random() * 3);
    setQuestion(questions[pos]);
  }, [])

  if (isConnected) {
    return <Navigate replace to="/" />;
  }

  const handleSubmit = () => {
    event.preventDefault;
    let v = isValidPhoneNumber(username);
    if (v === false) {
      setUname(true);
      setError("Numéro de téléphone invalide pour ce pays");
    } else {
      setLoading(true); // Afficher le logo de chargement
      setUname(false); // Valider le champ email/telephone
      reset({
        'phone': username,
        'security_question': question,
        'security_response': answer
      })
        .then((response) => {
          setLoading(false);
          swal(
            "SMS Envoyé",
            "Le lien de reinitialisation a été envoyé",
            "success"
          );
          window.location = "/login";
        })
        .catch((error) => {
          setLoading(false);
          setUname(true);
          setError("Ce numéro téléphone ne correspond à aucun compte");
        });
    }
  };

  function validate(str) {
    return str.length > 0;
  }

  return (
    <Fragment>
      <div style={{ overflow: 'hidden' }}>
        <Row className="bg-white g-0 main-section-login">
          <Col
            lg="6"
            style={{ height: "100%" }}
            className="pt-4 wazieats-bg-primary-color section1-login justify-content-center align-items-center"
          >
            <div className="text-light td">
              <div className="ts">
                <img src={logo} alt="Logo wazieats" width="60" height="60" />
              </div>
              <h3 className="fs-1 pt-3">
                Wazi<b>Food</b>
              </h3>
              <h3 className="fs-6 pb-3 show">PARTENAIRE</h3>
            </div>
          </Col>

          <Col
            lg="6"
            style={{ height: "100%" }}
            className="bg-white section2-login"
          >
            <div className="text-dark d-flex flex-column justify-content-center align-items-center">
              <LoadingOverlay
                className="p-5"
                tag="div"
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                  }),
                }}
                spinner={<Loader active={loading} type="ball-pulse-rise" />}
              >
                <Form
                  autoComplete="false"
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                  className="px-5"
                >
                  <Row className="text-center py-4 mb-3 mt-2">
                    <h2 class="fs-2">Réinitialiser mon mot de passe</h2>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup className="position-relative p-0">
                        <Row
                          noGutters
                          style={{
                            backgroundColor: "#eee",
                            borderRadius: "5px",
                          }}
                        >
                          <Col md={11}>
                            <PhoneInput
                              value={username}
                              onChange={(phone, data) => {
                                setUsername("+" + phone);
                                let v = phone.slice(data.dialCode.length);
                                setUname(!validate(v));
                                setError("Numéro téléphone vide");
                              }}
                              inputProps={{
                                name: "phone",
                                className: "form-control tel",
                                required: true,
                                autoFocus: true,
                              }}
                              preferredCountries={["cm"]}
                              enableSearch="true"
                              className="p-0"
                              country={"cm"}
                            />
                          </Col>
                          <Col
                            md={1}
                            id="phoneIcon"
                            className="text-center align-self-center d-none d-md-block"
                          >
                            <FontAwesomeIcon
                              style={{ color: "#000" }}
                              icon={faPhoneAlt}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            // backgroundColor: "#eee",
                            borderRadius: "5px",
                          }}>

                          <Row className="justify-content-center mt-3 align-items-center" />
                          <Col md={12}>
                            <Label for="type">
                              {question} *
                            </Label>
                          </Col>
                        </Row>
                        <Row md={12}>
                          <Col md={12}>
                            <InputGroup size="lg" className="mt-1">
                              <Input
                                type={"text"}
                                name="answer"
                                id="answer"
                                value={answer}
                                style={{ backgroundColor: "#eee", borderColor: "#eee" }}
                                bsSize="lg"
                                placeholder="Entrez votre réponse"
                                onChange={(e) => {
                                  setAnswer(e.target.value)
                                }} />
                            </InputGroup>
                          </Col>
                        </Row>
                        {/* <FormFeedback className={uname ? "d-block" : ""}>
                          {error}
                        </FormFeedback> */}
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="d-flex align-items-center flex-column">
                    <Button
                      size="lg"
                      block
                      className="btn btn-login"
                      onClick={handleSubmit}
                    >
                      Réinitialiser le mot de passe
                    </Button>
                  </div>
                  <div className="d-flex align-items-center flex-column mt-3">
                    <a
                      href="/forgot-identifier"
                      // size="lg"
                      // block
                      className="btn btn-login btn-lg w-100"
                    >
                      Identifiant oublié, cliquez ici
                    </a>
                  </div>

                  <div className="d-flex text-dark fw-bold justify-content-center mt-5">
                    Retour à la &nbsp;{" "}
                    <a href="/login" className="wazieats-2-link">
                      page de connexion
                    </a>
                  </div>
                </Form>
              </LoadingOverlay>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
