import { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classnames from "classnames";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import { useIntl } from "react-intl";
import DashboardByDate from "./DashboardByDate";
import {
  getCurrentDateFormatted,
  subtractOneWeek,
  subtractOneMonth,
  subtractOneYear,
} from "./utils";
import mainService from "../../services/mainService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TotalOrdersWidget from "../../components/TotalOrdersWidget/TotalOrdersWidget";

export default function Content() {
  const intl = useIntl();

  const user = localStorage.getItem("user");
  const mainUser = JSON.parse(user);
  const token = mainUser.token;

  const [activeTab, setActiveTab] = useState("1");
  const [period, setPeriod] = useState("dayly");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(getCurrentDateFormatted());

  useEffect(() => {
    setIsLoading(true);
    mainService
      .getOrdersSaleByDay(startDate, getCurrentDateFormatted(), token)
      .then((res) => {
        setFilteredOrders(res.data.response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  }, [startDate]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <div className="app-inner-layout__header-boxed p-0">
        <div
          className="text-white  mb-4 d-flex justify-content-between align-content-center"
          style={{ backgroundColor: "grey", borderRadius: "0.3em" }}
        >
          <FontAwesomeIcon icon="" />
          <h1
            className="fs-3"
            style={{ alignSelf: "center", marginLeft: "15px" }}
          >
            {intl.formatMessage({ id: "orderManagementStatistics" })}
          </h1>
          <TotalOrdersWidget
            loading={isLoading}
            deliveryOrdersCount={filteredOrders.length}
          />
        </div>
      </div>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            classNames="TabsAnimation"
            appear={true}
            timeout={1500}
            enter={false}
            exit={false}
          >
            <div>
              <Nav
                tabs
                className="periodSelect"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h2 style={{ marginBottom: "0px", color: "white" }}>
                  {intl.formatMessage({
                    id: "salesReport",
                  })}
                </h2>
                <div style={{ display: "flex", gap: "2px" }}>
                  <NavItem>
                    <button
                      className={classnames(
                        { active: activeTab === "1" },
                        period === "dayly" ? "isActive" : "tabBtn"
                      )}
                      onClick={() => {
                        toggle("1");
                        setPeriod("dayly");
                        setStartDate(getCurrentDateFormatted());
                      }}
                    >
                      {intl.formatMessage({
                        id: "day",
                      })}
                    </button>
                  </NavItem>
                  <NavItem>
                    <button
                      className={classnames(
                        { active: activeTab === "2" },
                        period === "weekly" ? "isActive" : "tabBtn"
                      )}
                      onClick={() => {
                        toggle("2");
                        setPeriod("weekly");
                        setStartDate(
                          subtractOneWeek(getCurrentDateFormatted())
                        );
                      }}
                    >
                      {intl.formatMessage({
                        id: "week",
                      })}
                    </button>
                  </NavItem>
                  <NavItem>
                    <button
                      className={classnames(
                        { active: activeTab === "3" },
                        period === "monthly" ? "isActive" : "tabBtn"
                      )}
                      onClick={() => {
                        toggle("3");
                        setPeriod("monthly");
                        setStartDate(
                          subtractOneMonth(getCurrentDateFormatted())
                        );
                      }}
                    >
                      {intl.formatMessage({
                        id: "month",
                      })}
                    </button>
                  </NavItem>
                  <NavItem>
                    <button
                      className={classnames(
                        { active: activeTab === "4" },
                        period === "yearly" ? "isActive" : "tabBtn"
                      )}
                      onClick={() => {
                        toggle("4");
                        setPeriod("yearly");
                        setStartDate(
                          subtractOneYear(getCurrentDateFormatted())
                        );
                      }}
                    >
                      {intl.formatMessage({
                        id: "year",
                      })}
                    </button>
                  </NavItem>
                </div>
              </Nav>
              <DashboardByDate
                filteredOrders={filteredOrders}
                isLoading={isLoading}
              />
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </>
  );
}
