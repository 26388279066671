import http from "./http-commons";
import { authHeader } from "./authHeader";
import { API_URL } from "./config";

class MainService {
  getModules() {
    return http.get(API_URL + "modules/", { headers: authHeader() });
  }

  createRestaurant = (data) => {
    return http.post(API_URL + "restaurant/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token 605a76487d776c8ab2cf53d3ccc485cd61180f02",
      },
    });
    // return http.post(API_URL + "restaurant/", data, { headers: authHeader() });
  };

  createSouseRestaurant = (data, token) => {
    return http.post(API_URL + "sous-restaurant/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    });
    // return http.post(API_URL + "restaurant/", data, { headers: authHeader() });
  };

  getSouseRestaurant = (token) => {
    return http.get(API_URL + "sous-restaurant/", {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    });
    // return http.post(API_URL + "restaurant/", data, { headers: authHeader() });
  };
  removeSouseRestaurant = (restau_id, token) => {
    return http.delete(`sous-restaurant/${restau_id}/`, {
      headers: { Authorization: `Token ${token}` },
    });
  };
  updateSousRestaurant = (data, token, id) => {
    return http.put(API_URL + `sous-restaurant/${id}/`, data, {
      headers: authHeader("multipart/form-data"),
    });
    // return http.post(API_URL + "restaurant/", data, { headers: authHeader() });
  };

  updateRestaurant = (data, token, id) => {
    return http.put(API_URL + `restaurant/${id}/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    });
    // return http.post(API_URL + "restaurant/", data, { headers: authHeader() });
  };

  getOrdersForPreviousDay(token) {
    return http.get(API_URL + `orders/previous-day/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  getOrdersForToday(token) {
    return http.get(API_URL + `orders/today/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  getOrdersForWeek(token) {
    return http.get(API_URL + `order/week/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  getOrdersDetail(token,id) {
    return http.get(API_URL + `order/${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  getOrdersForMonth(token) {
    return http.get(API_URL + `order/month/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  getAll(token, name, restaurantName) {
    return http.get(API_URL + `search/`, {
      params: {
        name: name,
        restaurant: restaurantName,
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  getOrdersForYear(token) {
    return http.get(API_URL + `order/year/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  getCategories() {
    return http.get(API_URL + `admin/restaurant/category`, {
      headers: {
        Authorization: "Token 605a76487d776c8ab2cf53d3ccc485cd61180f02",
      },
    });
  }
  getOrdersCompleted(token) {
    return http.get(API_URL + `orders/completed`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  getOrdersDelivery(token) {
    return http.get(API_URL + `orders/approved`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  getOrdersCancelled(token) {
    return http.get(API_URL + `orders/cancelled`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  getOrdersDeliveryByDay(start_date, end_date, token) {
    return http.get(API_URL + `orders/approved`, {
      params: {
        start_date,
        end_date,
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  getOrdersSaleByDay(start_date, end_date, token) {
    return http.get(API_URL + `orders/sale`, {
      params: {
        start_date,
        end_date,
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  getOrdersCancelledByDay(start_date, end_date, token) {
    return http.get(API_URL + `orders/cancelled`, {
      params: {
        start_date,
        end_date,
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  getOrders(token) {
    return http.get(API_URL + `orders/today`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  getOrderDetails(status, start_date, end_date, token) {
    return http.get(API_URL + `order/detail/`, {
      params: {
        status,
        start_date,
        end_date,
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  acceptRejectOrder = (data, token, orderId) => {
    return http.post(API_URL + "orders/" + orderId + "/acceptreject/", data, {
      headers: { Authorization: `Token ${token}` },
    });
  };

  getRestaurantProfile(token) {
    return http.get(API_URL + "restaurant/profile/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
  }

  activateAccount = (data) => {
    return http.post(API_URL + "reset-account", data, {
      headers: { "Content-Type": "application/json" },
    });
  };

  createClient = (data) => {
    return http.post(API_URL + "client/", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  getModule = (id) => {
    return http.get(`drink/${id}/`, { headers: authHeader() });
  };

  getNotificationMessages(token) {
    return http.get(API_URL + `notification`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  getTransaction(token, page=1, limit=10) {
    const params = {
      page: page,
      limit: limit
    }
    return http.get(API_URL + `pay/transaction/`, {
      params,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  cashout(token,data) {
    return http.post(API_URL + `pay/transaction/`, data, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json"
      },
    });
  }
  putNotificationMessages(notificationId, token) {
    return http.put(API_URL + `notification/${notificationId}/`, undefined, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
}

export default new MainService();
