import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Base from "../Base.jsx";
import { useIntl } from "react-intl";
import OrderModal from "./OrderModal.jsx";
import SubmitButtons from "./SubmitButtons.jsx";
import { useFieldArray, useForm } from "react-hook-form";
import { timeOptions } from "../../utils/timeOptions.js";
import OrderDetailsForm from "./OrderDetailsForm.jsx";
import QRCode from "react-qr-code";
import { Col, Row } from "reactstrap";

export default function OrderDetail() {
  const intl = useIntl();

  const [modalState, setModalState] = useState(null);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [updatedOrderList, setUpdatedOrderList] = useState(null);
  const location = useLocation();
  const orderDetails = location.state;

  const { register, control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      food_items: orderDetails.food_items.map((orderDetail) => ({
        quantity: Number(orderDetail.total_quantity),
        editCheckbox: 1,
        food: orderDetail.food_id,
      })),
      drink_items: orderDetails.drink_items.map((orderDetail) => ({
        quantity: Number(orderDetail.total_quantity),
        editCheckbox: 1,
        drink: orderDetail.drink_id,
      })),
      menu_items: orderDetails.menu_items.map((orderDetail) => ({
        quantity: Number(orderDetail.total_quantity),
        editCheckbox: 1,
        menu: orderDetail.menu_id,
      })),
    },
  });
  const {} = useFieldArray({
    control,
    name: "food_items",
  });

  const toggle = () => {
    setModalState(null);
  };

  return (
    <Base>
      <div className="app-inner-layout">
        <OrderModal
          modalState={modalState}
          updatedOrderList={updatedOrderList}
          toggle={toggle}
          orderId={orderDetails.order_id}
          formIsDirty={formIsDirty}
        />
        <form
          onSubmit={handleSubmit((formData) => {
            if (formIsDirty) {
              formData.food_items.forEach((element) => {
                delete element.editCheckbox;
              });
              formData.drink_items.forEach((element) => {
                delete element.editCheckbox;
              });
              formData.menu_items.forEach((element) => {
                delete element.editCheckbox;
              });
              setUpdatedOrderList(formData);
            }
          })}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1>
              {intl.formatMessage({ id: "order" })}{" "}
              <b>#{orderDetails.order_id} </b>
            </h1>
            <h5>
              {new Date(orderDetails.ordered_at).toLocaleDateString(
                "en-US",
                timeOptions
              )}
            </h5>
          </div>
          <OrderDetailsForm
            orderItems={orderDetails}
            register={register}
            watch={watch}
            setValue={setValue}
            setFormIsDirty={setFormIsDirty}
          />
          {orderDetails.status !== 3 ? (
            <SubmitButtons
              setModalState={setModalState}
              orderStatus={orderDetails.status}
            />
          ) : (
            <Row>
              <Col md="12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <QRCode
                    size={256}
                    style={{ height: "200px", maxWidth: "100%", width: "100%" }}
                    value={orderDetails.key}
                    viewBox={`0 0 256 256`}
                  />
                  <p><em>{orderDetails.key}</em></p>
                </div>
              </Col>
            </Row>
          )}
        </form>
      </div>
    </Base>
  );
}
