import React, { useState, useEffect, useRef } from "react";
import mainService from "../../../services/mainService";
import cx from "classnames";
import { Spinner } from "reactstrap";
import { useIntl } from "react-intl";

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  return (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

const SearchBox = () => {
  const intl = useIntl();

  const [activeSearch, setActiveSearch] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const token = currentUser?.token;
  const restaurantId = currentUser?.restaurant;
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [realResult, setRealResult] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const debouncedApiCall = useDebounce((value) => triggerSearch(value), 1000);

  useEffect(() => {
    setRealResult([]);
    if (searchResult.length > 2) {
      console.log("do i even reach here?");
      setRealResult([
        ...searchResult[0],
        ...searchResult[1],
        ...searchResult[2],
      ]);
    }
  }, [searchResult]);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    switch (name) {
      case "search": {
        setSearch(value);
        debouncedApiCall(value);
      }
    }
  };

  const triggerSearch = (value) => {
    if (value !== 0) {
      setIsShown(true);
      setLoadingSearch(true);
      mainService
        .getAll(token, value, restaurantId)
        .then((res) => {
          delete res.data.restaurant_meals;
          setSearchResult(Object.values(res.data));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingSearch(false);
        });
    }
  };

  const getTypeOfSearchItem = (item) => {
    if ("foodType" in item) return ["Food", "foodPicture"];
    if ("drinkType" in item) return ["Drink", "drinkPicture"];
    if ("menuType" in item) return ["Menu", "menuPicture"];
  };

  const getSearchInfo = (item) => {
    return (
      <div
        style={{
          width: "100%",
          height: "60px",
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          alignItems: "center",
          gap: "2px",
        }}
      >
        <span>{getTypeOfSearchItem(item)[0]}</span>
        <span>{item.name}</span>
        <span>{item.price} Fcfa</span>
        <img
          src={item[getTypeOfSearchItem(item)[1]]}
          style={{ maxWidth: "100%", maxHeight: "60px", justifySelf: "center" }}
        ></img>
      </div>
    );
  };

  return (
    <div
      className={cx("search-wrapper", {
        active: activeSearch,
      })}
    >
      <form className="input-holder">
        <input
          type="text"
          className="search-input"
          value={search}
          name="search"
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: "search" })}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            if (!activeSearch) {
              setActiveSearch(true);
            }
          }}
          className="search-icon"
        >
          <span />
        </button>
      </form>
      <button
        onClick={() => {
          setSearch([]);
          setSearchResult([]);
          setActiveSearch(false);
          setIsShown(false);
        }}
        className="btn-close"
      />
      {isShown && (
        <div
          style={{
            borderRadius: "10px",
            backgroundColor: "white",
            width: "380px",
            position: "absolute",
            padding: "20px",
          }}
        >
          {loadingSearch ? (
            <Spinner
              speed="0.85s"
              thickness="4px"
              emptyColor="gray.200"
              color="#0ab44e"
              size="xl"
              style={{ float: "center" }}
            />
          ) : realResult.length ? (
            realResult.map((searchItem) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  {getSearchInfo(searchItem)}
                </div>
              );
            })
          ) : (
            <span>No coincidences found in your search</span>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              color: "black",
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
