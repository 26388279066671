const getCookingTime = (order) => {
  return order.food_items.reduce((accumulator, currentValue) => {
    return (
      accumulator + (!currentValue.food_data.cooking_time || "15") * 60 * 1000
    );
  }, 0);
};

export const sortByTimeToStartCooking = (ordersData) => {
  const ordersEnhanced = ordersData.map((order) => {
    const deliveryTimestamp = `${order.delivery_date}T${order.delivery_time}`;
    const timeToStartCooking =
      new Date(deliveryTimestamp).getTime() - getCookingTime(order);
    return { ...order, timeToStartCooking };
  });

  return ordersEnhanced.sort(
    (a, b) => a.timeToStartCooking - b.timeToStartCooking
  );
};
