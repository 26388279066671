export const API_URL = "https://wazimer.com/api/";
export const WS_URL = "wss://wazimer.com/ws/";
export const PSOUSREST = "WaziResto";

// export const API_URL = "http://localhost:8000/api/";
export function removeQueryParameters(imageUrl) {
  // Find the position of the "?" character
  const queryStringIndex = imageUrl.indexOf("?");

  if (queryStringIndex !== -1) {
    // If "?" exists, remove everything after it
    return imageUrl.slice(0, queryStringIndex);
  }

  // If there are no query parameters, return the original URL
  return imageUrl;
}
