import { useForm } from "react-hook-form";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import swal from "sweetalert";
import { useIntl } from "react-intl";
import { useContext, useState } from "react";
import mainService from "../../services/mainService";
import LoadingOverlay from "react-loading-overlay-ts";
import { Loader } from "react-loaders";

export default function WithdrawalForm({ walletAmount, toggle, open }) {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const user = localStorage.getItem("user");
  const mainUser = JSON.parse(user);
  const token = mainUser.token;

  const { register, handleSubmit, watch, setValue } = useForm();

  const amountWatch = watch().amount_to_withdraw;
  const phoneWatch = watch().phone_to_withdraw ? watch().phone_to_withdraw : "";
  const widrawalOptionWatch = watch().widrawalOption;
  const onSubmit = (data) => {
    swal({
      text: "vérifier les informations avant de confirmer cas les informations ne peut plus être modifiée ! cliquer sur annuler pour modifier et accepter pour continuer ",
      title: "Confirmation",
      icon: "info",
      buttons: ["Annuler", "accepter"],
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm) {
        setLoading(true)
        const formData = new FormData()
        formData.append("amount", Number(watch().amount_to_withdraw))
        formData.append("type_transaction", "cashout")
        formData.append("method", watch().widrawalOption)
        formData.append("number", watch().phone_to_withdraw)
        formData.append("rib", null)
        mainService.cashout(token, formData)
            .then((response) => {
              setLoading(false)
              swal("la transaction est initialisée avec succès!", {
                icon: "success",
              });
            })
            .catch((error) => {
              setLoading(false)
              if (error.response && error.response.data && error.response.data.message) {
                swal("Erreur", error.response.data.message, "error");
              } else if (error) {
                swal("Erreur", error.message, "error");
              }else{
                swal("Erreur", "an error has occurred ", "error");
              }
              console.log(error)
            })
      }
    })
  }

  return (
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1">
          {intl.formatMessage({ id: "withdrawFunds" })}
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <LoadingOverlay
            tag="div"
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "#fff",
                opacity: 0.5,
              }),
            }}
            spinner={<Loader active={loading} type="ball-pulse-rise" />}
          >
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Card
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <InputGroup>
                          <Input
                            placeholder={intl.formatMessage({
                              id: "amountToWithdraw",
                            })}
                            type="number"
                            min={0}
                            max={walletAmount}
                            onChange={(e) => {
                              setValue(
                                `amount_to_withdraw`,
                                Number(e.target.value)
                              );
                            }}
                          ></Input>
                          <InputGroupText>Fcfa</InputGroupText>
                        </InputGroup>
                        <InputGroup style={{ marginTop: "20px" }}>
                          <Input
                            placeholder={intl.formatMessage({
                              id: "phoneToWithdraw",
                            })}
                            type="text"
                            min={9}
                            max={9}
                            onChange={(e) => {
                              setValue(
                                `phone_to_withdraw`,
                                e.target.value
                              );
                            }}
                          ></Input>
                          <InputGroupText>Phone</InputGroupText>
                        </InputGroup>
                        <div style={{ margin: "10px" }}>
                          <div style={{ marginTop: "10px" }}>
                            <label>
                              <input
                                style={{ margin: "10px" }}
                                type="radio"
                                value="M2U"
                                {...register("widrawalOption")}
                              />
                              M2U
                            </label>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <label>
                              <input
                                style={{ margin: "10px" }}
                                type="radio"
                                value="orange-money"
                                {...register("widrawalOption")}
                              />
                              Orange Money
                            </label>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <label>
                              <input
                                style={{ margin: "10px" }}
                                s
                                type="radio"
                                value="mobile-money"
                                {...register("widrawalOption")}
                              />
                              Mobile Money
                            </label>
                          </div>
                        </div>
                        <Button
                          className=" btn-icon-only pt-0 wazi-btn-add "
                          style={{
                            alignSelf: "center",
                            backgroundColor: "#0ab44e",
                            border: "1px solid #0ab44e",
                            width: "auto",
                            whiteSpace: "nowrap",
                            fontSize: "20px",
                            lineHeight: "45px",
                            margin: "0 15px",
                          }}
                          // onClick={() => onSubmit()}
                          disabled={
                            !amountWatch ||
                            amountWatch < 1000 ||
                            amountWatch > walletAmount ||
                            !(phoneWatch.length == 9 )||
                            !widrawalOptionWatch
                          }
                          // disabled={true}
                        >
                          {intl.formatMessage({ id: "requestWithdrawal" })}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </LoadingOverlay>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
}
