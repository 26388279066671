import React, { useState, useContext } from "react";
import { Button } from "reactstrap";
import { WalletContext } from "../../../context/walletContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import "./WalletWidget.scss";
import { isMobile } from 'react-device-detect';

export default function WalletWidget({}) {
  const intl = useIntl();

  const { walletAmount } = useContext(WalletContext);
  const navigate = useNavigate();

  return (
    <Button
      className="wallet-widget-button"
      onClick={() => navigate("/wallet")}
    >
      <div className="wallet-widget-icon">
        <FontAwesomeIcon icon={faCoins} style={{ marginRight: "10px" }} />
        {
          !isMobile ? (
            <span style={{ fontSize: "15px" }}>
              {intl.formatMessage({ id: "wallet" })}: {walletAmount} Fcfa
            </span>
          )
          : ("")
        }
      </div>
    </Button>
  );
}
