import React, { createContext, useEffect, useState } from "react";
import mainService from "../services/mainService";

const NotificationsContext = createContext(0);

const NotificationsProvider = ({ children }) => {
  const [notificationMessages, setNotificationMessages] = useState();

  const user = localStorage.getItem("user");
  const mainUser = JSON.parse(user);
  const token = mainUser ? mainUser.token :null;

  const getNotificationMessages = async () => {
    try {
      const response = await mainService.getNotificationMessages(token);
      setNotificationMessages(response.data);
    } catch (err) {
      setNotificationMessages([]);
      console.log(err);
    }
  };

  useEffect(() => {
    getNotificationMessages();
  }, []);

  return (
    <NotificationsContext.Provider
      value={{ notificationMessages, getNotificationMessages }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
