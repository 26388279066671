import { createSlice } from "@reduxjs/toolkit";
import AuthService from "../services/AuthService";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user } //verifyUser()
  : { isLoggedIn: false, user: null };

 function verifyUser() {
  return AuthService.isTokenValid(user.token).then((tokenIsValid) => {
     if(tokenIsValid){
       return { isLoggedIn: true, user };
     } else {
       return { isLoggedIn: false, user: null };
     }
   }).catch((e) => {
     console.log("ERROR VALIDATING TOKEN: "+ e);
    return { isLoggedIn: false, user: null };
  });
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    get: (state) => {
      return state.data
    },
    loggedIn: (draft, action) => {
      draft.isLoggedIn = true
      draft.user = action.payload
      return
    },
    temporalPass:(state, action) =>{
      state.user = action.payload
      state.temporary_password = false
    }
  }
})

export function isUser() {
}

export function login(username, password) {
  return AuthService.login(username, password).then((response) => {
    if(response.role === 'Restaurant' || response.role ==='underrestaurant') {
        actions.loggedIn(response)
    } else {
        throw "Restaurant Not Found";
    }

  })
}

export function logout(username, password) {
  return AuthService.logout()
}

export function reset(data) {
  return AuthService.reset(data)
}

export function resetPassword(token, password, confirm) {
  return AuthService.resetPassword(token, password, confirm)
}

export function createPassword( token, password, confirm, security1, security2, security3, securityQ1, securityQ2,  securityQ3) {
  return AuthService.createPassword( token, password, confirm, security1, security2, security3, securityQ1, securityQ2,  securityQ3)
}

const { actions, reducer } = authSlice

export default reducer
