import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Row } from "reactstrap";
import { useIntl } from "react-intl";

export default function SubmitButtons({ setModalState, orderStatus }) {
  const intl = useIntl();

  return (
    <Row
      style={{
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "15px",
      }}
    >
      <Button
        className=" btn-icon-only pt-0 wazi-btn-add "
        style={{
          backgroundColor: "#0ab44e",
          border: "1px solid #0ab44e",
          width: "auto",
          whiteSpace: "nowrap",
          fontSize: "20px",
          lineHeight: "45px",
          margin: "0 15px",
        }}
        type="submit"
        onClick={() => {
          setModalState("accept");
        }}
      >
        {intl.formatMessage({ id: "accept" })}
        <FontAwesomeIcon
          icon={faCheck}
          size="xs"
          style={{
            paddingLeft: "5px",
          }}
        />
      </Button>
      {orderStatus !== 6 ? (
        <Button
          className=" btn-icon-only pt-0 wazi-btn-add "
          style={{
            backgroundColor: "red",
            border: "1px solid red",
            width: "auto",
            whiteSpace: "nowrap",
            fontSize: "20px",
            lineHeight: "45px",
            margin: "0 15px",
          }}
          onClick={() => {
            setModalState("reject");
          }}
        >
          {intl.formatMessage({ id: "refuse" })}
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            style={{
              paddingLeft: "5px",
            }}
          />
        </Button>
      ) : (
        <></>
      )}
    </Row>
  );
}
