import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
import doc from "../../assets/doc.webp";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../../utils/selectors";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faAngleDoubleDown,
  faGlobeAfrica,
  faMapPin,
  faPhoneAlt,
  faStoreAlt,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from "react-select/creatable";
import Dropzone, { useDropzone } from "react-dropzone";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";

import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import mainService from "../../services/mainService";
import swal from "sweetalert";
import Base from "../Base.jsx";
import ModalBodyComponent from "./Map";
import { useIntl } from "react-intl";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export default function Profile() {
  const intl = useIntl();

  const currentUser = JSON.parse(localStorage.getItem("user"));
  console.log(currentUser, "current User");

  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();
  // we will display the date as DD-MM-YYYY
  let currentDate = `${currentDay}-${currentMonth}-${currentYear}`;

  const token = currentUser.token;
  const id = currentUser.restaurant_id;
  const [profileInfo, setProfileInfo] = useState(currentUser);
  const [confirm, setConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rname, setRname] = useState("");
  const [vrname, setVRname] = useState(false);
  const [name, setName] = useState("");
  const [sname, setSname] = useState("");
  const [vname, setVname] = useState(false);
  const [open, setOpen] = useState("08:00:00");
  const [close, setClose] = useState("20:00:00");
  const [long, setLong] = useState(12.354722);
  const [lat, setLat] = useState(7.369721);
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [profile, setProfile] = useState([]);
  const [picture, setPicture] = useState([]);
  const [cni, setCNI] = useState([]);
  const [rccm, setRCCM] = useState([]);
  const [immatriculation, setImmatriculation] = useState("");
  const [image, setImage] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState(false);
  const [webError, setWebError] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [restaurant, setRetaurant] = useState([]);
  const [merchantCode, setmerchantCode] = useState("");
  const [merchantName, setmerchantName] = useState("");
  const [dateDeDemarrage, setdateDeDemarrage] = useState(
    currentDate.slice(0, 10)
  );
  const [accountNumber, setAccountNumber] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [bankAgencyCode, setBankAgencyCode] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [iban, setIban] = useState("");
  const [ribCode, setRibCode] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [collapse, setCollapse] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleSaveCoordinates = (address, latitude, longitude) => {
    setAddress(address);
    setLat(latitude);
    setLong(longitude);
  };

  const getPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLat(latitude);
          setLong(longitude);
          await getAddressFromCoordinates(latitude, longitude);
          // showPosition(position)
        },
        (error) => {
          console.error(error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getAddressFromCoordinates = async (latitude, longitude) => {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${APIKEY}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        setAddress(data.results[0].formatted_address);
      } else {
        setAddress("Address not found.");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      setProfile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  function validate(str) {
    return str.length > 3;
  }

  function canSubmit() {
    if (profileInfo !== undefined) {
      if (cni.length > 0 || profileInfo.cni !== "") {
        return true;
      } else if (profileInfo.first_name !== name && name !== "") {
        return true;
      } else if (profileInfo.last_name !== sname && sname !== "") {
        return true;
      } else if (profileInfo.restaurant !== rname && rname !== "") {
        return true;
      } else if (profileInfo.phone !== phone && phone !== "") {
        return true;
      } else if (profileInfo.restaurantCategory !== category) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function SubmitButton() {
    if (!canSubmit()) {
      return (
        <Button
          type="button"
          // onClick={handleSubmit}
          size="md"
          className="fw-bold btn btn-login mt-2"
          block
          disabled
        >
          {intl.formatMessage({
            id: "update",
          })}
        </Button>
      );
    } else {
      return (
        <Button
          type="button"
          onClick={handleSubmit}
          size="md"
          className="fw-bold btn btn-login mt-2"
          block
        >
          {intl.formatMessage({
            id: "update",
          })}
        </Button>
      );
    }
  }

  useEffect(() => {
    return () => profile.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [profile]);

  const isConnected = useSelector(isLoggedIn);

  useEffect(() => {
    setProfileInfo(currentUser);
    setName(profileInfo.first_name);
    setSname(profileInfo.last_name);
    setRname(profileInfo.restaurant);
    setPhone(profileInfo.phone);
    setClose(profileInfo.closing_hour);
    setOpen(profileInfo.opening_hour);
    setImmatriculation(profileInfo.immatriculation);
    setWeb(profileInfo.internet_site);
    setAccountNumber(profileInfo.account_number);
    setdateDeDemarrage(profileInfo.created_at.slice(0, 10));
    setBankAddress(profileInfo.bank_address);
    setBankAgencyCode(profileInfo.bank_agency_code);
    setBankCode(profileInfo.bank_code);
    setBankName(profileInfo.bank_name);
    setIban(profileInfo.iban);
    setCategory(profileInfo.restaurantCategory);
    setRibCode(profileInfo.rib_code);
    setSwiftCode(profileInfo.swift_code);
    setAddress(profileInfo.address);
    setLat(profileInfo.location[0]);
    setLong(profileInfo.location[1]);
    setmerchantName(profileInfo.merchant_name);
    setmerchantCode(profileInfo.merchnat_code);
    if (profileInfo.email !== null) {
      setEmail(profileInfo.email);
    }
  }, []);

  const getValidUrl = (url = "") => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `http://${newUrl}`;
    }

    return newUrl;
  };

  useEffect(() => {
    mainService
      .getCategories(token)
      .then((response) => {
        setCategoryList(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    mainService
      .getRestaurantProfile(token)
      .then((res) => {
        setRetaurant(res.data.picture_restaurant);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //     if (autocomplete) {
  //         autocomplete.addListener("place_changed", () => {
  //             const place = autocomplete.getPlace();
  //             if (place && place.geometry) {
  //                 setLat(place.geometry.location.lat());
  //                 setLong(place.geometry.location.lng());
  //                 setAddress(place.formatted_address);
  //             }
  //         });
  //     }
  // }, [autocomplete]);

  const handleSubmit = (e) => {
    // const arr = [name, pic, prof]
    // const newOne = arr.filter((item) => item !== "" || item !== null || item !== [] || item !== {})

    e.preventDefault();

    if (rname === "") {
      setVRname(true);
      setTabIndex(0);
    } else if (phone === "" || !isValidPhoneNumber(phone)) {
      setPhoneError(true);
      setTabIndex(2);
    } else if (name === "") {
      setVname(true);
      setTabIndex(2);
    } else if (immatriculation === "") {
      setimmError(true);
    } else if (category === "") {
      setCategoryError(true);
    } else if (web === "") {
      setWebError(true);
    } else if (address === "") {
      setAddressError(true);
    } else {
      setPhoneError(false);
      setimmError(false);
      setWebError(false);
      setCategoryError(false);
      setVRname(false);
      setVname(false);
      setLoading(true);
      const formData = new FormData();
      if (rname != "" && rname !== profileInfo.restaurant) {
        formData.append("name", rname);
      }
      if (open !== "" && open !== profileInfo.opening_hour) {
        formData.append("opening_hour", open);
      }
      if (close !== "" && close !== profileInfo.closing_hour) {
        formData.append("closing_hour", close);
      }
      if (lat !== profileInfo.location[0]) {
        formData.append("location", lat);
      }
      if (long !== profileInfo.location[1]) {
        formData.append("location", long);
      }
      ///THIS IS RESTAURNT USER PROFILE
      if (profile.length > 0) {
        formData.append("user_profile_picture", profile[0]);
      }
      if (
        immatriculation !== "" &&
        immatriculation !== profileInfo.immatriculation
      ) {
        formData.append("immatriculation", immatriculation);
      }
      if (rccm.length > 0) {
        formData.append("rccm_document", rccm[0]);
      }
      if (cni.length > 0 && cni !== profileInfo.cni) {
        formData.append("cni", cni[0]);
      }
      for (let i = 0; i < picture.length; i++) {
        formData.append("picture_restaurant[" + i + "]image", picture[i]);
      }

      formData.append("restaurant_channel", channel);
      for (let i = 0; i < module.length; i++) {
        formData.append("module", module[i]);
      }
      for (let i = 0; i < fType.length; i++) {
        formData.append("foodType", fType[i]);
      }
      for (let i = 0; i < fCategory.length; i++) {
        formData.append("foodCategory", fCategory[i]);
      }
      for (let i = 0; i < dType.length; i++) {
        formData.append("drinkType", dType[i]);
      }
      // for (let i = 0; i < dCategory.length; i++) {
      //   formData.append("drinkCategory", dCategory[i]);
      // }
      if (email !== "") {
        formData.append("email", email);
      }
      if (email1 !== "") {
        formData.append("email1", email1);
      }
      if (phone !== "" && phone !== profileInfo.phone) {
        formData.append("phone", phone);
      }

      if (sname !== "" && sname !== profileInfo.last_name) {
        formData.append("last_name", sname);
      }
      if (name !== "" && name !== profileInfo.first_name) {
        formData.append("first_name", name);
      }
      ///THIS IS Resto PROFILE Image
      if (image.length > 0) {
        formData.append("profile_picture", image[0]);
      }
      if (merchantCode !== "" && merchantCode !== profileInfo.merchant_code) {
        formData.append("merchant_code", merchantCode);
      }
      if (merchantName !== "" && merchantName !== profileInfo.merchant_name) {
        formData.append("merchant_name", merchantName);
      }
      if (
        dateDeDemarrage !== "" &&
        dateDeDemarrage !== profileInfo.created_at
      ) {
        formData.append("created_at", dateDeDemarrage);
      }
      if (
        accountNumber !== "" &&
        accountNumber !== profileInfo.account_number
      ) {
        formData.append("account_number", accountNumber);
      }
      if (bankAddress !== "" && bankAddress !== profileInfo.bank_address) {
        formData.append("bank_address", bankAddress);
      }
      if (
        bankAgencyCode !== "" &&
        bankAgencyCode !== profileInfo.bank_agency_code
      ) {
        formData.append("bank_agency_code", bankAgencyCode);
      }
      if (bankCode !== "" && bankCode !== profileInfo.bank_code) {
        formData.append("bank_code", bankCode);
      }
      if (bankName !== "" && bankName !== profileInfo.bank_name) {
        formData.append("bank_name", bankName);
      }
      if (iban !== "" && iban !== profileInfo.iban) {
        formData.append("iban", iban);
      }
      if (ribCode !== "" && ribCode !== profileInfo.rib_code) {
        formData.append("rib_code", ribCode);
      }
      if (swiftCode !== "" && swiftCode !== profileInfo.swift_code) {
        formData.append("swift_code", swiftCode);
      }
      if (address !== "" && address !== profileInfo.address) {
        formData.append("address", address);
      }
      if (web !== "" && web !== profileInfo.internet_site) {
        formData.append("internet_site", getValidUrl(web));
      }

      for (let i = 0; i < value.length; i++) {
        formData.append(
          "social_network_link[" + i + "]",
          getValidUrl(value[i].value)
        );
      }
      // formData.append("restaurantType", "1");
      if (category !== "" && category !== profileInfo.restaurantCategory) {
        formData.append("restaurantCategory", category.id);
      }

      mainService
        .updateRestaurant(formData, token, id)
        .then((response) => {
          setLoading(false);
          console.log("SUMMIT RESPONSE", response);

          const resData = response.data;
          let newUser = {
            ...currentUser,
            ...resData,
            restaurant: resData.name,
            first_name: resData.user_first_name,
            last_name: resData.user_last_name,
            restaurantCategory: resData.restaurantCategory.id,
            picture: resData.restaurant_profile_picture,
            profile_picture: resData.user_profile_picture,
          };
          delete newUser.user_first_name;
          delete newUser.user_last_name;
          delete newUser.user_profile_picture;
          delete newUser.restaurant_profile_picture;
          localStorage.setItem("user", JSON.stringify(newUser));

          // const message = response.data.message
          swal("Restaurant Mis à jour  avec succès", "success").then(() => {
            window.location.href = "/";
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log("SUMMIT ERROR", err);
          let error = err.response.data.message;
          console.log(error);
          if (error && error.includes("email")) {
            swal(
              "Erreur",
              "L'email de l'administrateur est requis pour procéder",
              "error"
            );
            setTabIndex(2);
          } else if (error && error.includes("phone")) {
            swal(
              "Erreur",
              "Le numero de téléphone est déjà utiliser par un autre compte",
              "error"
            );
            setTabIndex(2);
          } else if (error && error.includes("rccm_document")) {
            swal(
              "Erreur",
              "Le document RCCM est requis pour procéder",
              "error"
            );
            setTabIndex(1);
          } else if (error && error.includes("profile_picture")) {
            swal(
              "Erreur",
              "La photo de profil du restaurant est requis pour procéder",
              "error"
            );
            setTabIndex(0);
          } else if (error && error.includes("picture")) {
            swal(
              "Erreur",
              "La photo de profil de l'administrateur est requis pour procéder",
              "error"
            );
            setTabIndex(2);
          } else if (error && error.includes("internet_site")) {
            swal(
              "Erreur",
              "L'adresse du site web est requis pour procéder",
              "error"
            );
            setTabIndex(3);
          } else if (error && error.includes("picture_restaurant")) {
            swal(
              "Erreur",
              "Une photo du restaurant est requis pour procéder",
              "error"
            );
            setTabIndex(1);
          } else if (error && error.includes("social_network_link")) {
            swal(
              "Erreur",
              "Un lien de réseau social est requis pour procéder",
              "error"
            );
            setTabIndex(3);
          } else if (
            err &&
            err.response.status &&
            err.response.status === 409
          ) {
            swal("Erreur", err.response.data.message, "error");
          } else {
            swal(
              "Erreur",
              "Une erreur s'est produite lors de l'execution de votre requete",
              "error"
            );
          }
        });
    }
  };

  function findObjectById(list, id) {
    // Find the object in the list with the given id.
    const objectFound = list.find((object) => `${object.id}` === id);

    // Return the object, or undefined if it is not found.
    return objectFound;
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case "password":
        setPassword(value);
        break;
      case "confirm":
        setConfirm(value);
        break;
      case "name":
        setName(value);
        break;
      case "immatriculation":
        setImmatriculation(value);
        setimmError(!validate(value));
        break;
      case "sname":
        setSname(value);
        break;
      case "rname":
        setRname(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "email1":
        setEmail1(value);
        break;
      case "opening":
        setOpen(value);
        break;
      case "closing":
        setClose(value);
        break;
      case "website":
        setWeb(value);
        setWebError(!validate(value));
        break;
      case "website1":
        setWeb1(value);
        break;
      case "category":
        const selectedCategory = findObjectById(categoryList, value);
        setCategory(selectedCategory);
        // setCategoryError(!validate(value));
        break;
      case "accountNumber":
        setAccountNumber(value);
        break;
      case "bankAddress":
        setBankAddress(value);
        break;
      case "bankAgencyCode":
        setBankAgencyCode(value);
        break;
      case "bankCode":
        setBankCode(value);
        break;
      case "bankName":
        setBankName(value);
        break;
      case "iban":
        setIban(value);
        break;
      case "ribCode":
        setRibCode(value);
        break;
      case "swiftCode":
        setSwiftCode(value);
        break;
      case "address":
        setAddress(value);
        break;
      default:
        break;
    }
  };

  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone, setPhone] = useState("");
  const [pError, setPhoneError] = useState(false);
  const [immError, setimmError] = useState(false);
  const [fCategory, setFcategory] = useState([1]);
  const [fType, setFtype] = useState([1]);
  const [dCategory, setDcategory] = useState([1]);
  const [dType, setDtype] = useState([1]);
  const [module, setModule] = useState([1]);
  const [channel, setChannel] = useState(1);
  const [web, setWeb] = useState("");
  const [web1, setWeb1] = useState("");
  const [social, setSocial] = useState("");
  const [value, setValue] = React.useState([]);

  const handleKeyDown = (event) => {
    if (!social) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, createOption(social)]);
        setSocial("");

        event.preventDefault();
    }
  };

  function reorderListByObject(list, objectName) {
    // Find the object in the list with the given name.
    console.log("CURRENT CATEGORY: ", objectName);
    if (objectName === undefined) return list;

    // If objectName is an object, get the name field.
    const objectNameToFind =
      objectName instanceof Object ? objectName.name : objectName;

    // Find the object in the list with the given name.
    const objectFound = list.find(
      (object) => object.name === objectNameToFind || object.id === objectName
    );

    // If the object is not found, return the original list.
    if (!objectFound) {
      return list;
    }

    // Remove the object from the list.
    list.splice(list.indexOf(objectFound), 1);

    // Push the object to the front of the list.
    list.unshift(objectFound);

    // Return the reordered list.
    return list;
  }

  return (
    <Base>
      <div className="app-inner-layout">
        <div className="app-inner-layout__header-boxed p-0">
          <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
            <h1 className="fs-3">Mon Compte</h1>
          </div>
          <Fragment>
            <div className="app-inner-layout__wrapper">
              <Row className="g-0">
                <Col
                  lg="12"
                  md="12"
                  className="bg-white justify-content-center align-items-center"
                >
                  <Form className="px-4">
                    <Row className="g-0 justify-content-center">
                      <div className="d-flex flex-column justify-content-center g-2 p-3">
                        <div className="d-flex align-content-center justify-content-center text-center">
                          <h1
                            className="text-center mx-3 my-3 fs-1 fw-bold"
                            style={{ color: "#0ab44e" }}
                          >
                            {intl.formatMessage({
                              id: "personalInformation",
                            })}
                          </h1>
                        </div>
                        <div className="d-flex justify-content-center p-3">
                          <div>
                            <Col>
                              <Col md={12} className="mb-4">
                                <FormGroup row>
                                  <Label sm={12} className="mb-2" for="profile">
                                    {intl.formatMessage({
                                      id: "restaurantProfilePhoto",
                                    })}
                                  </Label>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column-reverse",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    {profile?.length > 0 ? (
                                      <Col
                                        sm={6}
                                        className="thumb-container pb-5"
                                      >
                                        {profile.map((file) => (
                                          <div key={file.name}>
                                            <div
                                              style={{
                                                width: "180px",
                                                height: "180px",
                                                marginLeft: "30px",
                                              }}
                                            >
                                              <img
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                src={file.preview}
                                                alt={file.name}
                                                onLoad={() => {
                                                  URL.revokeObjectURL(
                                                    file.preview
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      </Col>
                                    ) : (
                                      <>
                                        <Col
                                          style={{ width: "50%" }}
                                          {...getRootProps({
                                            // className: "wazi-dropzone2",
                                          })}
                                        >
                                          <input
                                            {...getInputProps()}
                                            type="file"
                                            name="profile"
                                            id="profile"
                                          />
                                          <Button
                                            type="button"
                                            size="sm"
                                            className="fw-bold btn btn-login mt-2"
                                            block
                                          >
                                            {intl.formatMessage({
                                              id: "searchForAPhoto",
                                            })}
                                          </Button>
                                        </Col>
                                        <div
                                          style={{
                                            width: "200px",
                                            height: "200px",
                                            borderRadius: "10%",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <img
                                            src={currentUser.profile_picture}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Col>
                          </div>
                          <div>
                            <Col md={12} className="">
                              <FormGroup>
                                <Label for="name">
                                  {intl.formatMessage({
                                    id: "nameRestaurantAdministrator",
                                  })}
                                </Label>
                                <Row>
                                  <Col>
                                    <InputGroup size="lg" className="mt-1">
                                      <Input
                                        type={"text"}
                                        name="name"
                                        id="name"
                                        value={name}
                                        onChange={handleChange}
                                        style={{
                                          backgroundColor: "#eee",
                                          borderColor: "#eee",
                                        }}
                                        bsSize="lg"
                                        placeholder="Noms"
                                        invalid={vname}
                                      />
                                      <InputGroupText
                                        style={{
                                          color: "#000",
                                          backgroundColor: "#eee",
                                          borderColor: "#eee",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          style={{ cursor: "pointer" }}
                                          icon={faUser}
                                        />
                                      </InputGroupText>
                                    </InputGroup>
                                    <FormFeedback
                                      className={vname ? "d-block" : ""}
                                    >
                                      Nom de l'administrateur du restaurant
                                      requis
                                    </FormFeedback>
                                  </Col>
                                  <Col>
                                    <InputGroup size="lg" className="mt-1">
                                      <Input
                                        type={"text"}
                                        name="sname"
                                        id="sname"
                                        value={sname}
                                        onChange={handleChange}
                                        style={{
                                          backgroundColor: "#eee",
                                          borderColor: "#eee",
                                        }}
                                        bsSize="lg"
                                        placeholder="Prénoms"
                                      />
                                      <InputGroupText
                                        style={{
                                          color: "#000",
                                          backgroundColor: "#eee",
                                          borderColor: "#eee",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          style={{ cursor: "pointer" }}
                                          icon={faUserAlt}
                                        />
                                      </InputGroupText>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>

                            <Col className="my-5" md={12}>
                              <FormGroup className="position-relative">
                                <Label for="phone">
                                  {intl.formatMessage({
                                    id: "phoneRestaurantAdministrator",
                                  })}
                                </Label>
                                <Row
                                  noGutters
                                  className="mt-1"
                                  style={{
                                    backgroundColor: "#eee",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <Col sm={11}>
                                    <PhoneInput
                                      value={phone}
                                      onChange={(phone, data) => {
                                        setPhone("+" + phone);
                                        // let v = p.slice(data.dialCode.length)
                                        // setPhoneError(!isValidPhoneNumber(phone))
                                      }}
                                      inputProps={{
                                        name: "phone",
                                        className: "form-control tel",
                                        required: true,
                                        autoFocus: true,
                                      }}
                                      preferredCountries={["cm"]}
                                      enableSearch="true"
                                      className="p-0"
                                      country={"cm"}
                                    />
                                  </Col>
                                  <Col
                                    sm={1}
                                    id="phoneIcon"
                                    className="text-center align-self-center d-none d-md-block"
                                  >
                                    <FontAwesomeIcon
                                      style={{ color: "#000" }}
                                      icon={faPhoneAlt}
                                    />
                                  </Col>
                                </Row>
                                <FormFeedback
                                  className={pError ? "d-block" : ""}
                                >
                                  Numéro de Téléphone invalide
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col className="" md={12}>
                              <FormGroup className="position-relative">
                                <Label for="email">
                                  {intl.formatMessage({
                                    id: "emailRestaurantAdministrator",
                                  })}
                                </Label>
                                <InputGroup size="lg" className="mt-1">
                                  <Input
                                    autoComplete="false"
                                    type={"email"}
                                    name="email"
                                    id="email"
                                    onChange={handleChange}
                                    value={email}
                                    style={{
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                    bsSize="lg"
                                    placeholder="Email de l'administrateur"
                                  />
                                  <InputGroupText
                                    style={{
                                      color: "#000",
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      icon={faEnvelope}
                                    />
                                  </InputGroupText>
                                </InputGroup>
                                <InputGroup size="lg" className="mt-1">
                                  <Input
                                    autoComplete="false"
                                    type={"email"}
                                    name="email1"
                                    id="email1"
                                    onChange={handleChange}
                                    value={email1}
                                    style={{
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                    bsSize="lg"
                                    placeholder="Ajoute Email"
                                  />
                                  <InputGroupText
                                    style={{
                                      color: "#000",
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      icon={faEnvelope}
                                    />
                                  </InputGroupText>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center g-2 p-3">
                        <div className="d-flex align-content-center justify-content-center text-center">
                          <h1
                            className="text-center mx-3 my-3 fs-1 fw-bold"
                            style={{ color: "#0ab44e" }}
                          >
                            {intl.formatMessage({
                              id: "partnerInformation",
                            })}
                          </h1>
                        </div>
                        <div
                          className="d-flex flex-column justify-content-center p-3"
                          style={{ width: "70%" }}
                        >
                          <Col md={12}>
                            <FormGroup className="position-relative">
                              <Label for="name">
                                {intl.formatMessage({
                                  id: "restaurantName",
                                })}
                              </Label>
                              <InputGroup size="lg" className="mt-1">
                                <Input
                                  type={"text"}
                                  name="rname"
                                  id="rname"
                                  value={rname}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: "#eee",
                                    borderColor: "#eee",
                                  }}
                                  bsSize="lg"
                                  placeholder={intl.formatMessage({
                                    id: "name",
                                  })}
                                  invalid={vrname}
                                />
                                <InputGroupText
                                  style={{
                                    color: "#000",
                                    backgroundColor: "#eee",
                                    borderColor: "#eee",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    style={{ cursor: "pointer" }}
                                    icon={faStoreAlt}
                                  />
                                </InputGroupText>
                              </InputGroup>
                              <FormFeedback className={vrname ? "d-block" : ""}>
                                {intl.formatMessage({
                                  id: "restaurantName",
                                })}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col className="mb-4" md={12}>
                            <FormGroup className="position-relative">
                              <Label for="name">
                                {intl.formatMessage({
                                  id: "chooseTheCategory",
                                })}
                              </Label>
                              <Input
                                type={"select"}
                                name="category"
                                id="category"
                                onChange={handleChange}
                                value={category}
                                style={{
                                  backgroundColor: "#eee",
                                  borderColor: "#eee",
                                }}
                                bsSize="lg"
                                placeholder={intl.formatMessage({
                                  id: "chooseTheCategory",
                                })}
                              >
                                {reorderListByObject(
                                  categoryList,
                                  category
                                ).map((list) => (
                                  <option value={list.id}>{list.name}</option>
                                ))}
                              </Input>
                              <FormFeedback
                                className={categoryError ? "d-block" : ""}
                              >
                                {intl.formatMessage({
                                  id: "chooseTheCategory",
                                })}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={12} className="my-4 mb-4">
                            <FormGroup>
                              <Label for="username">
                                {intl.formatMessage({
                                  id: "activitySchedule",
                                })}
                              </Label>
                              <Row>
                                <Col>
                                  <Input
                                    type="time"
                                    step="2"
                                    name="opening"
                                    id="opening"
                                    onChange={handleChange}
                                    style={{
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                    value={open}
                                    bsSize="lg"
                                    placeholder="Heure d'ouverture"
                                  />
                                </Col>
                                <Col>
                                  <Input
                                    type="time"
                                    step="1"
                                    name="closing"
                                    id="closing"
                                    onChange={handleChange}
                                    style={{
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                    value={close}
                                    bsSize="lg"
                                    placeholder="Heure de fermeture"
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup row>
                              <Label sm={12} for="profile">
                                {intl.formatMessage({
                                  id: "adminProfilePhoto",
                                })}
                              </Label>

                              <Col sm={6} className="thumb-container pb-5">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                  }}
                                >
                                  <Dropzone
                                    className=""
                                    onDrop={(acceptedFiles) => {
                                      setImage(
                                        acceptedFiles.map((file) =>
                                          Object.assign(file, {
                                            preview: URL.createObjectURL(file),
                                          })
                                        )
                                      );
                                    }}
                                    name="image"
                                    multiple={false}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div
                                        {...getRootProps({
                                          // className: "wazi-dropzone3",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <Button
                                          type="button"
                                          size="sm"
                                          className="fw-bold btn btn-login mt-2"
                                          block
                                        >
                                          {intl.formatMessage({
                                            id: "searchForAPhoto",
                                          })}
                                        </Button>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div>
                                    {image.length > 0 ? (
                                      <Col
                                        sm={6}
                                        className="thumb-container pb-5"
                                      >
                                        {image.map((file) => (
                                          <div
                                            className="thumb"
                                            key={file.name}
                                          >
                                            <div className="thumb-inner">
                                              <img
                                                style={{
                                                  width: "300px",
                                                  height: "150px",
                                                }}
                                                src={file.preview}
                                                alt={file.name}
                                                onLoad={() => {
                                                  URL.revokeObjectURL(
                                                    file.preview
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      </Col>
                                    ) : (
                                      <>
                                        <img src={profileInfo.picture} />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup className="position-relative">
                              <Label for="name">
                                {intl.formatMessage({
                                  id: "locationAddress",
                                })}
                              </Label>
                              {address !== "" ? (
                                <InputGroup size="lg" className="mt-1">
                                  <Input
                                    type={"text"}
                                    name="address"
                                    id="address"
                                    value={address}
                                    onChange={handleChange}
                                    disabled={true}
                                    style={{
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                    bsSize="lg"
                                    placeholder="Adresse de localisation"
                                  />
                                  <InputGroupText
                                    style={{
                                      color: "#000",
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      icon={faLocationDot}
                                    />
                                  </InputGroupText>
                                </InputGroup>
                              ) : (
                                <></>
                              )}

                              <FormFeedback
                                className={addressError ? "d-block" : ""}
                              >
                                Adresse de localisation requise
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={12} className="mb-4">
                            <FormGroup>
                              <Button
                                type="button"
                                onClick={() => (
                                  getPosition(), handleOpenModal()
                                )}
                                size="md"
                                className="fw-bold btn btn-login"
                                block
                              >
                                <FontAwesomeIcon icon={faMapPin} /> &nbsp;
                                {intl.formatMessage({
                                  id: "openMap",
                                })}
                              </Button>
                            </FormGroup>
                          </Col>
                          <Modal
                            isOpen={isOpen}
                            onClose={handleCloseModal}
                            size={"5xl"}
                          >
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>
                                <b>Carte de géolocalisation</b>
                              </ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                <ModalBodyComponent
                                  initialCoordinates={{
                                    latitude: lat ?? 4.0511,
                                    longitude: long ?? 9.7678,
                                  }}
                                  initialAddress={address}
                                  onClose={handleCloseModal}
                                  onSaveCoordinates={handleSaveCoordinates}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                          <Col md={12}>
                            <FormGroup className="position-relative">
                              <Label for="name">
                                {intl.formatMessage({
                                  id: "registrationNumber",
                                })}{" "}
                              </Label>
                              <Input
                                type={"text"}
                                name="immatriculation"
                                id="immatriculation"
                                onChange={handleChange}
                                value={immatriculation}
                                style={{
                                  backgroundColor: "#eee",
                                  borderColor: "#eee",
                                }}
                                bsSize="lg"
                                placeholder="Immatriculation"
                              />
                              <FormFeedback
                                className={immError ? "d-block" : ""}
                              >
                                Input Your Immatriculation Number
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={9}>
                            <FormGroup row>
                              <Label sm={12} for="profile">
                                {intl.formatMessage({
                                  id: "documentRCCM",
                                })}
                              </Label>
                              <Col sm={6} className="thumb-container pb-5">
                                <Dropzone
                                  className=""
                                  onDrop={(acceptedFiles) => {
                                    setRCCM(
                                      acceptedFiles.map((file) =>
                                        Object.assign(file, {
                                          preview: URL.createObjectURL(file),
                                        })
                                      )
                                    );
                                  }}
                                  name="rccm"
                                  multiple={false}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps({
                                        className: "wazi-dropzone2",
                                      })}
                                    >
                                      <input {...getInputProps()} />
                                      <span style={{ fontSize: ".8rem" }}>
                                        Recherchez ou Déposer une photo ici
                                      </span>
                                    </div>
                                  )}
                                </Dropzone>
                              </Col>
                              {rccm.length > 0 ? (
                                <Col sm={6}>
                                  {rccm.map((file) => (
                                    <>
                                      <div className="" key={file.name}>
                                        <div className="">
                                          <Row className="text-center">
                                            <Col
                                              sm={6}
                                              className="text-center justify-content-center"
                                            >
                                              <img
                                                style={{
                                                  width: "150px",
                                                  height: "150px",
                                                  borderRadius: "10%",
                                                  marginLeft: "10px",
                                                }}
                                                src={doc}
                                                alt={file.name}
                                                onLoad={() => {
                                                  URL.revokeObjectURL(
                                                    file.preview
                                                  );
                                                }}
                                              />
                                            </Col>
                                            <Col
                                              sm={6}
                                              className="text-center align-self-center align-items-center"
                                            >
                                              <b className="text-center">
                                                {file.name}
                                              </b>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </Col>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                    }}
                                    className="text-center justify-content-center"
                                  >
                                    <img
                                      className="text-center justify-content-center"
                                      src={currentUser.rccm_document}
                                    />
                                  </div>
                                </>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={9} className="mb-4">
                            <FormGroup row>
                              <Label sm={12} for="profile">
                                {intl.formatMessage({ id: "idPicture" })}
                              </Label>
                              <Col sm={6} className="thumb-container pb-5">
                                <Dropzone
                                  className=""
                                  onDrop={(acceptedFiles) => {
                                    setCNI(
                                      acceptedFiles.map((file) =>
                                        Object.assign(file, {
                                          preview: URL.createObjectURL(file),
                                        })
                                      )
                                    );
                                  }}
                                  name="cni"
                                  multiple={false}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps({
                                        className: "wazi-dropzone2",
                                      })}
                                    >
                                      <input {...getInputProps()} />
                                      <span style={{ fontSize: ".8rem" }}>
                                        Recherchez ou Déposer une photo ici
                                      </span>
                                    </div>
                                  )}
                                </Dropzone>
                              </Col>
                              <div
                                style={{
                                  width: "200px",
                                  height: "200px",
                                }}
                              >
                                {cni.length > 0 ? (
                                  <Col>
                                    {cni.map((file) => (
                                      <div
                                        className="thumb"
                                        key={file.name}
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                        }}
                                      >
                                        <img
                                          name="cni"
                                          src={file.preview}
                                          alt={file.name}
                                          onLoad={() => {
                                            URL.revokeObjectURL(file.preview);
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </Col>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        width: "200px",
                                        height: "200px",
                                      }}
                                      className="text-center justify-content-center"
                                    >
                                      <img
                                        className="text-center justify-content-center"
                                        src={profileInfo.cni}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          {/* <Col className="mb-4" md={12}>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                            {cni.length > 0 ? (
                                                                <Col sm={6} className="thumb-container pb-5">
                                                                    {cni.map((file) => (
                                                                        <div className="thumb" key={file.name}>
                                                                            <div className="thumb-inner"
                                                                                 style={{
                                                                                     width: "115px",
                                                                                     height: "115px",
                                                                                 }}>
                                                                                <img
                                                                                    src={file.preview}
                                                                                    alt={file.name}
                                                                                    onLoad={() => {
                                                                                        URL.revokeObjectURL(file.preview);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </Col>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col className="mb-4" md={12}>
                            <FormGroup className="position-relative">
                              <Label for="name">Commissions</Label>
                              <Input
                                type={"text"}
                                name="commission"
                                id="commission"
                                value='50'
                                readonly
                                style={{
                                  backgroundColor: "#eee",
                                  borderColor: "#eee",
                                }}
                                bsSize="lg"
                              >
                              </Input>
                            </FormGroup>
                          </Col> */}
                          <Col md={12}>
                            <FormGroup className="position-relative">
                              <Label for="name">
                                {intl.formatMessage({
                                  id: "accountNameOM",
                                })}
                              </Label>
                              <Input
                                type={"text"}
                                name="merchantName"
                                id="merchantName"
                                onChange={(e) => {
                                  setmerchantName(e.target.value);
                                }}
                                value={merchantName}
                                style={{
                                  backgroundColor: "#eee",
                                  borderColor: "#eee",
                                }}
                                bsSize="lg"
                                placeholder="Entrez le nom du marchand "
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup className="position-relative">
                              <Label for="name">
                                {intl.formatMessage({
                                  id: "mrchantAccountAndTelephoneNumber",
                                })}
                              </Label>
                              <Input
                                type={"text"}
                                name="merchantCode"
                                id="merchantCode"
                                onChange={(e) => {
                                  setmerchantCode(e.target.value);
                                }}
                                value={merchantCode}
                                style={{
                                  backgroundColor: "#eee",
                                  borderColor: "#eee",
                                }}
                                bsSize="lg"
                                placeholder="Entrez le code code Marchand"
                              ></Input>
                            </FormGroup>
                          </Col>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center g-2 p-3">
                        <div
                          className="d-flex align-content-center justify-content-center text-center"
                          id="toggler"
                          style={{ height: "150px" }}
                        >
                          <h6
                            className="text-center mx-3 my-3 fs-1 fw-bold"
                            style={{ color: "#0ab44e", cursor: "pionter" }}
                          >
                            {intl.formatMessage({
                              id: "bankingInformation",
                            })}
                          </h6>
                          <FontAwesomeIcon
                            style={{ marginTop: "25px" }}
                            icon={faAngleDoubleDown}
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-center p-3">
                          <div>
                            <UncontrolledCollapse toggler="#toggler">
                              <Row md={12}>
                                <Col md={12}>
                                  <FormGroup className="position-relative">
                                    <Label for="name">Nom de banque</Label>
                                    <Input
                                      type={"text"}
                                      name="bankName"
                                      id="bankName"
                                      value={bankName}
                                      onChange={handleChange}
                                      style={{
                                        backgroundColor: "#eee",
                                        borderColor: "#eee",
                                      }}
                                      bsSize="lg"
                                    ></Input>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="position-relative">
                                    <Label for="name">
                                      {intl.formatMessage({
                                        id: "bankAddress",
                                      })}
                                    </Label>
                                    <Input
                                      type={"text"}
                                      name="bankAddress"
                                      id="bankAddress"
                                      value={bankAddress}
                                      onChange={handleChange}
                                      style={{
                                        backgroundColor: "#eee",
                                        borderColor: "#eee",
                                      }}
                                      bsSize="lg"
                                    ></Input>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="position-relative">
                                    <Label for="name">Swift Code</Label>
                                    <Input
                                      type={"text"}
                                      name="swiftCode"
                                      id="swiftCode"
                                      value={swiftCode}
                                      onChange={handleChange}
                                      style={{
                                        backgroundColor: "#eee",
                                        borderColor: "#eee",
                                      }}
                                      bsSize="lg"
                                    ></Input>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="position-relative">
                                    <Label for="name">
                                      {intl.formatMessage({
                                        id: "accountNumber",
                                      })}
                                    </Label>
                                    <Input
                                      type={"text"}
                                      name="accountNumber"
                                      id="accountNumber"
                                      value={accountNumber}
                                      onChange={handleChange}
                                      style={{
                                        backgroundColor: "#eee",
                                        borderColor: "#eee",
                                      }}
                                      bsSize="lg"
                                    ></Input>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="position-relative">
                                    <Label for="name">
                                      {intl.formatMessage({
                                        id: "bankAccountNumber",
                                      })}{" "}
                                    </Label>
                                    <Input
                                      type={"text"}
                                      name="bankCode"
                                      id="bankCode"
                                      value={bankCode}
                                      onChange={handleChange}
                                      style={{
                                        backgroundColor: "#eee",
                                        borderColor: "#eee",
                                      }}
                                      bsSize="lg"
                                    ></Input>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="position-relative">
                                    <Label for="name">
                                      {intl.formatMessage({
                                        id: "bankAccountNumber",
                                      })}
                                    </Label>
                                    <Input
                                      type={"text"}
                                      name="bankAgencyCode"
                                      id="bankAgencyCode"
                                      onChange={handleChange}
                                      value={bankAgencyCode}
                                      style={{
                                        backgroundColor: "#eee",
                                        borderColor: "#eee",
                                      }}
                                      bsSize="lg"
                                    ></Input>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="position-relative">
                                    <Label for="name">
                                      {intl.formatMessage({
                                        id: "fullBankAccount",
                                      })}
                                    </Label>
                                    <Input
                                      type={"text"}
                                      name="ribCode"
                                      id="ribCode"
                                      value={ribCode}
                                      onChange={handleChange}
                                      style={{
                                        backgroundColor: "#eee",
                                        borderColor: "#eee",
                                      }}
                                      bsSize="lg"
                                    ></Input>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="position-relative">
                                    <Label for="name">Iban</Label>
                                    <Input
                                      type={"text"}
                                      name="iban"
                                      id="iban"
                                      value={iban}
                                      onChange={handleChange}
                                      style={{
                                        backgroundColor: "#eee",
                                        borderColor: "#eee",
                                      }}
                                      bsSize="lg"
                                    ></Input>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </UncontrolledCollapse>
                          </div>

                          <Col
                            className="mb-4"
                            md={12}
                            style={{ marginTop: "100px" }}
                          >
                            <FormGroup className="position-relative">
                              <Label for="name">
                                {intl.formatMessage({
                                  id: "startingDate",
                                })}
                              </Label>
                              <Input
                                type={"date"}
                                name="dateDeDemarrage"
                                id="dateDeDemarrage"
                                onChange={(e) => {
                                  setdateDeDemarrage(e.target.value);
                                }}
                                value={dateDeDemarrage}
                                style={{
                                  backgroundColor: "#eee",
                                  borderColor: "#eee",
                                }}
                                bsSize="lg"
                                placeholder="Entré la de démarrage"
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup row>
                              <Label
                                className="text-center"
                                sm={12}
                                for="username"
                              >
                                <b>
                                  {intl.formatMessage({
                                    id: "restaurantPhotos",
                                  })}
                                </b>
                                <br />
                              </Label>
                              <Col sm={12}>
                                <Dropzone
                                  className="mt-2"
                                  onDrop={(acceptedFiles) => {
                                    setPicture(
                                      acceptedFiles.map((file) =>
                                        Object.assign(file, {
                                          preview: URL.createObjectURL(file),
                                        })
                                      )
                                    );
                                  }}
                                  name="pictures"
                                  multiple={true}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps({
                                        className:
                                          "wazi-eats-dropzone justify-content-center",
                                      })}
                                    >
                                      <input {...getInputProps()} />
                                      <span
                                        className=""
                                        style={{ fontSize: ".8rem" }}
                                      >
                                        Veuillez cliquer ici pour choisir vos
                                        images
                                      </span>
                                    </div>
                                  )}
                                </Dropzone>
                                <Col
                                  md={9}
                                  className="thumbs-container pb-5 px-4"
                                  style={{ overflow: "auto" }}
                                >
                                  {restaurant.map((file) => (
                                    <div className="thumbs" key={file.id}>
                                      <div
                                        className="thumbs-inner"
                                        style={{
                                          width: "115px",
                                          height: "115px",
                                        }}
                                      >
                                        <img src={file.image} alt={file.id} />
                                      </div>
                                    </div>
                                  ))}
                                </Col>
                                {picture.length > 0 ? (
                                  <Col sm={6} className="thumb-container pb-5">
                                    {picture?.map((file) => (
                                      <div className="thumb" key={file.name}>
                                        <div className="thumb-inner">
                                          <img
                                            style={{
                                              width: "115px",
                                              height: "115px",
                                            }}
                                            src={file.preview || file.image}
                                            alt={file.name}
                                            onLoad={() => {
                                              URL.revokeObjectURL(file.preview);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </Col>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </FormGroup>
                          </Col>
                        </div>
                      </div>

                      <Col lg={12} className="bg-white">
                        <Row>
                          {/*<Col md={6}>*/}
                          {/*  <FormGroup>*/}
                          {/*    <Label for="username">*/}
                          {/*      Mot de passe*/}
                          {/*    </Label>*/}
                          {/*    <Row className="">*/}
                          {/*      <Col sm={12}>*/}
                          {/*        <InputGroup size="lg" className="mt-3">*/}
                          {/*          <Input*/}
                          {/*              type={ show ? "text" : "password"}*/}
                          {/*              name="password"*/}
                          {/*              id="password"*/}
                          {/*              onChange={handleChange}*/}
                          {/*              value={password}*/}
                          {/*              style={{ backgroundColor: "#eee", borderColor: "#eee" }}*/}
                          {/*              bsSize="lg"*/}
                          {/*              placeholder="Mot de passe"/>*/}
                          {/*          <InputGroupText onClick={() => setShow(!show)} style={{ color: "#000", backgroundColor: "#eee", borderColor: "#eee" }}>*/}
                          {/*            { show ? <FontAwesomeIcon style={{ cursor : "pointer" }} icon={faEyeSlash} /> : <FontAwesomeIcon style={{ cursor : "pointer" }} icon={faEye} /> }*/}
                          {/*          </InputGroupText>*/}
                          {/*        </InputGroup>*/}
                          {/*      </Col>*/}
                          {/*      <Col sm={12}>*/}
                          {/*        <InputGroup size="lg" className="mt-4">*/}
                          {/*          <Input*/}
                          {/*              type={ view ? "text" : "password"}*/}
                          {/*              name="confirm"*/}
                          {/*              id="confirm"*/}
                          {/*              onChange={handleChange}*/}
                          {/*              value={confirm}*/}
                          {/*              style={{ backgroundColor: "#eee", borderColor: "#eee" }}*/}
                          {/*              bsSize="lg"*/}
                          {/*              placeholder="Confirmez le mot de passe"/>*/}
                          {/*          <InputGroupText onClick={() => setView(!view)} style={{ color: "#000", backgroundColor: "#eee", borderColor: "#eee" }}>*/}
                          {/*            { view ? <FontAwesomeIcon style={{ cursor : "pointer" }} icon={faEyeSlash} /> : <FontAwesomeIcon style={{ cursor : "pointer" }} icon={faEye} /> }*/}
                          {/*          </InputGroupText>*/}
                          {/*        </InputGroup>*/}
                          {/*      </Col>*/}
                          {/*      <FormFeedback className={ passError ? "d-block": ""}>*/}
                          {/*        Mot de passe invalide*/}
                          {/*      </FormFeedback>*/}
                          {/*    </Row>*/}
                          {/*  </FormGroup>*/}
                          {/*</Col>*/}

                          <Col md={12}>
                            <FormGroup>
                              <Label for="username">
                                {intl.formatMessage({
                                  id: "webSite",
                                })}
                              </Label>
                              <InputGroup size="md" className="mt-1 mb-3">
                                <Input
                                  type={"text"}
                                  name="website"
                                  id="website"
                                  onChange={handleChange}
                                  value={web}
                                  style={{
                                    backgroundColor: "#eee",
                                    borderColor: "#eee",
                                  }}
                                  bsSize="lg"
                                  placeholder="Site Web"
                                />
                                <InputGroupText
                                  style={{
                                    color: "#000",
                                    backgroundColor: "#eee",
                                    borderColor: "#eee",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    style={{ cursor: "pointer" }}
                                    icon={faGlobeAfrica}
                                  />
                                </InputGroupText>
                              </InputGroup>
                              <InputGroup size="md" className="mt-1 mb-3">
                                <Input
                                  type={"text"}
                                  name="website1"
                                  id="website1"
                                  onChange={handleChange}
                                  value={web1}
                                  style={{
                                    backgroundColor: "#eee",
                                    borderColor: "#eee",
                                  }}
                                  bsSize="lg"
                                  placeholder="Site Web"
                                />
                                <InputGroupText
                                  style={{
                                    color: "#000",
                                    backgroundColor: "#eee",
                                    borderColor: "#eee",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    style={{ cursor: "pointer" }}
                                    icon={faGlobeAfrica}
                                  />
                                </InputGroupText>
                              </InputGroup>
                              <FormFeedback
                                className={webError ? "d-block" : ""}
                              >
                                Entrer Site Web
                              </FormFeedback>
                              <FormText>
                                Exemple: <i>https://www.wazifood.com</i>
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup className="mt-5">
                              <Label for="username">
                                {intl.formatMessage({
                                  id: "otherLinks",
                                })}{" "}
                                (Facebook, Instagram, WhatsApp etc.)
                              </Label>
                              <CreatableSelect
                                components={components}
                                inputValue={social}
                                onChange={(newValue) => setValue(newValue)}
                                onInputChange={(newValue) =>
                                  setSocial(newValue)
                                }
                                onKeyDown={handleKeyDown}
                                value={value}
                                className="mb-3"
                                menuIsOpen={false}
                                placeholder="Entrez les liens"
                                style={{
                                  backgroundColor: "#eee",
                                  borderColor: "#eee",
                                }}
                                isMulti
                                isClearable
                              />
                              <FormText>
                                {intl.formatMessage({
                                  id: "enterLinkPressEnter",
                                })}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <div style={{ height: "15%" }}>
                          <Row className="px-3 mb-4">
                            <Col>
                              <SubmitButton />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Fragment>
        </div>
      </div>
    </Base>
  );
}
