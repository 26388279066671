import React, {useEffect, useRef, useState} from "react";
import {LoadScript, StandaloneSearchBox,} from "@react-google-maps/api";
import {Button, Input, InputGroup, InputGroupText} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {GoogleMap, Marker, withGoogleMap} from "react-google-maps";

import Geocode from "react-geocode"; // Import the Geocode library


function MapComponent({coordinates, onMarkerDragEnd}) {
    const [map, setMap] = useState(null); // Keep track of the map instance

    const handleMapLoad = (mapInstance) => {
        setMap(mapInstance);
    };

    useEffect(() => {
        if (map && coordinates) {
            const newCenter = new window.google.maps.LatLng(
                coordinates.latitude,
                coordinates.longitude
            );
            map.setCenter(newCenter);
        }
    }, [map, coordinates]);

    return (
        <GoogleMap
            defaultZoom={12}
            center={{
                lat: coordinates.latitude,
                lng: coordinates.longitude,
            }}
            defaultCenter={{
                lat: coordinates.latitude,
                lng: coordinates.longitude,
            }}
            onLoad={handleMapLoad}
        >

            <Marker
                position={{
                    lat: coordinates.latitude,
                    lng: coordinates.longitude,
                }}
                draggable={true} // Allow marker to be draggable
                onDragEnd={onMarkerDragEnd} // Callback for when marker is dragged
            />
        </GoogleMap>
    );
}

const WrappedMapComponent = withGoogleMap(MapComponent);


function ModalBodyComponent({initialCoordinates, initialAddress, onClose, onSaveCoordinates}) {
    const [address, setAddress] = useState(initialAddress || "");
    const [coordinates, setCoordinates] = useState(initialCoordinates || null);
    const [autocomplete, setAutocomplete] = useState(null);
    const inputRef = useRef(null);

    const lib = ["places"];

    useEffect(() => {
        // Set your Google Maps API key for Geocode
        Geocode.setApiKey("AIzaSyBScVzghtFm_hSY6OCoa0AKu79dHgSK2Ks");
    }, []);

    useEffect(() => {
        if (autocomplete) {
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place && place.geometry) {
                    setCoordinates({
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng(),
                    });
                    setAddress(place.formatted_address);
                }
            });
        }
    }, [autocomplete]);

    const handleSearchChange = (event) => {
        setAddress(event.target.value);
    };

    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();

        // Update the marker's coordinates
        setCoordinates({ latitude: newLat, longitude: newLng });

        // Perform reverse geocoding to get the address
        Geocode.fromLatLng(newLat, newLng).then(
            (response) => {
                const address = response.results[0].formatted_address;
                console.log("Address:", address);
                setAddress(address)
                // You can do whatever you need with the address here
            },
            (error) => {
                console.error("Error getting address:", error);
            }
        );
    };

    const onAddressesChanged = () => {
        const places = inputRef.current.getPlaces();

        if (places.length > 0) {
            const selectedPlace = places[0];
            setAddress(selectedPlace.name + ", " + selectedPlace.formatted_address);
            setCoordinates({
                latitude: selectedPlace.geometry.location.lat(),
                longitude: selectedPlace.geometry.location.lng(),
            });
        }
    };

    const handleSaveCoordinates = () => {
        onSaveCoordinates(address, coordinates.latitude, coordinates.longitude);
        onClose();
    };

    return (
        <>
            <LoadScript
                googleMapsApiKey="AIzaSyCIM7020qhSXKf3cGV-xuSd9pSg9IFEl_A"
                libraries={lib}
                onLoad={() => {
                    const autoCompleteInstance = new window.google.maps.places.Autocomplete(
                        inputRef.current
                    );
                    setAutocomplete(autoCompleteInstance);
                }}
            >
                <div className="mb-4" style={{width: '100%'}}>
                    <InputGroup size="lg" className="mt-1">
                        <StandaloneSearchBox
                            onLoad={(ref) => (inputRef.current = ref)}
                            onPlacesChanged={onAddressesChanged}
                            style={{zIndex: '100000 !important'}}
                        >
                            <Input
                                type={"text"}
                                name="address"
                                id="address"
                                value={address}
                                onChange={handleSearchChange}
                                ref={inputRef}
                                style={{
                                    backgroundColor: "#eee",
                                    borderColor: "#eee",
                                    width: "100%",
                                }}
                                bsSize="lg"
                                zIndex={1000001}
                                placeholder="Adresse de localisation"
                            />
                        </StandaloneSearchBox>
                        <InputGroupText
                            style={{
                                color: "#000",
                                backgroundColor: "#eee",
                                borderColor: "#eee",
                            }}
                        >
                            <FontAwesomeIcon
                                style={{cursor: "pointer"}}
                                icon={faLocationDot}
                            />
                        </InputGroupText>
                    </InputGroup>
                </div>
                <div style={{height: '100%', width: '100%'}}>
                    <WrappedMapComponent
                        coordinates={coordinates} // Pass the coordinates object
                        onMarkerDragEnd={handleMarkerDragEnd}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBScVzghtFm_hSY6OCoa0AKu79dHgSK2Ks&v=3.exp&libraries=places`}
                        loadingElement={<div style={{height: `100%`}}/>}
                        containerElement={<div style={{height: `400px`}}/>}
                        mapElement={<div style={{height: `100%`}}/>}
                    />
                    <br/>
                    <div className="text-center py-4">
                        Cliquez sur l'emplacement sur la carte pour
                        enregistrer les coordonnées
                    </div>
                </div>
                <Button
                    size="lg"
                    className="fw-bold btn btn-login"
                    block
                    onClick={handleSaveCoordinates}
                >
                    Enregistrer les cordonnées
                </Button>
            </LoadScript>
        </>
    );
}

export default ModalBodyComponent;
