import React, { createContext, useState } from "react";
import enMessages from "../translation/en.json";
import frMessages from "../translation/fr.json";

const messages = {
  en: enMessages,
  fr: frMessages,
};

const LanguageContext = createContext("fr");

const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "fr"
  );
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    localStorage.setItem("selectedLanguage", language);
  };

  return (
    <LanguageContext.Provider
      value={{ selectedLanguage, handleLanguageChange }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { messages, LanguageContext, LanguageProvider };
