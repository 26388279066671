import React, {
    KeyboardEventHandler,
    Fragment,
    useEffect,
    useState,
    useRef,
} from "react";
import {
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    FormFeedback,
    FormText,
} from "reactstrap";
import logo from "../../assets/utils/images/logo.png";
import doc from "../../assets/doc.webp";
import { login } from "../../features/auth";
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../../utils/selectors";
import { Redirect } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faStore,
    faStoreAlt,
    faFileContract,
    faUserCog,
    faMapPin,
    faGlobeAfrica,
    faLocationDot,
    faEyeSlash,
    faEye,
    faUserAlt,
    faPhoneAlt,
    faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { InputGroup, InputGroupText, Input } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import { isValidPhoneNumber } from "react-phone-number-input";
import foodService from "../../services/foodService";
import drinkService from "../../services/drinkService";
import { Spinner } from "@chakra-ui/react";
import mainService from "../../services/mainService";
import swal from "sweetalert";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
// import { ClearButton } from "react-bootstrap-typeahead";
import { event } from "jquery";
import { isValidPhoneNumber } from "libphonenumber-js";
import AppModal from "../../components/AppModal";
import isEmail from "validator/es/lib/isEmail";
import TermsModal from "../../components/TermCondition";
import ModalBodyComponent from "../Profil/Map";

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});

const lib = ["places"];

export default function Login() {
    const [confirm, setConfirm] = useState("");
    const [password, setPassword] = useState("");
    const [passError, setPassError] = useState("");
    const [loading, setLoading] = useState(false);

    const [rname, setRname] = useState("");
    const [vrname, setVRname] = useState(false);

    const [name, setName] = useState("");
    const [sname, setSname] = useState("");
    const [vname, setVname] = useState(false);

    const [open, setOpen] = useState("08:00:00");
    const [close, setClose] = useState("20:00:00");
    const [oHour, setOhour] = useState(false);
    const [cHour, setChour] = useState(false);

    const [category, setCategory] = useState("");
    const [categoryList, setCategoryList] = useState([]);

    const [long, setLong] = useState()
    const [lat, setLat] = useState()
    const [address, setAddress] = useState('')
    console.log(long, lat);


    // let long = 0.0;
    // let lat = 0.0;
    // let address = "";


    const [tabIndex, setTabIndex] = useState(0);

    const [profile, setProfile] = useState([]);
    const [picture, setPicture] = useState([]);
    const [profileError, setProfileError] = useState(false);
    const [cni, setCNI] = useState([]);
    const [rccm, setRCCM] = useState([]);
    const [immatriculation, setImmatriculation] = useState([]);
    const [image, setImage] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": [],
        },
        multiple: false,
        onDrop: (acceptedFiles) => {
            setProfile(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    const removeFiles = (file) => () => {
        const newProfile = [...profile];
        newProfile.splice(profile, 1);
        setProfile(newProfile);
        event.preventDefault;
    };

    const removePicture = (file) => () => {
        const newPicture = [...picture];
        newPicture.splice(picture.indexOf(file), 1);
        setPicture(newPicture);
        event.preventDefault;
    };

    useEffect(() => {
        return () => profile.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [profile]);

    useEffect(() => {
        mainService
            .getCategories()
            .then((response) => {
                setCategoryList(response.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    const isConnected = useSelector(isLoggedIn);

    const getValidUrl = (url = "") => {
        if (url === "") {
            return url;
        } else {
            let newUrl = window.decodeURIComponent(url);
            newUrl = newUrl.trim().replace(/\s/g, "");

            if (/^(:\/\/)/.test(newUrl)) {
                return `http${newUrl}`;
            }
            if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
                return `http://${newUrl}`;
            }

            return newUrl;
        }
    };

    const inputRef = useRef();
    const onAddressesChanged = () => {
        const [place] = inputRef.current.getPlaces();
        setAddress(place.formatted_address);
        setLong(place.geometry.location.lng());
        setLat(place.geometry.location.lat());
        console.log(place);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (rname === "") {
            setVRname(true);
            setTabIndex(0);
        } else if (phone === "" || !isValidPhoneNumber(phone, 'CM')) {
            setVRname(false);
            setPhoneError(true);
            setTabIndex(2);
        } else if (name === "") {
            setVRname(false);
            setPhoneError(false);
            setVname(true);
            setTabIndex(2);
        } else if (phone2 === "" || !isValidPhoneNumber(phone2, 'CM')) {
            setVRname(false);
            setPhoneError(false);
            setVname(false);
            setPhone2Error(true);
        } else if (email === "" || !isEmail(email,)) {
            setVRname(false);
            setPhoneError(false);
            setVname(false);
            setPhone2Error(false);
            setEmailError(true);
        } else if (profile === undefined || profile.length === 0) {
            setVRname(false);
            setPhoneError(false);
            setVname(false);
            setPhone2Error(false);
            setProfileError(true);
        } else {
            if (terms) {

                setPhoneError(false);
                setPhone2Error(false);
                setVRname(false);
                setVname(false);
                setProfileError(false);

                let formData = new FormData();

                formData.append("name", rname);

                if (address !== undefined && address !== "") {
                    formData.append("address", address);
                }
                if (lat !== undefined && lat !== null) {
                    formData.append("location", lat);
                }
                if (long !== undefined && long !== null) {
                    formData.append("location", long);
                }

                for (let i = 0; i < Math.max(picture.length, 3); i++) {
                    if (i < picture.length) {
                        formData.append("picture_restaurant[" + i + "]image", picture[i]);
                    } else {
                        formData.append("picture_restaurant[" + i + "]image", profile[0]);
                    }
                }

                formData.append("email", email);
                formData.append("phone", phone);
                formData.append("whatsapp_number", phone2);
                formData.append("first_name", sname);
                formData.append("last_name", name);
                formData.append("profile_picture", profile[0]);
                formData.append('restaurantCategory', category);
                formData.append("role", "restaurant");

                if (web !== '') {
                    formData.append("internet_site", getValidUrl(web));
                  }
                for (let i = 0; i < value.length; i++) {
                    formData.append(
                        "social_network_link[" + i + "]",
                        getValidUrl(value[i].value)
                    );
                }

                
                // Display the key/value pairs
                for (var pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }
                try {

                    const response = await mainService
                        .createRestaurant(formData);

                    console.log("Server RESPONSE", response);
                    setLoading(false);
                    swal(
                        "Votre demande de creation de compte est en cours de traitement",
                        "success"
                    ).then(() => {
                        window.location.href = "/";
                    });
                } catch (e) {
                    setLoading(false);
                    console.log("Server ERROR", e);
                    let error = e.response.data.message;
                    console.log(error);
                    if (error !== undefined) {
                        if (error && error.includes("email")) {
                            swal(
                                "Erreur",
                                "Un compte a été déjà enregistré avec cet email",
                                "error"
                            );
                        } else if (error && error.includes("name")) {
                            swal(
                                "Erreur",
                                "Un compte a été déjà enregistré avec ce nom",
                                "error"
                            );
                        } else if (error && error.includes("phone")) {
                            swal(
                                "Erreur",
                                "Un compte a été déjà enregistré avec ce numéro de téléphone",
                                "error"
                            );
                        } else if (error && error.includes("whatsapp_number")) {
                            swal(
                                "Erreur",
                                "Un compte a été déjà enregistré avec ce numéro whatsapp",
                                "error"
                            );
                        } else if (error && error.includes("profile_picture")) {
                            swal(
                                "Erreur",
                                "La photo de profil du restaurant est requise pour procéder",
                                "error"
                            );
                        } else if (error && error.includes("picture_restaurant")) {
                            swal(
                                "Erreur",
                                "Une photo du restaurant est requise pour procéder",
                                "error"
                            );
                        } else if (error && error.includes("picture")) {
                            swal(
                                "Erreur",
                                "La photo de profil de l'administrateur est requise pour procéder",
                                "error"
                            );
                        } else if (error && error.includes("internet_site")) {
                            swal(
                                "Erreur",
                                "Un compte a été déjà enregistré avec ce site web",
                                "error"
                            );
                        } else if (error && error.includes("social_network_link")) {
                            swal(
                                "Erreur",
                                "Un lien de réseau social est requis pour procéder",
                                "error"
                            );
                        } else {
                            swal(
                                "Erreur",
                                "Veuillez saisir tous les champs obligatoires",
                                "error"
                            );
                        }
                    } else {
                        if (
                            Math.floor(e.response.status / 400) === 1 ||
                            Math.floor(e.response.status / 500) === 1
                        ) {
                            swal("Erreur", e.response.data.detail ?? e.response.message, "error");
                        } else {
                            swal(
                                "Erreur",
                                "Veuillez saisir tous les champs obligatoires",
                                "error"
                            );
                        }
                    }
                }
            } else {
                swal(
                    "Erreur",
                    "Terms and Conditions Not Accepted",
                    "error"
                );
            }
        }

        setLoading(false);
    }

    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        switch (name) {
            case "password":
                setPassword(value);
                break;
            case "confirm":
                setConfirm(value);
                break;
            case "name":
                setName(value);
                break;
            case "immatriculation":
                setImmatriculation(value);
                break;
            case "sname":
                setSname(value);
                break;
            case "rname":
                setRname(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "opening":
                setOpen(value);
                break;
            case "closing":
                setClose(value);
                break;
            case "website":
                setWeb(value);
                break;
            case "category":
                setCategory(value);
                break;
            default:
                break;
        }
    }

    const [show, setShow] = useState(false);
    const [view, setView] = useState(false);

    const [email, setEmail] = useState("");

    const [terms, setTerms] = useState(true);
    const [phone, setPhone] = useState("");
    const [phone2, setPhone2] = useState("");
    const [pError, setPhoneError] = useState(false);
    const [p2Error, setPhone2Error] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [fCategory, setFcategory] = useState([1]);
    const [fType, setFtype] = useState([1]);
    const [dCategory, setDcategory] = useState([1]);
    const [dType, setDtype] = useState([1]);
    const [module, setModule] = useState([1]);
    const [channel, setChannel] = useState(1);

    const [web, setWeb] = useState("");
    const [social, setSocial] = useState("");

    const [value, setValue] = React.useState([]);

    const handleKeyDown = (event) => {
        if (!social) return;
        switch (event.key) {
            case "Enter":
            case "Tab":
                setValue((prev) => [...prev, createOption(social)]);
                setSocial("");
                event.preventDefault();
        }
    };
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleSaveCoordinates = (address, latitude, longitude) => {
        setAddress(address);
        setLat(latitude);
        setLong(longitude);
    };


    const getPosition = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    setLat(latitude);
                    setLong(longitude);
                    await getAddressFromCoordinates(latitude, longitude);
                    // showPosition(position)
                },
                (error) => {
                    console.error(error.message);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const getAddressFromCoordinates = async (latitude, longitude) => {
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${APIKEY}`;

        try {
            const response = await fetch(apiUrl);
            const data = await response.json();

            if (data.results && data.results.length > 0) {
                setAddress(data.results[0].formatted_address);
            } else {
                setAddress('Address not found.');
                console.log('Address not found');
            }
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    return (
        <Fragment>
            <div className="h-100">
                <LoadingOverlay
                    tag="div"
                    active={loading}
                    style={{
                        position: "absolute",
                    }}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            height: "100%",
                            width: "100%",
                        }),
                    }}
                    spinner={<Loader active={loading} type="ball-pulse-rise" />}
                >
                    <Row className="g-0 main-section">
                        <Col lg="5" md="12" className="d-flex d-lg-block section1">
                            <div className="wazieats-bg-primary-color text-light sd">
                                <div className="banner">
                                    <img src={logo} alt="Logo wazieats" width="60" height="60" />
                                    <h1 className="pb-1 pt-3">
                                        Wazi<b>Food</b>
                                    </h1>
                                    {/*<br/>*/}
                                    {/*<span className="fs-4">Food Delivery</span>*/}
                                </div>
                                <h1 style={{ fontSize: "5rem" }} className="fw-bold mt-3 wt">
                                    Bienvenue
                                </h1>

                                <div>
                                    <AppModal />
                                </div>
                            </div>
                        </Col>

                        <Col lg="7" md="12" className="h-100 bg-white section2">
                            <Row>
                                <Form className="px-4" style={{ position: "relative" }}>
                                    <Row className="g-0 justify-content-center h-100 form-vendor">
                                        <Col lg={12} style={{ height: "15%" }}>
                                            <Row className="text-center py-5">
                                                <h2 className="fs-1 fw-bold wazieats-color wh2">
                                                    Devenir Wazi Partner
                                                </h2>
                                            </Row>
                                        </Col>
                                        <Col lg={12} className="bg-white" style={{ height: "75%" }}>
                                            <Row>

                                                <Col md={12}>
                                                    <FormText>
                                                        <i>All compulsory fields carry a *</i>.
                                                    </FormText>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label for="name">Nom du restaurant *</Label>
                                                        <InputGroup size="lg" className="mt-1">
                                                            <Input
                                                                type={"text"}
                                                                name="rname"
                                                                id="rname"
                                                                value={rname}
                                                                onChange={handleChange}
                                                                style={{
                                                                    backgroundColor: "#eee",
                                                                    borderColor: "#eee",
                                                                }}
                                                                bsSize="lg"
                                                                placeholder="Nom"
                                                                invalid={vrname}
                                                            />
                                                            <InputGroupText
                                                                style={{
                                                                    color: "#000",
                                                                    backgroundColor: "#eee",
                                                                    borderColor: "#eee",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{ cursor: "pointer" }}
                                                                    icon={faStoreAlt}
                                                                />
                                                            </InputGroupText>
                                                        </InputGroup>
                                                        <FormFeedback className={vrname ? "d-block" : ""}>
                                                            Nom du restaurant requis
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="">
                                                    <FormGroup>
                                                        <Label for="name">
                                                            Nom(s) Administrateur restaurant *
                                                        </Label>
                                                        <Row>
                                                            <Col>
                                                                <InputGroup size="lg" className="mt-1">
                                                                    <Input
                                                                        type={"text"}
                                                                        name="name"
                                                                        id="name"
                                                                        value={name}
                                                                        onChange={handleChange}
                                                                        style={{
                                                                            backgroundColor: "#eee",
                                                                            borderColor: "#eee",
                                                                        }}
                                                                        bsSize="lg"
                                                                        placeholder="Noms"
                                                                        invalid={vname}
                                                                    />
                                                                    <InputGroupText
                                                                        style={{
                                                                            color: "#000",
                                                                            backgroundColor: "#eee",
                                                                            borderColor: "#eee",
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            style={{ cursor: "pointer" }}
                                                                            icon={faUser}
                                                                        />
                                                                    </InputGroupText>
                                                                </InputGroup>
                                                                <FormFeedback
                                                                    className={vname ? "d-block" : ""}
                                                                >
                                                                    Nom de l'administrateur du restaurant requis
                                                                </FormFeedback>
                                                            </Col>
                                                            <Col>
                                                                <InputGroup size="lg" className="mt-1">
                                                                    <Input
                                                                        type={"text"}
                                                                        name="sname"
                                                                        id="sname"
                                                                        value={sname}
                                                                        onChange={handleChange}
                                                                        style={{
                                                                            backgroundColor: "#eee",
                                                                            borderColor: "#eee",
                                                                        }}
                                                                        bsSize="lg"
                                                                        placeholder="Prénoms"
                                                                    />
                                                                    <InputGroupText
                                                                        style={{
                                                                            color: "#000",
                                                                            backgroundColor: "#eee",
                                                                            borderColor: "#eee",
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            style={{ cursor: "pointer" }}
                                                                            icon={faUserAlt}
                                                                        />
                                                                    </InputGroupText>
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label for="phone">
                                                            Téléphone Administrateur restaurant *
                                                        </Label>
                                                        <Row
                                                            noGutters
                                                            className="mt-1"
                                                            style={{
                                                                backgroundColor: "#eee",
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            <Col sm={11}>
                                                                <PhoneInput
                                                                    value={phone}
                                                                    onChange={(phone, data) => {
                                                                        setPhone("+" + phone);
                                                                        // let v = p.slice(data.dialCode.length)
                                                                        // console.log(phone)
                                                                        // setPhoneError(!isValidPhoneNumber(phone))
                                                                    }}
                                                                    inputProps={{
                                                                        name: "phone",
                                                                        // id: "tel",
                                                                        className: "form-control tel",
                                                                        required: true,
                                                                        autoFocus: true,
                                                                    }}
                                                                    preferredCountries={["cm"]}
                                                                    enableSearch="true"
                                                                    className="p-0"
                                                                    country={"cm"}
                                                                />
                                                            </Col>
                                                            <Col
                                                                sm={1}
                                                                id="phoneIcon"
                                                                className="text-center align-self-center d-none d-md-block"
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{ color: "#000" }}
                                                                    icon={faPhoneAlt}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <FormFeedback className={pError ? "d-block" : ""}>
                                                            Numéro de Téléphone invalide
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label for="phone2">
                                                            Téléphone WhatsApp restaurant *
                                                        </Label>
                                                        <Row
                                                            noGutters
                                                            className="mt-1"
                                                            style={{
                                                                backgroundColor: "#eee",
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            <Col sm={11}>
                                                                <PhoneInput
                                                                    value={phone2}
                                                                    onChange={(phone2, data) => {
                                                                        setPhone2("+" + phone2);
                                                                        // let v = p.slice(data.dialCode.length)
                                                                        // console.log(phone)
                                                                        // setPhoneError(!isValidPhoneNumber(phone))
                                                                    }}
                                                                    inputProps={{
                                                                        name: "phone2",
                                                                        id: "tel2",
                                                                        className: "form-control tel",
                                                                        required: false,
                                                                        autoFocus: true,
                                                                    }}
                                                                    preferredCountries={["cm"]}
                                                                    enableSearch="true"
                                                                    className="p-0"
                                                                    country={"cm"}
                                                                />
                                                            </Col>
                                                            <Col
                                                                sm={1}
                                                                id="phoneIcon"
                                                                className="text-center align-self-center d-none d-md-block"
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{ color: "#000" }}
                                                                    icon={faPhoneAlt}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <FormFeedback className={p2Error ? "d-block" : ""}>
                                                            Numéro de Téléphone invalide
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label for="email">
                                                            Email Administrateur restaurant *
                                                        </Label>
                                                        <InputGroup size="lg" className="mt-1">
                                                            <Input
                                                                autoComplete="false"
                                                                type={"email"}
                                                                name="email"
                                                                id="email"
                                                                onChange={handleChange}
                                                                value={email}
                                                                style={{
                                                                    backgroundColor: "#eee",
                                                                    borderColor: "#eee",
                                                                }}
                                                                bsSize="lg"
                                                                placeholder="Email de l'administrateur"
                                                            />
                                                            <InputGroupText
                                                                style={{
                                                                    color: "#000",
                                                                    backgroundColor: "#eee",
                                                                    borderColor: "#eee",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{ cursor: "pointer" }}
                                                                    icon={faEnvelope}
                                                                />
                                                            </InputGroupText>
                                                        </InputGroup>

                                                        <FormFeedback className={emailError ? "d-block" : ""}>
                                                            Email invalide
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="mb-4" md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label for="name">Choisissez la catégorie</Label>
                                                        <Input
                                                            type={"select"}
                                                            name="category"
                                                            id="category"
                                                            onChange={handleChange}
                                                            value={category}
                                                            style={{
                                                                backgroundColor: "#eee",
                                                                borderColor: "#eee",
                                                            }}
                                                            bsSize="lg"
                                                            placeholder="Choisissez la catégorie"
                                                        >
                                                            {categoryList.map((list) => (
                                                                <option value={list.id}>{list.name}</option>)
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label for="name">Adresse de localisation</Label>
                                                        {address !== "" ? <InputGroup size="lg" className="mt-1">
                                                            <Input
                                                                type={"text"}
                                                                name="address"
                                                                id="address"
                                                                value={address}
                                                                onChange={handleChange}
                                                                disabled={true}
                                                                style={{
                                                                    backgroundColor: "#eee",
                                                                    borderColor: "#eee",
                                                                }}
                                                                bsSize="lg"
                                                                placeholder="Adresse de localisation"
                                                            />
                                                            <InputGroupText
                                                                style={{
                                                                    color: "#000",
                                                                    backgroundColor: "#eee",
                                                                    borderColor: "#eee",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{ cursor: "pointer" }}
                                                                    icon={faLocationDot}
                                                                />
                                                            </InputGroupText>
                                                        </InputGroup> : <></>}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mb-4">
                                                    <FormGroup>

                                                        <Button
                                                            type="button"
                                                            onClick={() => (getPosition(), handleOpenModal())}
                                                            size="md"
                                                            className="fw-bold btn btn-login"
                                                            block
                                                        >
                                                            <FontAwesomeIcon icon={faMapPin} /> &nbsp;
                                                            Ouvrir la carte
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                                <Modal isOpen={isOpen} onClose={handleCloseModal} size={"5xl"}>
                                                    <ModalOverlay />
                                                    <ModalContent>
                                                        <ModalHeader>
                                                            <b>Carte de géolocalisation</b>
                                                        </ModalHeader>
                                                        <ModalCloseButton />
                                                        <ModalBody>
                                                            <ModalBodyComponent
                                                                initialCoordinates={{
                                                                    latitude: lat ?? 4.0511,
                                                                    longitude: long ?? 9.7678
                                                                }}
                                                                initialAddress={address}
                                                                onClose={handleCloseModal}
                                                                onSaveCoordinates={handleSaveCoordinates}
                                                            />
                                                        </ModalBody>
                                                    </ModalContent>
                                                </Modal>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label for="web">Site Web</Label>
                                                        <InputGroup size="md" className="mt-1 mb-3">
                                                            <Input
                                                                type={"text"}
                                                                name="website"
                                                                id="website"
                                                                onChange={handleChange}
                                                                value={web}
                                                                style={{
                                                                    backgroundColor: "#eee",
                                                                    borderColor: "#eee",
                                                                }}
                                                                bsSize="lg"
                                                                placeholder="Site Web"
                                                            />
                                                            <InputGroupText
                                                                style={{
                                                                    color: "#000",
                                                                    backgroundColor: "#eee",
                                                                    borderColor: "#eee",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{ cursor: "pointer" }}
                                                                    icon={faGlobeAfrica}
                                                                />
                                                            </InputGroupText>
                                                        </InputGroup>
                                                        <FormText>
                                                            Exemple: <i>https://www.example.com</i>
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label for="social">
                                                            Autres liens (Facebook, Instagram, WhatsApp etc.)
                                                        </Label>
                                                        <CreatableSelect
                                                            components={components}
                                                            inputValue={social}
                                                            onChange={(newValue) => setValue(newValue)}
                                                            onInputChange={(newValue) => setSocial(newValue)}
                                                            onKeyDown={handleKeyDown}
                                                            value={value}
                                                            className="mb-3"
                                                            menuIsOpen={false}
                                                            placeholder="Entrez les liens"
                                                            style={{
                                                                backgroundColor: "#eee",
                                                                borderColor: "#eee",
                                                            }}
                                                            isMulti
                                                            isClearable
                                                        />
                                                        <FormText>
                                                            Entrez le lien et appuyez <b>ENTREE</b> pour
                                                            valider
                                                        </FormText>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup row>
                                                        <Label sm={12} for="profile">
                                                            <b>Photo de profil du restaurant *</b>
                                                        </Label>
                                                        <Col sm={12}>
                                                            {profile.length > 0 ? (
                                                                <Col sm={12} className="thumb-container pb-5">
                                                                    {profile.map((file) => (
                                                                        <div className="thumb" key={file.name}>
                                                                            <button
                                                                                className="rbtn"
                                                                                onClick={removeFiles(file)}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    style={{
                                                                                        width: "50px",
                                                                                        height: "50px",
                                                                                    }}
                                                                                    icon={faXmarkCircle}
                                                                                />
                                                                            </button>
                                                                            <div className="thumb-inner">
                                                                                <img
                                                                                    style={{
                                                                                        width: "200px",
                                                                                        height: "125px",
                                                                                    }}
                                                                                    src={file.preview}
                                                                                    alt={file.name}
                                                                                    onLoad={() => {
                                                                                        URL.revokeObjectURL(file.preview);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </Col>
                                                            ) : (
                                                                <>
                                                                    <Col
                                                                        sm={12}
                                                                        {...getRootProps({
                                                                            className:
                                                                                "wazi-dropzone2 justify-content-center",
                                                                        })}
                                                                    >
                                                                        <input
                                                                            {...getInputProps()}
                                                                            type="file"
                                                                            name="profile"
                                                                            id="profile"
                                                                        />
                                                                        <FormText color="muted">
                                                                            Déposer une photo ici
                                                                        </FormText>
                                                                    </Col>
                                                                </>
                                                            )}
                                                        </Col>

                                                        <FormFeedback className={profileError ? "d-block" : ""}>
                                                            Select a profile photo for your restaurant
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={8}>
                                                    <FormGroup row>
                                                        <Label sm={12} for="username">
                                                            <b>Photos du restaurant</b>
                                                            (Maximum 3 Images)
                                                        </Label>
                                                        <Col sm={12}>
                                                            <Dropzone
                                                                className="mt-2"
                                                                onDrop={(acceptedFiles) => {
                                                                    setPicture(
                                                                        acceptedFiles.map((file) =>
                                                                            Object.assign(file, {
                                                                                preview: URL.createObjectURL(file),
                                                                            })
                                                                        )
                                                                    );
                                                                }}
                                                                name="pictures"
                                                                multiple={true}
                                                                maxFiles={3}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div
                                                                        {...getRootProps({
                                                                            className:
                                                                                "wazi-eats-dropzone justify-content-center",
                                                                        })}
                                                                    >
                                                                        <input {...getInputProps()} />
                                                                        <span
                                                                            className=""
                                                                            style={{ fontSize: ".8rem" }}
                                                                        >
                                                                            Veuillez cliquer ici pour choisir vos
                                                                            images
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                        </Col>
                                                        <Col
                                                            sm={11}
                                                            className="thumbs-container pb-5 px-4"
                                                            style={{ overflow: "auto" }}
                                                        >
                                                            {picture.map((file) => (
                                                                <div className="thumbs" key={file.name}>
                                                                    <button
                                                                        className="rbtn2"
                                                                        onClick={removePicture(file)}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            style={{ width: "50px", height: "50px" }}
                                                                            icon={faXmarkCircle}
                                                                        />
                                                                    </button>
                                                                    <div className="thumbs-inner">
                                                                        <img
                                                                            style={{
                                                                                width: "115px",
                                                                                height: "115px",
                                                                            }}
                                                                            src={file.preview}
                                                                            alt={file.name}
                                                                            onLoad={() => {
                                                                                URL.revokeObjectURL(file.preview);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </Col>
                                                    </FormGroup>
                                                </Col>


                                                <Col md={12}>
                                                    <div >
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    defaultChecked={terms}
                                                                    onChange={() => { setTerms(!terms); }}
                                                                />
                                                                <TermsModal />
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                <FormText>
                                                    <i>All compulsory fields carry a *</i>.
                                                </FormText>
                                                <Col sm={2} className="cbtn">
                                                    <Button
                                                        type="button"
                                                        onClick={handleSubmit}
                                                        size="md"
                                                        className="fw-bold btn btn-login mt-2"
                                                        block
                                                    >
                                                        Créer
                                                    </Button>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Row>
                            <Row className="form-vendor-end">
                                <div className="divider" />
                                <Col lg={12} style={{ height: "5%" }}>
                                    <div className="fw-bold text-dark text-center mt-2">
                                        J'ai déjà un compte. Se&nbsp;
                                        <a href="/login" className="wazieats-2-link">
                                            connecter
                                        </a>
                                        &nbsp;à son compte.
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </LoadingOverlay>
            </div>
        </Fragment>
    );
}
