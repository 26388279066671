import { createSlice } from "@reduxjs/toolkit";
import foodService from "../services/foodService";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const platesSlice = createSlice({
  name: "plates",
  initialState: initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.data = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    get: (state) => {
      return state.data;
    },
    addItem: (draft, action) => {
      draft.data.push(action.payload);
      return;
    },
    updateItem: (draft, action) => {
      const index = draft.data.findIndex(
        (item) => item.id === action.payload.id
      );
      draft.data.splice(index, 1, action.payload);
      return;
    },
    removeItem: (draft, action) => {
      const index = draft.data.findIndex(
        (item) => item.id === action.payload.id
      );
      draft.data.splice(index, 1);
    },
  },
});

export function fetchPlates(
  restaurant_id,
  status = true,
  page = 1,
  limit = 10
) {
  return async (dispatch) => {
    dispatch(setLoading());
    if (status !== "today") {
      foodService
        .getAllForRestaurant(restaurant_id, status)
        .then((response) => {
          dispatch(setItems(response.data));
          console.log(response.data, "me");
        })
        .catch((er) => {
          dispatch(setError());
        });
    } else {
      // Obtenir le jour actuel en français
      const options = { weekday: 'long' };
      const jourActuelFr = new Date().toLocaleDateString('fr-FR', options);

      // Obtenir le jour actuel en anglais
      const jourActuelEn = new Date().toLocaleDateString('en-US', options);

      let published_day = [jourActuelFr,jourActuelEn]
      foodService
        .getAllForRestaurant(restaurant_id, null, published_day)
        .then((response) => {
          dispatch(setItems(response.data));
        })
        .catch((er) => {
          dispatch(setError());
        });
    }
  };
}

export const {
  addItem,
  updateItem,
  removeItem,
  setLoading,
  setItems,
  setError,
} = platesSlice.actions;

export default platesSlice.reducer;
