import { Input, ListGroup, ListGroupItem } from "reactstrap";
import Select from "react-select";
import { useIntl } from "react-intl";

const listItemStyle = { justifySelf: "center", alignSelf: "center" };

export default function OrderDetailsForm({
  orderItems,
  register,
  watch,
  setValue,
  setFormIsDirty,
}) {
  const intl = useIntl();
  const calculTotalOrder = (order) => {
    let total = 0
    total = total + (order.food_data.purchase_price * order.total_quantity)
    return total
  }

  function getCurrentTime() {
    const now = new Date(); // Obtenir l'heure actuelle

    // Extraire les heures, minutes et secondes
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }
  function compareDates(date1, date2) {
      const d1 = new Date(date1); // Convertir date1 en objet Date
      const d2 = new Date(date2); // Convertir date2 en objet Date

      if (d1 > d2) {
        return "expirer";
      } else if (d1 < d2) {
        return "le livraison est pour demain";
      } else {
          return true
      }
  }
  function compareTimes(time1, time2) {
      const t1 = new Date(`1970-01-01T${time1}Z`); // Convertir time1 en objet Date
      const t2 = new Date(`1970-01-01T${time2}Z`); // Convertir time2 en objet Date

      if (t1 > t2) {
          return true;
      } else if (t1 < t2) {
          return false;
      } else {
          return true;
      }
  }

  function calculateTimeDifference(endTime, startTime) {
    // Convertir les heures en objets Date
    const start = new Date(`01/01/2000 ${startTime}`);
    const end = new Date(`01/01/2000 ${endTime}`);

    // Calculer la différence en millisecondes
    const differenceInMilliseconds = end - start;

    // Convertir la différence en heures, minutes et secondes
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  }
  function getCurrentDate() {
    const today = new Date();
    
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    const day = String(today.getDate()).padStart(2, '0'); // Ajouter un 0 pour les jours < 10

    return `${year}-${month}-${day}`;
  }
  let timeLeftToBeDelivered = "pas correct"
  if (compareDates(getCurrentDate(), orderItems.delivery_date) == true && compareTimes(orderItems.delivery_time, getCurrentTime()) == true){
    timeLeftToBeDelivered = calculateTimeDifference(orderItems.delivery_time, getCurrentTime());
  }else{

    timeLeftToBeDelivered = compareDates(getCurrentDate(), orderItems.delivery_date) != true ? compareDates(getCurrentDate(), orderItems.delivery_date) : "expirer";
  }

  return (
    <>
      <ListGroup style={{ marginTop: "15px" }}>
        <ListGroupItem style={{ backgroundColor: "rgba(0, 0, 0, 0.06)" }}>
          <b>Food items</b>
        </ListGroupItem>
        {orderItems.food_items.map((item, index) => {
          return (
            <ListGroupItem
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(5, 1fr)",
              }}
            >
              <Input
                style={{ ...listItemStyle, ...{ width: "40px" } }}
                defaultValue={Number(item.total_quantity)}
                type="number"
                onChange={(e) => {
                  setFormIsDirty(true);
                  setValue(
                    `food_items.${index}.quantity`,
                    Number(e.target.value)
                  );
                }}
              />
              <span style={listItemStyle}>{item.food_name} | {item.food_data.complements} | {item.food_data.obstacles} | {calculTotalOrder(item)}</span>
              <img src={item.food_data.foodPicture} style={{width: "80px" ,height: "80px"}} />
              <span
                style={{
                  width: "80px" ,
                  height: "80px",
                  justifySelf: "center",
                  color: `${timeLeftToBeDelivered > 0 && "red"}`,
                }}
              >{`Time left :  ${timeLeftToBeDelivered}`}</span>
              <Input {...register(`food_items.${index}.food_id`)} hidden />
              <Input
                style={listItemStyle}
                type="checkbox"
                defaultChecked
                onChange={(e) => {
                  setValue(
                    `food_items.${index}.editCheckbox`,
                    Number(e.target.checked)
                  );
                }}
              />
              {!watch().food_items[index].editCheckbox &&
                (item.food_data.substitute_food?.length ? (
                  <Select
                    isSearchable
                    style={listItemStyle}
                    type="select"
                    name="filter"
                    id="filter"
                    options={item.food_data.substitute_food.map((obj) => ({
                      value: obj.food_id,
                      label: obj.food_name,
                    }))}
                    onChange={(e) => {
                      setFormIsDirty(true);
                      setValue(`food_items.${index}.food`, e.value);
                    }}
                  ></Select>
                ) : (
                  <span style={listItemStyle}>
                    {intl.formatMessage({ id: "noSuggestionsToShow" })}
                  </span>
                ))}
            </ListGroupItem>
          );
        })}
      </ListGroup>
      <ListGroup style={{ marginTop: "15px" }}>
        <ListGroupItem style={{ backgroundColor: "rgba(0, 0, 0, 0.06)" }}>
          <b>Drink items</b>
        </ListGroupItem>
        {orderItems.drink_items.map((item, index) => {
          return (
            <ListGroupItem
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
              }}
            >
              <Input
                style={{ ...listItemStyle, ...{ width: "60px" } }}
                defaultValue={Number(item.total_quantity)}
                type="number"
                onChange={(e) => {
                  setFormIsDirty(true);
                  setValue(
                    `drink_items.${index}.quantity`,
                    Number(e.target.value)
                  );
                }}
              />
              <span style={listItemStyle}>{item.drink_name}</span>
              <Input {...register(`drink_items.${index}.drink_id`)} hidden />
              <Input
                style={listItemStyle}
                type="checkbox"
                defaultChecked
                onChange={(e) => {
                  setValue(
                    `drink_items.${index}.editCheckbox`,
                    Number(e.target.checked)
                  );
                }}
              />
              {!watch().drink_items[index].editCheckbox &&
                (item.drink_data.substitute_drink?.length ? (
                  <Select
                    isSearchable
                    style={listItemStyle}
                    type="select"
                    name="filter"
                    id="filter"
                    options={item.food_data.substitute_food.map((obj) => ({
                      value: obj.drink_id,
                      label: obj.drink_name,
                    }))}
                    onChange={(e) => {
                      setFormIsDirty(true);
                      setValue(`drink_items.${index}.drink`, e.value);
                    }}
                  ></Select>
                ) : (
                  <span style={listItemStyle}>
                    {intl.formatMessage({ id: "noSuggestionsToShow" })}
                  </span>
                ))}
            </ListGroupItem>
          );
        })}
      </ListGroup>
      <ListGroup style={{ marginTop: "15px" }}>
        <ListGroupItem style={{ backgroundColor: "rgba(0, 0, 0, 0.06)" }}>
          <b>Menu items</b>
        </ListGroupItem>
        {orderItems.menu_items.map((item, index) => {
          return (
            <ListGroupItem
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
              }}
            >
              <Input
                style={{ ...listItemStyle, ...{ width: "60px" } }}
                defaultValue={Number(item.total_quantity)}
                type="number"
                onChange={(e) => {
                  setFormIsDirty(true);
                  setValue(
                    `menu_items.${index}.quantity`,
                    Number(e.target.value)
                  );
                }}
              />
              <span style={listItemStyle}>{item.menu_name}</span>
              <Input {...register(`menu_items.${index}.menu_id`)} hidden />
              <Input
                style={listItemStyle}
                type="checkbox"
                defaultChecked
                onChange={(e) => {
                  setValue(
                    `menu_items.${index}.editCheckbox`,
                    Number(e.target.checked)
                  );
                }}
              />
              {!watch().menu_items[index].editCheckbox &&
                (item.menu_data.substitute_menu?.length ? (
                  <Select
                    isSearchable
                    style={listItemStyle}
                    type="select"
                    name="filter"
                    id="filter"
                    options={item.food_data.substitute_menu.map((obj) => ({
                      value: obj.menu_id,
                      label: obj.menu_name,
                    }))}
                    onChange={(e) => {
                      setFormIsDirty(true);
                      setValue(`menu_items.${index}.menu_id`, e.value);
                    }}
                  ></Select>
                ) : (
                  <span style={listItemStyle}>
                    {intl.formatMessage({ id: "noSuggestionsToShow" })}
                  </span>
                ))}
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </>
  );
}
