export function getCurrentDateFormatted() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function subtractOneWeek(dateString) {
  const inputDate = new Date(dateString);
  inputDate.setDate(inputDate.getDate() - 7);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, hence adding 1
  const day = String(inputDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function subtractOneMonth(dateString) {
  const inputDate = new Date(dateString);
  inputDate.setMonth(inputDate.getMonth() - 1);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, hence adding 1
  const day = String(inputDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function subtractOneYear(dateString) {
  const inputDate = new Date(dateString);
  inputDate.setFullYear(inputDate.getFullYear() - 1);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, hence adding 1
  const day = String(inputDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
