import { useIntl } from "react-intl";
import { sortByTimeToStartCooking } from "./utils";
import { timeOptions } from "../../utils/timeOptions";
import { Card, Spinner } from "reactstrap";
import { isMobile } from 'react-device-detect';

const orderCardStyle = {
  display: "grid",
  gridTemplateColumns: "70% 30%",
  width: `${isMobile ? '100%' : '70%'}`,
  padding: "15px",
  margin: "5px",
  cursor: "pointer",
};
const noDataCardStyle = {
  width: "70%",
  padding: "15px",
  margin: "5px",
};
const noDataContainerStyle = {
  display: "flex",
  justifyContent: "center",
};
const calculTotalOrder = (order) => {
  let total = 0
  for (let i = 0; i < order.food_items.length; i++) {
    const element = order.food_items[i];
    total = total + (element.food_data.purchase_price * element.total_quantity)
    
  }
  return total
}
const getTagColor = (status) => {
  switch (status) {
    case 2:
      return "purple";
    case 3:
      return "orange";
    case 6:
      return "red";
    default:
      return "grey";
  }
};

export default function OrdersTable({ data, handleOrderClick, loading }) {
  const intl = useIntl();

  const getTagText = (status) => {
    switch (status) {
      case 2:
        return intl.formatMessage({ id: "processing" });
      case 3:
        return intl.formatMessage({ id: "inProgress" });
      case 6:
        return intl.formatMessage({ id: "cancelled" });
      default:
        return "";
    }
  };

  function getCurrentTime() {
      const now = new Date(); // Obtenir l'heure actuelle

      // Extraire les heures, minutes et secondes
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
  }
  function compareDates(date1, date2) {
      const d1 = new Date(date1); // Convertir date1 en objet Date
      const d2 = new Date(date2); // Convertir date2 en objet Date

      if (d1 > d2) {
        return "expirer";
      } else if (d1 < d2) {
        return "le livraison est pour demain";
      } else {
          return true
      }
  }
  function compareTimes(time1, time2) {
      const t1 = new Date(`1970-01-01T${time1}Z`); // Convertir time1 en objet Date
      const t2 = new Date(`1970-01-01T${time2}Z`); // Convertir time2 en objet Date

      if (t1 > t2) {
          return true;
      } else if (t1 < t2) {
          return false;
      } else {
          return true;
      }
  }

  function calculateTimeDifference(endTime, startTime) {
    // Convertir les heures en objets Date
    const start = new Date(`01/01/2000 ${startTime}`);
    const end = new Date(`01/01/2000 ${endTime}`);

    // Calculer la différence en millisecondes
    const differenceInMilliseconds = end - start;

    // Convertir la différence en heures, minutes et secondes
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  }
  function getCurrentDate() {
    const today = new Date();
    
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    const day = String(today.getDate()).padStart(2, '0'); // Ajouter un 0 pour les jours < 10

    return `${year}-${month}-${day}`;
  }

  const getListOfFoodItems = (order) => {
    let listOfFoodItems = "";
    let total = 0;
    order.food_items.forEach((food_item) => {
      listOfFoodItems = listOfFoodItems.concat(
        ", ",
        `${food_item.total_quantity}x ${food_item.food_name}`
      );
      total = total + food_item.total_quantity;
    });
    return { listOfFoodItems: listOfFoodItems.substring(2), total };
  };

  return loading ? (
    <Spinner
      speed="0.85s"
      thickness="4px"
      emptyColor="gray.200"
      color="#0ab44e"
      size="xl"
    />
  ) : data.length ? (
    sortByTimeToStartCooking(data).map((order, key) => {
      let timeLeftToBeDelivered = "pas correct"
      if (compareDates(getCurrentDate(), order.delivery_date) == true && compareTimes(order.delivery_time, getCurrentTime()) == true){
        timeLeftToBeDelivered = calculateTimeDifference(order.delivery_time, getCurrentTime());
      }else{

        timeLeftToBeDelivered = compareDates(getCurrentDate(), order.delivery_date) != true ? compareDates(getCurrentDate(), order.delivery_date) : "expirer";
      }

      const foodItems = getListOfFoodItems(order);
      return (
        <Card
          key={order.order_id}
          style={orderCardStyle}
          onClick={() => handleOrderClick(order)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              justifySelf: "flex-start",
            }}
          >
            <span style={{ color: "#0ab44e" }}>
              <b>{`${intl.formatMessage({ id: "order" })} #${
                order.order_id
              }`}</b>
            </span>
            <span style={{ justifySelf: "center" }}>
              {new Date(order.ordered_at).toLocaleDateString(
                "en-US",
                timeOptions
              )}
            </span>
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <b>{`${foodItems.total} ${intl.formatMessage({
                id: "dish",
              })}:`}</b>{" "}
              {foodItems.listOfFoodItems}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              justifySelf: "center",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
                backgroundColor: getTagColor(order.status),
                padding: "5px",
                borderRadius: "3px",
              }}
            >
              {getTagText(order.status)}
            </span>
            <span>
              <b>{`${calculTotalOrder(order)} Fcfa`}</b>
            </span>
            {order.status === 2 && (
              <span
                style={{
                  justifySelf: "center",
                  color: `${timeLeftToBeDelivered > 0 && "red"}`,
                }}
              >{`Time left :  ${timeLeftToBeDelivered}`}</span>
            )}
          </div>
        </Card>
      );
    })
  ) : (
    <Card style={noDataCardStyle}>
      <div style={noDataContainerStyle}>
        <span>
          <b>No data to show</b>
        </span>
      </div>
    </Card>
  );
}
