import React, { createContext, useEffect, useState } from "react";
import mainService from "../services/mainService";

const walletInfo = {
  wallet_amount: 100,
};


const WalletContext = createContext(0);

const WalletProvider = ({ children }) => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const token = currentUser ? currentUser.token : null;
  const [walletAmount, setWalletAmount] = useState(0);

  useEffect( async () => {
    await mainService
      .getRestaurantProfile(token)
      .then((response) => {
        setWalletAmount(response.data.wallet_amount);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <WalletContext.Provider value={{ walletAmount, setWalletAmount }}>
      {children}
    </WalletContext.Provider>
  );
};

export { WalletContext, WalletProvider };
