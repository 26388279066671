import Base from "../Base";
import Content from "./content";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../../utils/selectors";
import { Navigate } from "react-router-dom";
import { useIntl } from "react-intl";

function Dashboard() {
  const intl = useIntl();

  const isConnected = useSelector(isLoggedIn);
  const user = localStorage.getItem("user");
  const mainUser = JSON.parse(user);
  console.log(mainUser, "currentUser here");

  if (!isConnected) {
    return <Navigate replace to="/login" />;
  }

  if (mainUser?.temporary_password === true && mainUser?.activated === true) {
    return <Navigate replace to="/reset-password" />;
  } else if (mainUser?.temporary_password === true) {
    return <Navigate replace to="/create-password" />;
  } else {
    return (
      <Base>
        <div className="app-inner-layout">
          <Content />
        </div>
      </Base>
    );
  }
}

export default Dashboard;
