import { toast } from "react-toastify";
import { WS_URL } from "./config";
import mainService from "./mainService";
import { useNavigate } from "react-router-dom";

import { useContext, useEffect, useRef } from "react";
import { NotificationsContext } from "../context/notificationsContext";

const user = localStorage.getItem("user");
const mainUser = JSON.parse(user);
const token = mainUser ? mainUser.token || null : null ;


const NotificationsWebSocket = () => {
  const ws = useRef(null);
  
  const { getNotificationMessages } = useContext(NotificationsContext);
  const navigate = useNavigate();
  
  const handleNotificationsClick = async (notification) => {
    const order = await mainService.getOrdersDetail(token,notification.order_id);
    navigate(`/orders/${notification.order_id}`, {
      state: order.data[0],
    });
  };
  const user = localStorage.getItem("user");
  const mainUser = JSON.parse(user);
  const url = `${WS_URL}order/restaurant/${mainUser ? mainUser.restaurant_id : 0}/`;

  // Vérifiez si les notifications sont prises en charge par le navigateur
  if (!("Notification" in window)) {
    // alert("Ce navigateur ne prend pas en charge les notifications de bureau.");
    return;
  }else{
    if (Notification.permission !== "denied") {
      // Demandez la permission de notification
      Notification.requestPermission()
    }
  }


  const showChromeNotification = (title, message, iconUrl,data) => {
  
    // Vérifiez si la permission a déjà été accordée
    if (Notification.permission === "granted") {
      // Affichez la notification
      createNotification(title, message, iconUrl,data);
    }
  }
  
  const createNotification = (title, message, iconUrl,data) =>{
    const options = {
      body: message,
      icon: iconUrl
    };
  
    const notification = new Notification(title, options);
  
    notification.onclick = (e) => {
      window.focus(); // Mettre le focus sur la fenêtre du navigateur
      handleNotificationsClick(data)
      // window.location.href = "/notifications"; // Rediriger vers /orders

    };
  }
  
  useEffect(() => {
    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      console.log("WebSocket connected");
    };

    ws.current.onclose = () => {
      console.log("WebSocket disconnected");
    };

    ws.current.onmessage = (event) => {
      const parsedEvent = JSON.parse(event.data);
      getNotificationMessages();
      toast(
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            <b>You have a new order!</b>
          </span>
          <span>Order ID: {parsedEvent.message.order_id}</span>
        </div>

      );
      showChromeNotification(parsedEvent.message.title, parsedEvent.message.description, "/logo.png", parsedEvent.message)
    };

    return () => {
      ws.current.close();
    };
  }, [url]);

  return <></>;
};

export default NotificationsWebSocket;
