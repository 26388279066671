import { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import VideoPlayer from '../VideoPlayer/index.jsx';
import ReactPlayer from 'react-player';

function AppModal () {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button onClick={toggle} className="btn-demo" >
        Demo
      </Button>
      <Modal isOpen={modal} toggle={toggle} fade={false} size='lg' className="modal-widget">
        <ModalBody>
          <VideoPlayer src/>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AppModal
