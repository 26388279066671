import { useLocation } from "react-router-dom";
import { timeOptions } from "../../utils/timeOptions";
import Base from "../Base";
import { useIntl } from "react-intl";

export default function NotificationDetail() {
  const intl = useIntl();

  const location = useLocation();
  const notificationDetails = location.state;
  return (
    <Base>
      <div
        className="app-inner-layout"
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <h2>
          {new Date(notificationDetails.created_at).toLocaleDateString(
            "en-US",
            timeOptions
          )}
        </h2>
        <h3>{notificationDetails.title}</h3>
        <span>{notificationDetails.description}</span>
        {notificationDetails.order ? (
          <span>
            {intl.formatMessage({ id: "order" })}{" "}
            <b>#{notificationDetails.order}</b>
          </span>
        ) : (
          <></>
        )}
      </div>
    </Base>
  );
}
