import http from './http-commons';
import { authHeader } from './authHeader';
import { API_URL } from './config';
import axios from 'axios';

class DrinkService {

  getDrinkType() {
    return http.get(API_URL + 'drink/type/', { headers: { 'Authorization': 'Token 605a76487d776c8ab2cf53d3ccc485cd61180f02', "Content-type": "application/json", } });
  }
  getDrinkCategory() {
    return http.get(API_URL + 'drink/category/', { headers:  { 'Authorization': 'Token 605a76487d776c8ab2cf53d3ccc485cd61180f02', "Content-type": "application/json", } });
  }

  getAllAdminDrink() {
    return http.get(API_URL + 'admin/drink/', { headers: authHeader() });
  }

  getAllForRestaurant(id) {
    if(id === null || id === undefined || id === "") {
      return http.get(API_URL + `drink/`, { headers: authHeader() });
    }
    return http.get(API_URL + `drink/?restaurant=${id}`, { headers: authHeader() });
  }

  getAdmin() {
    return http.get(API_URL + 'admin/drink/', { headers: authHeader() });
  }
  getAdminID = id => {
    return http.get(`admin/drink/${id}/`, { headers: authHeader() });
  };
  get = id => {
    return http.get(`drink/${id}/`, { headers: authHeader() });
  };

  getDrinkByType = (restoId, type, name) => {
    // return http.get(`drink/?type=${type}&name=${name}`, { headers: authHeader() });
    return http.get(`drink/restaurant/${restoId}/category/${type}`, { headers: authHeader() });
  };

  getDrinkByCategory = (restId, type, name) => {
    return http.get(`drink/restaurant/${restId}/category/${type}`, { headers: authHeader() });
  };

  getDrinkByName = (restId, name) => {
    return http.get(`drink/?name=${name}&restaurant=${restId}`, { headers: authHeader() });
  };

  create = data => {
    return axios.post(API_URL + "drink/", data, { headers: authHeader("multipart/form-data") })
  };

  update = (id, data) => {
    return axios.put(API_URL + `drink/${id}/`, data, { headers: authHeader() })
    // return http.put(`drink/${id}`, data, { headers: authHeader() });
  };

  remove = id => {
    return http.delete(`drink/${id}/`, { headers: authHeader() });
  };

  deactivate = id => {
    return http.post(`drink/${id}/deactivate/`, { headers: authHeader() });
  };
  activate = id => {
    return http.post(`drink/${id}/activate/`, { headers: authHeader() });
  };
}

export default new DrinkService();
