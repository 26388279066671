import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { LanguageProvider } from "./context/languageContext";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/base.scss";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import { WalletProvider } from "./context/walletContext";
import { NotificationsProvider } from "./context/notificationsContext";

const rootElement = document.getElementById("root");
const tagManagerArgs = {
  gtmId: "GTM-TPSGDXZ",
};
TagManager.initialize(tagManagerArgs);

const renderApp = (Component) => {
  ReactDOM.createRoot(rootElement).render(
    <Provider store={store}>
      <Router>
        <NotificationsProvider>
          <WalletProvider>
            <LanguageProvider>
              <Component />
            </LanguageProvider>
          </WalletProvider>
        </NotificationsProvider>
      </Router>
    </Provider>
  );
};

renderApp(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    renderApp(NextApp);
  });
}
serviceWorker.register();


