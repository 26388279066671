import React, { useState, useContext } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { LanguageContext } from "../../../context/languageContext";
import { isMobile } from 'react-device-detect';

const languageOptions = [
  { label: "en", value: "English", flag: "🇺🇸" },
  { label: "fr", value: "Français", flag: "🇫🇷" },
];

export default function LanguageDropdown({}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { selectedLanguage, handleLanguageChange } =
    useContext(LanguageContext);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown
      style={{ marginRight: "15px" }}
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle caret style={{ fontSize: "18px" }}>
        {
          languageOptions.find((option) => option.label === selectedLanguage)
            .flag
        }{" "}
      </DropdownToggle>
      <DropdownMenu style={ isMobile ? { position: "fixed", top: "100px", left: "150px" } : {position: "relative"}}>
        {languageOptions.map((option) => (
          <DropdownItem
            key={option.label}
            onClick={() => {
              setDropdownOpen(false);
              handleLanguageChange(option.label);
            }}
          >
            {option.flag} {option.value}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
