import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Base from "../Base";
import { useIntl } from "react-intl";
import mainService from "../../services/mainService";
import { Spinner } from "@chakra-ui/react";
import {
  Button,
  Card,
  CardSubtitle,
  CardText,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faFireBurner,
  faTrash,
  faHandshakeAngle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import OrdersTable from "./OrdersTable";
import { getCurrentDateFormatted } from "../Dashboard/utils";
import { isMobile } from 'react-device-detect';

const cardStyles = {
  display: "flex",
  justifyContent: "space-around",
  padding: "8px",
  fontSize: "30px",
};
const cardSubtitleStyles = {
  display: "flex",
  justifyContent: "center",
  padding: "0 8px",
};

export default function Orders() {
  const intl = useIntl();

  const [loadingProcessing, setLoadingProcessing] = useState(false);
  const [loadingInProgress, setLoadingInProgress] = useState(false);
  const [loadingCancelled, setLoadingCancelled] = useState(false);
  const [modal, setModal] = useState(false);
  const [processingOrders, setProcessingOrders] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [showProcessingOrders, setShowProcessingOrders] = useState(true);
  const [showInProgressOrders, setShowInProgressOrders] = useState(true);
  const [showCancelledOrders, setShowCancelledOrders] = useState(true);
  const [fromDate, setFromDate] = useState(getCurrentDateFormatted());
  const [toDate, setToDate] = useState(getCurrentDateFormatted());

  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const mainUser = JSON.parse(user);
  const token = mainUser.token;

  const getProcessingOrdersCurrentDay = async () => {
    setLoadingProcessing(true);
    try {
      const response = await mainService.getOrders(token);
      setProcessingOrders(response.data);
    } catch (err) {
      setProcessingOrders([]);
      console.log(err);
    } finally {
      setLoadingProcessing(false);
    }
  };
  const getProcessingOrdersByDay = async () => {
    setLoadingProcessing(true);
    try {
      const response = await mainService.getOrderDetails(
        "2",
        fromDate,
        toDate,
        token
      );
      setProcessingOrders(response.data.response);
    } catch (err) {
      setProcessingOrders([]);
      console.log(err);
    } finally {
      setLoadingProcessing(false);
    }
  };
  const getInProgressOrdersByDay = async (fromDate, toDate) => {
    setLoadingInProgress(true);
    try {
      const response = await mainService.getOrdersDeliveryByDay(
        fromDate,
        toDate,
        token
      );
      setInProgressOrders(response.data.response);
    } catch (err) {
      setInProgressOrders([]);
      console.log(err);
    } finally {
      setLoadingInProgress(false);
    }
  };
  const getCancelledOrdersByDay = async (fromDate, toDate) => {
    setLoadingCancelled(true);
    try {
      const response = await mainService.getOrdersCancelledByDay(
        fromDate,
        toDate,
        token
      );
      setCancelledOrders(response.data.response);
    } catch (err) {
      setCancelledOrders([]);
      console.log(err);
    } finally {
      setLoadingCancelled(false);
    }
  };

  const getOrdersCurrentDay = async () => {
    getProcessingOrdersCurrentDay();
    getInProgressOrdersByDay(
      getCurrentDateFormatted(),
      getCurrentDateFormatted()
    );
    getCancelledOrdersByDay(
      getCurrentDateFormatted(),
      getCurrentDateFormatted()
    );
  };
  const getOrdersByDate = async () => {
    if (!fromDate || !toDate) return;
    getProcessingOrdersByDay();
    getInProgressOrdersByDay(fromDate, toDate);
    getCancelledOrdersByDay(fromDate, toDate);
  };

  const handleSwitch = (value) => {
    setShowProcessingOrders(false)
    setShowInProgressOrders(false)
    setShowCancelledOrders(false)
    if (value === "processing") {
      setShowProcessingOrders((v) => !v);
    } else if (value === "inProgress") {
      setShowInProgressOrders((v) => !v);
    } else if (value === "cancelled") {
      setShowCancelledOrders((v) => !v);
    }
  };
  const handleOrderClick = (order) => {
    navigate(`/orders/${order.order_id}`, {
      state: order,
    });
  };

  useEffect(() => {
    getOrdersCurrentDay();
  }, []);

  const toggleTwo = () => {
    setModal(!modal);
  };

  const closeBtnTwo = (
    <FontAwesomeIcon
      className="close"
      onClick={toggleTwo}
      style={{ cursor: "pointer", fontSize: "1.5rem" }}
      icon={faTimes}
    />
  );

  return (
    <Base>
      <div className="app-inner-layout">
        <div className="app-inner-layout__header-boxed p-0">
          <div
            className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center"
            style={{
              padding: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Button
                className=" btn-icon-only pt-0 wazi-btn-add "
                style={{
                  alignSelf: "center",
                  backgroundColor: "#0ab44e",
                  border: "1px solid #0ab44e",
                  width: "auto",
                  whiteSpace: "nowrap",
                  fontSize: "20px",
                  lineHeight: "45px",
                  margin: "0 15px",
                }}
                onClick={() => {
                  getOrdersCurrentDay();
                }}
              >
                {intl.formatMessage({ id: "refreshOrders" })}
                <FontAwesomeIcon
                  icon={faArrowsRotate}
                  size="xs"
                  style={{
                    paddingLeft: "5px",
                  }}
                />
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                <h1 className="fs-3 pt-2">
                  {intl.formatMessage({ id: "orders" })}
                </h1>
              </div>
              {
                !isMobile ? (

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      gap: "10px",
                    }}
                  >
                    <Card
                      onClick={() => {
                        handleSwitch("processing");
                      }}
                      style={{
                        border: `${
                          showProcessingOrders
                            ? "2px solid green"
                            : "2px solid transparent"
                        }`,
                        cursor: "pointer",
                      }}
                    >
                      <div></div>
                      <div style={cardStyles}>
                        <FontAwesomeIcon
                          icon={faHandshakeAngle}
                          style={{
                            alignSelf: "center",
                            color: "purple",
                          }}
                        />
                        {loadingProcessing ? (
                          <Spinner
                            speed="0.85s"
                            thickness="4px"
                            emptyColor="gray.200"
                            color="#0ab44e"
                            size="xl"
                          />
                        ) : (
                          <CardText style={{ color: "black" }}>{`${
                            processingOrders.length || "-"
                          }`}</CardText>
                        )}
                      </div>
                      <div style={cardSubtitleStyles}>
                        <CardSubtitle tag="h5">
                          {intl.formatMessage({ id: "ordersInProcessing" })}
                        </CardSubtitle>
                      </div>
                    </Card>
                    <Card
                      onClick={() => {
                        handleSwitch("inProgress");
                      }}
                      style={{
                        border: `${
                          showInProgressOrders
                            ? "2px solid green"
                            : "2px solid transparent"
                        }`,
                        cursor: "pointer",
                      }}
                    >
                      <div style={cardStyles}>
                        <FontAwesomeIcon
                          icon={faFireBurner}
                          style={{
                            alignSelf: "center",
                            color: "orange",
                          }}
                        />
                        {loadingInProgress ? (
                          <Spinner
                            speed="0.85s"
                            thickness="4px"
                            emptyColor="gray.200"
                            color="#0ab44e"
                            size="xl"
                          />
                        ) : (
                          <CardText style={{ color: "black" }}>{`${
                            inProgressOrders.length || "-"
                          }`}</CardText>
                        )}
                      </div>
                      <div style={cardSubtitleStyles}>
                        <CardSubtitle tag="h5">
                          {intl.formatMessage({ id: "ordersInProgress" })}
                        </CardSubtitle>
                      </div>
                    </Card>
                    <Card
                      onClick={() => {
                        handleSwitch("cancelled");
                      }}
                      style={{
                        border: `${
                          showCancelledOrders
                            ? "2px solid green"
                            : "2px solid transparent"
                        }`,
                        cursor: "pointer",
                      }}
                    >
                      <div style={cardStyles}>
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{
                            alignSelf: "center",
                            color: "red",
                          }}
                        />
                        {loadingCancelled ? (
                          <Spinner
                            speed="0.85s"
                            thickness="4px"
                            emptyColor="gray.200"
                            color="#0ab44e"
                            size="xl"
                          />
                        ) : (
                          <CardText style={{ color: "black" }}>{`${
                            cancelledOrders.length || "-"
                          }`}</CardText>
                        )}
                      </div>
                      <div style={cardSubtitleStyles}>
                        <CardSubtitle tag="h5">
                          {intl.formatMessage({ id: "cancelledOrders" })}
                        </CardSubtitle>
                      </div>
                    </Card>{" "}
                  </div>
                )
                : ("")
              }
            </div>
            {isMobile ? (

              <div style={{marginTop: "20px"}}>
                <Card
                  onClick={() => {
                    handleSwitch("processing");
                  }}
                  style={{
                    border: `${
                      showProcessingOrders
                        ? "2px solid green"
                        : "2px solid transparent"
                    }`,
                    cursor: "pointer",
                  }}
                >
                  <div></div>
                  <div style={cardStyles}>
                    <FontAwesomeIcon
                      icon={faHandshakeAngle}
                      style={{
                        alignSelf: "center",
                        color: "purple",
                      }}
                    />
                    {loadingProcessing ? (
                      <Spinner
                        speed="0.85s"
                        thickness="4px"
                        emptyColor="gray.200"
                        color="#0ab44e"
                        size="xl"
                      />
                    ) : (
                      <CardText style={{ color: "black" }}>{`${
                        processingOrders.length || "-"
                      }`}</CardText>
                    )}
                  </div>
                  <div style={cardSubtitleStyles}>
                    <CardSubtitle tag="h5">
                      {intl.formatMessage({ id: "ordersInProcessing" })}
                    </CardSubtitle>
                  </div>
                </Card>
                <Card
                  onClick={() => {
                    handleSwitch("inProgress");
                  }}
                  style={{
                    marginTop: "10px",
                    border: `${
                      showInProgressOrders
                        ? "2px solid green"
                        : "2px solid transparent"
                    }`,
                    cursor: "pointer",
                  }}
                >
                  <div style={cardStyles}>
                    <FontAwesomeIcon
                      icon={faFireBurner}
                      style={{
                        alignSelf: "center",
                        color: "orange",
                      }}
                    />
                    {loadingInProgress ? (
                      <Spinner
                        speed="0.85s"
                        thickness="4px"
                        emptyColor="gray.200"
                        color="#0ab44e"
                        size="xl"
                      />
                    ) : (
                      <CardText style={{ color: "black" }}>{`${
                        inProgressOrders.length || "-"
                      }`}</CardText>
                    )}
                  </div>
                  <div style={cardSubtitleStyles}>
                    <CardSubtitle tag="h5">
                      {intl.formatMessage({ id: "ordersInProgress" })}
                    </CardSubtitle>
                  </div>
                </Card>
                <Card
                  onClick={() => {
                    handleSwitch("cancelled");
                  }}
                  style={{
                    marginTop: "10px",
                    border: `${
                      showCancelledOrders
                        ? "2px solid green"
                        : "2px solid transparent"
                    }`,
                    cursor: "pointer",
                  }}
                >
                  <div style={cardStyles}>
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{
                        alignSelf: "center",
                        color: "red",
                      }}
                    />
                    {loadingCancelled ? (
                      <Spinner
                        speed="0.85s"
                        thickness="4px"
                        emptyColor="gray.200"
                        color="#0ab44e"
                        size="xl"
                      />
                    ) : (
                      <CardText style={{ color: "black" }}>{`${
                        cancelledOrders.length || "-"
                      }`}</CardText>
                    )}
                  </div>
                  <div style={cardSubtitleStyles}>
                    <CardSubtitle tag="h5">
                      {intl.formatMessage({ id: "cancelledOrders" })}
                    </CardSubtitle>
                  </div>
                </Card>
              </div>
            )
            : ("")
          }
            <div>
              <div
                style={ isMobile ? {
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                }
                :
                {
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }
              }
              >
                <InputGroup style={ isMobile ? { marginRight: "15px", marginBottom: "15px" } : { width: "30%", marginRight: "15px" }}>
                  <InputGroupText>From</InputGroupText>
                  <Input
                    defaultValue={getCurrentDateFormatted()}
                    type="date"
                    onChange={(e) => setFromDate(e.target.value)}
                  ></Input>
                </InputGroup>
                <InputGroup style={isMobile ? {marginBottom: "15px"} : { width: "30%" }}>
                  <InputGroupText>To</InputGroupText>
                  <Input
                    defaultValue={getCurrentDateFormatted()}
                    type="date"
                    onChange={(e) => setToDate(e.target.value)}
                  ></Input>
                </InputGroup>
                <Button
                  className=" btn-icon-only pt-0 wazi-btn-add "
                  style={{
                    backgroundColor: "#0ab44e",
                    border: "1px solid #0ab44e",
                    width: "auto",
                    fontSize: "15px",
                    lineHeight: "45px",
                    margin: "0 15px",
                  }}
                  onClick={getOrdersByDate}
                >
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {showProcessingOrders ? (
            <>
              <h3 style={{ alignSelf: "flex-start" }}>
                Waiting for Accept/Reject
              </h3>
              <OrdersTable
                data={processingOrders}
                handleOrderClick={handleOrderClick}
                loading={loadingProcessing}
              />
            </>
          ) : (
            ""
          )}
          {showInProgressOrders ? (
            <>
              <h3 style={{ alignSelf: "flex-start" }}>
                {intl.formatMessage({ id: "inProgress" })}
              </h3>
              <OrdersTable
                data={inProgressOrders}
                handleOrderClick={handleOrderClick}
                loading={loadingInProgress}
              />
            </>
          ) : (
            ""
          )}
          {showCancelledOrders ? (
            <>
              <h3 style={{ alignSelf: "flex-start" }}>
                {intl.formatMessage({ id: "cancelled" })}
              </h3>
              <OrdersTable
                data={cancelledOrders}
                handleOrderClick={handleOrderClick}
                loading={loadingCancelled}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </Base>
  );
}
