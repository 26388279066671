import { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import ReactMarkdown from 'react-markdown';

function TermsModal () {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <div>
            <Button onClick={toggle} className="btn-terms" >
                Accept Terms and Condition
            </Button>
            <Modal isOpen={modal} toggle={toggle} fade={false} size='xl' className="modal-widget">
                <ModalBody>
                    <ReactMarkdown>
                        {
                            `## **Conditions Générales d’Utilisation et politique des données de l'application mobile "WaziFood"**

**01/06/2023**


**ARTICLE 1 - OBJET DES CONDITIONS GÉNÉRALES D’UTILISATION ET DE LA POLITIQUE DES DONNÉES :**

\` \`Les présentes conditions générales d’utilisation et la politique des données (ci-après « CGU-PD ») ont pour objet de définir les règles d’utilisation de l’application Web « WAZIFOOD » (ci-après « l’Application ») éditée par la société WAZIEATS PLC, société par action simplifiée au capital de 1 000 000 Fcfa, immatriculée au registre du commerce et des sociétés de Bamenda à Mezam au Cameroun sous le numéro RC/BALI/2022/B/005, ayant son siège social au Cameroun, 

En installant l’Application sur votre terminal et/ou en accédant à l’Application, vous acceptez sans réserve l’intégralité des présentes CGU-PD et vous engagez à respecter les obligations à votre charge. Dans le cas où vous n’accepteriez pas les CGU-PD ou auriez des réserves, merci de ne pas utiliser l’Application. Les CGU-PD expriment l'intégralité de l'accord entre vous et WAZIEATS PLC applicable à votre utilisation de l’Application. WAZIEATS PLC se réserve le droit de modifier ces CGU-PD en les mettant à jour à tout moment. La version des CGU-PD qui est applicable entre vous et WAZIEATS PLC est celle en vigueur au moment de votre connexion et de votre utilisation de l’Application. Nous vous conseillons donc de consulter cette page régulièrement afin de prendre connaissance des CGU-PD en vigueur lorsque vous utilisez l’Application. 

**ARTICLE 2 – OBJET ET FONCTIONNALITÉS DE L’APPLICATION :**

WAZIEATS PLC est une entreprise d’innovation technologique qui n’est pas un restaurant et qui ne fournit pas de services de restauration.

\` \`L’Application vous permet d’afficher vos options de restauration, aux utilisateurs de nos applications web et mobile, dans le but de les commercialiser. Ces fonctionnalités peuvent être enrichies ou modifiées à tout moment par WAZIEATS PLC sans que sa responsabilité ne puisse être engagée à ce titre. 

**ARTICLE 3 - ACCÈS À L’APPLICATION :**

\` \`Pour accéder à l’Application, vous devez posséder un terminal mobile ou ordinateur et un accès au réseau Internet.. La version du logiciel de l'application peut être mise à jour de temps à autre pour ajouter de nouvelles fonctions et de nouveaux services. Vous vous engagez, lors de votre utilisation et navigation sur l’Application, à nous fournir des informations vraies, exactes et aussi complètes que possible que nous sommes libre d’utiliser à notre volonté. Vous vous engagez également à mettre à jour ces informations dans les meilleurs délais en cas de changement de celles-ci. 

- Informations de plats, boissons et menus

Les informations saisies doivent être à jour et doivent refléter la réalité de vos articles disponible en temps réel.

.Informations de profil 

Vos informations de profil doivent être correctes et mise à jour.

- impôts

En acceptant les presentes CGU-PD, vous acceptez que cet accord soit soumis a tous les impots, taxes, droits et redevances legaux en vigueur, et couts futurs en relation avec tout impots presents ou futurs pouvant etre adopter par le legislateur.



**ARTICLE 4 – LICENCE D’UTILISATION :**

\` \`WAZIEATS PLC vous concède un droit personnel d’utilisation de l’Application et de son contenu. Ce droit est accordé à titre non exclusif, révocable, non cessible, non transférable et pour la seule utilisation de l’Application, à l’exclusion de toute autre finalité et exclusivement pour les services de WAZIEATS PLC. Il est strictement interdit d’accéder et/ou d’utiliser et/ou de tenter d’accéder ou d’utiliser les codes sources ou objets de l'application. Vous n’acquérez aucun droit de propriété intellectuelle sur l’Application ou son contenu même pas sur vos informations et images renseignées consciemment dans l’application par vos soins, ni aucun autre droit que ceux conférés par les présentes CGU-PD. Toutes les données et informations même personnelles renseignées en toute conscience et liberté par vous dans l’application deviennent une propriété de WAZIEATS PLC qui peut en jouir en toute liberté. 

**ARTICLE 5 - DONNÉES PERSONNELLES :**

\` \`Les données personnelles que nous exploiterons et cèderont sont principalement votre nom, prénom, fonction, adresse professionnelle, numéro de téléphone, adresse mail, photo d’identité, profil métier, localisation, trajets, synthèse de vos activités sur notre application et vos habitudes d’achat, de consommation et de préférence vos disponibilités. Vos droits d’accès et de rectification peuvent être exercés en nous envoyant un mail à l’adresse *<admin@wazieats.com>* 



**ARTICLE 6 - PROPRIÉTÉ INTELLECTUELLE :**

\` \`L'ensemble du contenu de l’Application relève de la législation camerounaise, communautaire et internationale sur le droit d'auteur et la propriété intellectuelle. Tous les droits de reproduction et de représentation afférents à l’Application sont réservés par WAZIEATS PLC, y compris toutes représentations graphiques, iconographiques et photographiques, ce quel que soit le territoire de protection et que ces droits aient fait l'objet d'un dépôt ou non. La reproduction et/ou représentation de tout ou partie de l’Application, quel que soit le support, en ce compris tous noms commerciaux, marques, logos, noms de domaine et autres signes distinctifs, est formellement interdite et constituerait une contrefaçon sanctionnée par le code de la propriété intellectuelle. Les mots WAZI, WAZIEats, WAZIFood, WAZIDelivery et tous les logos sont des marques déposées par WAZIEATS PLC.


**ARTICLE 7 – DISPONIBILITÉ DE L’APPLICATION :**

\` \`L’Application est accessible en ligne 7 jours sur 7 et 24 heures sur 24. Toutefois, compte tenu de la complexité du réseau internet en afrique et de l'afflux, à certaines heures, des utilisateurs d'internet, WAZIEATS PLC n'offre aucune garantie de continuité du service et ne pourra voir sa responsabilité engagée en cas d'impossibilité temporaire d'accéder en tout ou partie à l’Application. Aucun droit à indemnisation n’est accordé au titre du présent article. WAZIEATS PLC se réserve le droit de cesser, sans préavis, sans indemnité et à son entière discrétion, à titre définitif ou provisoire, de fournir tout ou partie du Service ou des fonctionnalités des applications, plateformes. 

**ARTICLE 8 - LIMITATION DE RESPONSABILITÉ – ABSENCE DE GARANTIE :**

WAZIEATS PLC s'engage à mettre en œuvre tous les moyens nécessaires afin d'assurer au mieux l’accès à l’Application. Vous êtes seul responsable de votre identifiant. Vous vous engagez à les garder confidentielles et à ne pas les transmettre à des tiers. Si vous avez connaissance d'une utilisation non autorisée de votre compte, il vous appartient d'en informer immédiatement WAZIEATS PLC en écrivant à l'adresse suivante : *<admin@wazieats.com>*  de manière générale, vous acceptez et reconnaissez que votre utilisation de l’Application, y compris les informations que vous diffusez, est faite sous votre unique et entière responsabilité. En utilisant l’Application, vous vous engagez à ne pas agir de manière dommageable ou ayant pour effet de causer un préjudice à l’image, aux intérêts ou aux droits de WAZIEATS PLC, d’endommager ou de rendre inopérante l’Application. WAZIEATS PLC ne saurait être tenue pour responsable et ne saurait être tenue de vous indemniser du préjudice direct ou indirect qui résulterait de l’indisponibilité de l’Application. WAZIEATS PLC ne saurait à cet égard être tenue pour responsable de tout dommage résultant de la perte, de l’altération ou de toute utilisation frauduleuse de données, de la transmission accidentelle de virus ou autres éléments nuisibles, de l’attitude ou comportement d’un tiers. Elle n’encourt aucune responsabilité du fait :

1) de l’impossibilité d’accéder à l’Application,
1) d’un mauvais usage de l’Application 
1) de la saturation du réseau internet, 

(iv) d’éventuels dysfonctionnements sur les terminaux mobiles utilisés par vous, 

(v) en cas de force majeure ou de fait indépendant de sa volonté. 

**ARTICLE 9 - NON RENONCIATION :**

Le fait pour l'une des parties de ne pas se prévaloir d'un manquement par l'autre partie à l'une quelconque des obligations visées dans les présentes CGU-PD ne saurait être interprété pour l'avenir comme une renonciation à l'obligation en cause. 

**ARTICLE 10 - DROIT APPLICABLE –LITIGES :**

Les présentes CGU-PD sont soumises au droit camerounais. Tout litige concernant l'Application ou l’interprétation des présentes CGU sera soumis au tribunal compétent de Douala. 

**ARTICLE 11 – CONTACT :**

Vous pouvez adresser vos questions ou remarques relatives aux présentes CGU-PD à WAZIEATS PLC en écrivant à l'adresse suivante: *<admin@wazieats.com>* 

`}
                    </ReactMarkdown>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default TermsModal
