import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../../utils/selectors.js";
import { Navigate } from "react-router-dom";
import Base from "../Base.jsx";
import { NotificationsContext } from "../../context/notificationsContext.jsx";
import Table from "react-bootstrap/Table";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { timeOptions } from "../../utils/timeOptions.js";
import mainService from "../../services/mainService.js";
import { Spinner } from "@chakra-ui/react";
import { isMobile } from 'react-device-detect';



function Notifications() {
  const isConnected = useSelector(isLoggedIn);
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const mainUser = JSON.parse(user);
  const token = mainUser ? mainUser.token || null : null ;
  const [loadingProcessing, setLoadingProcessing] = useState(false);

  const { notificationMessages, getNotificationMessages } =
    useContext(NotificationsContext);

  const markNotificationAsRead = async (notificationId) => {
    try {
      await mainService.putNotificationMessages(notificationId, token);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNotificationsClick = async (notification) => {
    setLoadingProcessing(true)
    if (notification.is_active === true) await markNotificationAsRead(notification.id);
    const order = await mainService.getOrdersDetail(token,notification.order);
    navigate(`/orders/${notification.order}`, {
      state: order.data[0],
    });
    setLoadingProcessing(false)
    // navigate(`/notifications/${notification.order}`, {
    //   state: notification,
    // });
  };

  if (!isConnected) {
    return <Navigate replace to="/login" />;
  }

  useEffect(() => {
    getNotificationMessages();
  }, []);

  return (
    <Base>
      <div className="app-inner-layout">
        <div className="app-inner-layout__header-boxed p-0">
          <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
            <h1 className="fs-3">Notifications</h1>
          </div>
        </div>
        <div>
          {notificationMessages ? (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Created at</th>
                  { !isMobile ? (<th>Title</th>) : ("")}
                  <th>Description</th>
                  <th>Order</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                { loadingProcessing 
                  ? (<Spinner
                        speed="0.85s"
                        thickness="4px"
                        emptyColor="gray.200"
                        color="#0ab44e"
                        size="xl"
                    />) 
                  :(notificationMessages
                  .filter((notification) => !notification.deleted)
                  .map((notification) => {
                    return (
                      <tr
                        style={{
                          fontWeight: notification.is_active
                            ? "bold"
                            : "normal",
                          backgroundColor: notification.is_active
                            ? "white"
                            : "#b6b4b4",
                        }}
                      >
                        <td>
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "13px",
                                height: "13px",
                                margin: "3px",
                                borderRadius: "50%",
                                background: notification.is_active
                                  ? "blue"
                                  : "none",
                              }}
                            ></div>
                            {new Date(
                              notification.created_at
                            ).toLocaleDateString("en-US", timeOptions)}
                          </div>
                        </td>
                        { !isMobile ? (<td>{notification.title}</td>) : ("")}
                        <td>{notification.description}</td>
                        <td>{notification.order}</td>
                        <td>
                          <Row style={{ flexDirection: "row" }}>
                            <Col>
                              <Button
                                className=" btn-icon-only pt-0 wazi-btn-add "
                                style={{
                                  backgroundColor: "green",
                                  border: "1px solid green",
                                }}
                                onClick={() =>
                                  handleNotificationsClick(notification)
                                }
                              >
                                <FontAwesomeIcon icon={faEye} size="xs" />
                              </Button>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    );
                  }))}
              </tbody>
            </Table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Base>
  );
}

export default Notifications;
