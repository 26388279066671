import Base from "../Base";
import { useContext, useState } from "react";
import "./wallet.scss";
import WithdrawalForm from "./WithdrawalForm";
import ListTransaction from "./ListTransaction";
import { WalletContext } from "../../context/walletContext";
import { useIntl } from "react-intl";

export default function Wallet() {
  const [transactionList, setTransactionList] = useState([]);
  const intl = useIntl();

  const [open, setOpen] = useState("");

  const { walletAmount } = useContext(WalletContext);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Base>
      <div className="app-inner-layout">
        <div className="app-inner-layout__header-boxed p-0">
          <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
            <h1 className="fs-3 pt-2">
              {intl.formatMessage({ id: "wallet" })}
            </h1>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h2>
            {intl.formatMessage({ id: "yourAvailableBalanceIs" })}{" "}
            <b>{walletAmount}</b> Fcfa
          </h2>
        </div>
        <WithdrawalForm
          walletAmount={walletAmount}
          toggle={toggle}
          open={open}
        />
        <ListTransaction toggle={toggle} open={open} />
      </div>
    </Base>
  );
}
