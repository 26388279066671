import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button, Form, FormGroup, Label, Formroup } from "reactstrap";
import logo from "../../assets/utils/images/logo.png";
import { InputGroup, Input } from 'reactstrap';
import { createPassword } from "../../features/auth";
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";

import { currentUser } from "../../utils/selectors";
import swal from 'sweetalert';
import 'react-phone-input-2/lib/bootstrap.css'

function SecurityQuestion() {

  const [securityQ1, setSecurityQ1] = useState('Le nom de votre quartier de résidence')
  const [securityQ2, setSecurityQ2] = useState('Votre couleur préférée')
  const [securityQ3, setSecurityQ3] = useState("Nom de l'école primaire que vous avez fréquenté")

  const [security1, setSecurity1] = useState('')
  const [security2, setSecurity2] = useState('')
  const [security3, setSecurity3] = useState("")

  // const user = useSelector(currentUser)
  // const user =
  //   JSON.parse(localStorage.getItem('user'));
  // const { token } = user

  const user = JSON.parse(localStorage.getItem('user'));
const token = user?.token;

  const [loading, setLoading] = useState(false)

  const [pass, setPass] = useState(false)
  const [conf, setConf] = useState(false)

  const [error, setError] = useState("")

  const [show, setShow] = useState(false)
  const [view, setView] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();

    const item = localStorage.getItem("pdata")
    const data = JSON.parse(item)

    const { password, confirm } = data;
    // setLoading(true) // Afficher le logo de chargement
    setConf(false) // Valider le champ email/telephone
    setPass(false) // Valider le champ mot de passe
    setError(null)
    createPassword(token, password, confirm, security1, security2, security3, securityQ1, securityQ2, securityQ3)
      .then(() => {
        setLoading(false)
        localStorage.setItem("user", JSON.stringify({ ...user, temporary_password: false }));
        swal("Reinitialisation", "Mot de passe réinitialisé avec succès", "success").then(() => {
          window.location.href = "/profile"
        });
      })
      .catch(error => {
        setLoading(false)
        swal("Erreur!", error.response.data.message, "error");
      })
      .finally(() => localStorage.removeItem('pdata'))
  }

  return (
    <Fragment>
      <div className="">
        <Row className="g-0 justify-content-center align-items-center h-100">

          <Col lg="12" style={{ height: '25%' }} className="pt-4 wazieats-bg-primary-color d-flex justify-content-center">
            <div className="text-light d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={logo} alt="Logo wazieats" width="60" height="60" />
                <h3 className="fs-1 pt-3">Wazi<b>Food</b></h3><br />
                <h3 className="fs-6 pb-3">PARTENAIRE</h3>
              </div>
            </div>
          </Col>

          <Col lg="6" className="bg-white w-100 d-flex justify-content-center">
            <div className="text-dark d-flex flex-column justify-content-center align-items-center">
              <LoadingOverlay className="p-5" tag="div" active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                  }),
                }}
                spinner={<Loader active={loading} type='ball-pulse-rise' />}>
                <Form onSubmit={e => { handleSubmit(e) }} className='px-5'>
                  <Row className="text-center pb-4">
                    <h2 className="fs-3 fw-bold">Questions de sécurité</h2>
                  </Row>
                  <Row className="justify-content-center mt-4 align-items-center">
                    <Col md={8}>
                      <FormGroup className="position-relative">
                        <FormGroup>

                          <Input
                            type="select"
                            name="securityQ1"
                            id="securityQ1"
                            onChange={(e) => { setSecurityQ1(e.target.value) }} >
                            <option>Le nom de votre quartier de résidence/ The name of the neighbourhood you live in</option>

                          </Input>
                        </FormGroup>
                        <Row md={12}>
                          <Col md={12}>
                            <InputGroup size="lg" className="mt-1">
                              <Input
                                type={"text"}
                                name="security1"
                                id="security1"
                                value={security1}
                                style={{ backgroundColor: "#eee", borderColor: "#eee" }}
                                bsSize="lg"
                                placeholder="Entrez votre réponse"
                                invalid={pass}
                                onChange={(e) => {
                                  setSecurity1(e.target.value)
                                }} />
                            </InputGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={8}>
                      <FormGroup className="position-relative">
                        <FormGroup>
                          <Input
                            type="select"
                            name="securityQ2"
                            id="securityQ2"
                            onChange={(e) => { setSecurityQ2(e.target.value) }} >
                            <option> Votre couleur préférée / Your favourite color</option>


                          </Input>
                        </FormGroup>
                        <Row md={12}>
                          <Col md={12}>
                            <InputGroup size="lg" className="mt-1">
                              <Input
                                type={"text"}
                                name="security2"
                                id="security2"
                                value={security2}
                                style={{ backgroundColor: "#eee", borderColor: "#eee" }}
                                bsSize="lg"
                                placeholder="Entrez votre réponse"
                                invalid={pass}
                                onChange={(e) => {
                                  setSecurity2(e.target.value)
                                }} />
                            </InputGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={8}>
                      <FormGroup className="position-relative">
                        <FormGroup>
                          <Input
                            type="select"
                            name="securityQ3"
                            id="securityQ3"
                            onChange={(e) => { setSecurityQ3(e.target.value) }} >
                            <option> Nom de l'école primaire que vous avez fréquenté / Name of the primary school you attended</option>
                          </Input>
                        </FormGroup>
                        <Row md={12}>
                          <Col md={12}>
                            <InputGroup size="lg" className="mt-1">
                              <Input
                                type={"text"}
                                name="security3"
                                id="security3"
                                value={security3}
                                style={{ backgroundColor: "#eee", borderColor: "#eee" }}
                                bsSize="lg"
                                placeholder="Entrez votre réponse"
                                invalid={pass}
                                onChange={(e) => {
                                  setSecurity3(e.target.value)
                                }} />
                            </InputGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>

                  </Row>

                  <Row className="justify-content-center mt-4 align-items-center">
                    <Col sm={8}>
                      <div className="d-flex flex-column">
                        <Button type="submit" size="md" block className="btn fw-bold btn-login">
                          Changer mon mot de passe
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </LoadingOverlay>
            </div>
          </Col>

        </Row>
      </div>
    </Fragment>
  )
}

export default SecurityQuestion
