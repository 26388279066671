import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import { withRouter } from "../../components/HOC/withRouter";
import { useMainNav } from "./useMainNav";

const withUseMainNavHook = (WrappedComponent) => {
  return (props) => {
    const item = useMainNav();

    return <WrappedComponent customHookData={item} {...props} />;
  };
};

class Nav extends Component {
  state = {};
  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  render() {
    const { customHookData } = this.props;

    return (
      <Fragment>
        <h5 className="app-sidebar__heading">Menu</h5>
        <MetisMenu
          content={customHookData}
          onSelected={this.toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withUseMainNavHook(Nav)));
