import axios from "axios";
import { API_URL } from "./config";
class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "login", { phone: username, password: password , "role": "restaurant"})
      .then((response) => {
        // if (response.data.temporary_password === true) {
          // const data = {...response.data, temporary_password: false}
          let result = response.data;
          if(response.data.role === 'Restaurant' || response.data.role ==='underrestaurant') {
          const restoData = response.data.resaurant_data;
             result = {...response.data, ...restoData, picture: restoData.restaurant_profile_picture, profile_picture: restoData.user_profile_picture};
            delete result.resaurant_data;
            console.log(result);
              localStorage.setItem("user", JSON.stringify(result));
          }
        // }
          return result;

      });
  }

  async isTokenValid(token) {
      console.log("VERIFYING CLIENTS TOKEN");
      try {

          const response = await axios.post(API_URL + "verify-token", {token: token, env: "client"});
          if(response.data.message.includes('inexistant')) {
              console.log("TOKEN IS NOT VALID");
              return false;
          } else {
              console.log("TOKEN IS VALID");
              return true;
          }
      } catch (e) {
          if(e.message.includes('inexistant')) {
              console.log("TOKEN IS NOT VALID");
              return false;
          } else {
              console.log("TOKEN IS VALID");
              return true;
          }
      }
  }

  reset(data) {
      let form_data = new FormData();
        form_data.append('role', 'restaurant')
      for ( let key in data ) {
          form_data.append(key, data[key]);
      }

      return axios.post(API_URL + "forgot-password", form_data,{
          headers: {
              "Content-Type": "multipart/form-data",
          }
      })
  }

  resetPassword(token, password, confirm) {
      return axios.post(API_URL + "reset-password", { new_password: password, confirm_password: confirm,
          'role': 'restaurant',}, {
        headers:{
          "Authorization" : `Token ${token}`
        }
      })
  }

  createPassword(token, password, confirm,security1, security2, security3, securityQ1, securityQ2, securityQ3){
    return axios.post(API_URL + "create-password/", { password: password, confirm_password: confirm, security_question_1: securityQ1, security_question_2: securityQ2, security_question_3: securityQ3, security_response_1: security1, security_response_2: security2, security_response_3: security3,  }, {
      headers:{
        "Authorization" : `Token ${token}`
      }
    })
  }

  logout() {
    localStorage.removeItem("user");
  }
}
export default new AuthService();
