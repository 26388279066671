import { Card, CardSubtitle, CardText } from "reactstrap";
import { faFireBurner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import { Spinner } from "@chakra-ui/react";

const cardStyles = {
  display: "flex",
  justifyContent: "space-around",
  padding: "8px",
  fontSize: "30px",
};
const cardSubtitleStyles = {
  display: "flex",
  justifyContent: "center",
  padding: "0 8px",
};

export default function TotalOrdersWidget({ isLoading, deliveryOrdersCount }) {
  const intl = useIntl();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        gap: "10px",
      }}
    >
      <Card>
        <div style={cardStyles}>
          <FontAwesomeIcon
            icon={faFireBurner}
            style={{
              alignSelf: "center",
              color: "orange",
            }}
          />
          {isLoading ? (
            <Spinner
              speed="0.85s"
              thickness="4px"
              emptyColor="gray.200"
              color="#0ab44e"
              size="xl"
            />
          ) : (
            <CardText style={{ color: "black" }}>{`${
              deliveryOrdersCount || "-"
            }`}</CardText>
          )}
        </div>
        <div style={cardSubtitleStyles}>
          <CardSubtitle tag="h5">
            {intl.formatMessage({ id: "ordersInProgress" })}
          </CardSubtitle>
        </div>
      </Card>
    </div>
  );
}
